import { MenuItem } from '@mui/material';
import React, { useContext } from 'react';
import BalanceDetails from './BalanceDetails';
import { DateContext } from '../Contexts/DateContext';
import { INR_RESOURCE_ID } from '../../Helpers/ConstantProperties';

const ShowBalance = ({ item, showDialog }) => {
    const { currentDate } = useContext(DateContext);

    const onMenuClick = () => {
        showDialog({
            contents: <BalanceDetails
                account={item}
                resource={{ id: INR_RESOURCE_ID }}
                date={currentDate} />,
            title: "Balance Details for " + item.name
        });
    }

    return (
        <>
            <MenuItem onClick={() => onMenuClick()}>
                {"Balance Details"}
            </MenuItem>
        </>
    );
};

export default ShowBalance;