import { Box, Typography } from "@mui/material";
import { getRoundUptoTwoPlaces, ShowNumber } from "../../Helpers/helpers";
import Grid2 from "@mui/material/Unstable_Grid2";
import { commonFontWeight, largeFontSize, PRIMARY_COLOR } from "../../Helpers/ConstantProperties";

export const hsnDetails = {
    "998898": { desc: "Other manufacturing services n.e.c.", uqc: "NA" },
    "39172310": { desc: "Plastic Tubes", uqc: "NOS" },
    "39174000": { desc: "Plastic Fittings", uqc: "NOS" },
    "35069190": { desc: "Solvent Cement", uqc: "NOS" },
    "85359040": { desc: "Plastic Junction Box", uqc: "NOS" }
}

export default function GstrOneHsn({ data, gstrJson }) {
    const border = "1px solid lightgray";

    let totalTaxable = 0;
    let totalIGST = 0;
    let totalCGST = 0;
    let totaoSGST = 0;

    return (
        <Grid2 container padding={1} rowGap={0.5}>

            <Grid2 xs={12} textAlign={"left"} paddingLeft={2}>
                <Typography fontSize={largeFontSize} color={PRIMARY_COLOR}>
                    HSN Summary
                </Typography>
            </Grid2>

            <Grid2 xs={12}
                border={border}
                fontWeight={commonFontWeight}>
                <Box>
                    <Grid2 container paddingY={0.5} className="bg-primary-subtle">
                        <Grid2 xs={12} md={1}>HSN</Grid2>
                        <Grid2 xs={12} md={3}>Description</Grid2>
                        <Grid2 xs={12} md={0.5}>UQC</Grid2>
                        <Grid2 xs={12} md={1}>Quantity</Grid2>
                        <Grid2 xs={12} md={1.5}>Total</Grid2>
                        <Grid2 xs={12} md={1.5}>Taxable</Grid2>
                        <Grid2 xs={12} md={1}>IGST</Grid2>
                        <Grid2 xs={12} md={1}>CGST</Grid2>
                        <Grid2 xs={12} md={1}>SGST</Grid2>
                        <Grid2 xs={12} md={0.5}>Rate</Grid2>
                    </Grid2>
                </Box>
            </Grid2>

            {
                Object.values(data).map((entry, index) => {
                    const total = Math.round(entry.taxable + entry.IGST + entry.CGST + entry.SGST);

                    totalTaxable += entry.taxable;
                    totalIGST += entry.IGST;
                    totalCGST += entry.CGST;
                    totaoSGST += entry.SGST;

                    gstrJson.hsn.data.push({
                        "num": index + 1,
                        "hsn_sc": entry.hsncode,
                        "desc": hsnDetails[entry.hsncode].desc,
                        "uqc": hsnDetails[entry.hsncode].uqc,
                        "qty": entry.quantity,
                        "rt": entry.GSTRate,
                        "txval": getRoundUptoTwoPlaces(entry.taxable),
                        "iamt": entry.IGST,
                        "samt": getRoundUptoTwoPlaces(entry.CGST),
                        "camt": getRoundUptoTwoPlaces(entry.SGST),
                        "csamt": 0
                    })

                    return (
                        <Grid2 xs={12} key={index}>
                            <Box>
                                <Grid2 container borderBottom={border}>
                                    <Grid2
                                        xs={12} md={1}
                                        borderRight={border}
                                        borderLeft={border}>
                                        {entry.hsncode}
                                    </Grid2>

                                    <Grid2
                                        xs={12} md={3}
                                        borderRight={border}>
                                        {hsnDetails[entry.hsncode].desc}
                                    </Grid2>

                                    <Grid2
                                        xs={12} md={0.5}
                                        borderRight={border}>
                                        {hsnDetails[entry.hsncode].uqc}
                                    </Grid2>

                                    <Grid2
                                        xs={12} md={1}
                                        borderRight={border}>
                                        {entry.quantity}
                                    </Grid2>

                                    <Grid2
                                        xs={12} md={1.5}
                                        borderRight={border}
                                        textAlign={"right"}
                                        paddingRight={1}>
                                        {ShowNumber(total, 2, true)}
                                    </Grid2>

                                    <Grid2
                                        xs={12} md={1.5}
                                        borderRight={border}
                                        textAlign={"right"}
                                        paddingRight={1}>
                                        {ShowNumber(entry.taxable, 2, true)}
                                    </Grid2>

                                    <Grid2
                                        xs={12} md={1}
                                        borderRight={border}
                                        textAlign={"right"}
                                        paddingRight={1}>
                                        {ShowNumber(entry.IGST, 2, true)}
                                    </Grid2>

                                    <Grid2
                                        xs={12} md={1}
                                        borderRight={border}
                                        textAlign={"right"}
                                        paddingRight={1}>
                                        {ShowNumber(entry.CGST, 2, true)}
                                    </Grid2>

                                    <Grid2
                                        xs={12} md={1}
                                        borderRight={border}
                                        textAlign={"right"}
                                        paddingRight={1}>
                                        {ShowNumber(entry.SGST, 2, true)}
                                    </Grid2>

                                    <Grid2
                                        xs={12} md={0.5}
                                        borderRight={border}>
                                        {entry.GSTRate}
                                    </Grid2>
                                </Grid2>
                            </Box>
                        </Grid2>
                    )
                })
            }

            <Grid2 xs={12}
                border={border}
                fontWeight={commonFontWeight}>
                <Box>
                    <Grid2 container paddingY={0.5} className="bg-secondary-subtle">
                        <Grid2 xs={12} md={7}>Total</Grid2>

                        <Grid2
                            xs={12} md={1.5}
                            textAlign={"right"}
                            paddingRight={1}>
                            {ShowNumber(totalTaxable, 2, true)}
                        </Grid2>
                        <Grid2 textAlign={"right"}
                            paddingRight={1}
                            xs={12} md={1}>
                            {ShowNumber(totalIGST, 2, true)}
                        </Grid2>
                        <Grid2 textAlign={"right"}
                            paddingRight={1}
                            xs={12} md={1}>
                            {ShowNumber(totalCGST, 2, true)}
                        </Grid2>
                        <Grid2
                            textAlign={"right"}
                            paddingRight={1} xs={12} md={1}>
                            {ShowNumber(totaoSGST, 2, true)}
                        </Grid2>
                        <Grid2 xs={12} md={0.5}></Grid2>
                    </Grid2>
                </Box>
            </Grid2>
        </Grid2 >
    )
}