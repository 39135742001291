import React, { useContext, useState } from 'react';
import GenericFormHeader from '../GenericComponents/FormComponent/GenericFormHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { FirmContext } from '../Contexts/FirmContext';
import { useSnackbar } from '../Contexts/SnackbarProvider';
import { GenericErrorComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import Grid2 from '@mui/material/Unstable_Grid2';
import QuotationInfo from '../Quotation/QuotationInfo';
import { Button, Paper, TextField, Typography } from '@mui/material';
import { addDaysToToday, checkAdmin, checkValue, getLocalDateString, getPartyINRBatchObject, parshallyInquiryVoucher } from '../../Helpers/helpers';
import InquiryOutQtySelect from './InquiryOutQtySelect';
import GenericSpinner from '../GenericComponents/FormComponent/GenericSpinner';
import { COMMON_BATCH, commonFontSize, DISPLAY_CHALLAN, GENERATE_INVOICE, INQUIRY_STORE_ACCOUNT_ID, NOT_AVAILABLE, OUTWORD_CHALLAN, TAX_INVOICE } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { AuthContext } from '../Auth/Auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getMutateObject } from '../Dashboard/InquiryCalculations';
import { getRateDetails, getSaleINRTransactions } from '../Quotation/RateCalculator';
import { CLOSED_INQUIRY_TAG } from '../../Helpers/ExtraProperties';
import { DateContext } from '../Contexts/DateContext';
import { addReqToBOM } from '../Product/ProductHelper';

const InquiryOutInv = () => {
    const { token } = useContext(AuthContext);
    const { khID, currentFirm } = useContext(FirmContext);
    const { currentDate } = useContext(DateContext);

    const { showSnackbar } = useSnackbar();

    const [disabled, setDisabled] = useState(false);

    const [vehical, setVehical] = useState("");
    const [driver, setDriver] = useState("");
    const [eway, setEway] = useState("");

    const location = useLocation();
    const navigate = useNavigate();
    const inquiry = location.state?.inquiry
    const balanceDetails = location.state?.balanceDetails;
    const generateChallan = location.state?.generateChallan;

    const pageTitle = generateChallan ? "Generate Challan" : "Generate Invoice";
    const voucherType = generateChallan ? OUTWORD_CHALLAN : TAX_INVOICE;
    const prefix = generateChallan ? currentFirm.prefixes.challan : currentFirm.prefixes.invoice;
    const docType = generateChallan ? "Challan " : "Invoice ";
    const navigateTo = generateChallan ? DISPLAY_CHALLAN : GENERATE_INVOICE

    const getPlannedQty = (product) => (product.dirty ? product.planned : product.inStore);

    const queryFunction = async () => {
        const voucher = {
            vehicalNo: checkValue(vehical),
            driverName: checkValue(driver),
            eway: checkValue(eway),
            customerName: inquiry.customerName,
            city: inquiry.city,
            inquiryId: inquiry.id,
            customerId: inquiry.customerId,
            type: voucherType,
            date: currentDate,
            verified: true,
            transactions: [],
            batches: []
        }

        const fromAccount = INQUIRY_STORE_ACCOUNT_ID;
        const toAccount = inquiry.customerId;
        const dispatch = inquiry.dispatch ? inquiry.dispatch : {};

        const voucherProducts = [];
        const rawMaterialList = {};

        balanceDetails.forEach((product) => {

            if (getPlannedQty(product) > 0) {
                dispatch[product.id] = (dispatch[product.id] || 0) + getPlannedQty(product);
                const combinedRM = [];

                if (product.isJob) {
                    addReqToBOM(product, getPlannedQty(product), combinedRM);

                    combinedRM.forEach((req) => {
                        rawMaterialList[req.product.id] = {
                            ...rawMaterialList[req.product.id],
                            [product.id]: req.fgRate
                        }
                    })
                }

                const txnObject = {
                    customerName: inquiry.customerName,
                    inquiryID: inquiry.id,
                    resourceID: product.id,
                }

                if (product.invoiceRate) txnObject.invoiceRate = product.invoiceRate;

                // From
                voucher.transactions.push({
                    ...txnObject,
                    accountID: fromAccount,
                    units: -1 * getPlannedQty(product),
                    batches: [{ id: inquiry.id, units: -1 * getPlannedQty(product) }]
                })

                // To
                voucher.transactions.push({
                    ...txnObject,
                    accountID: toAccount,
                    units: getPlannedQty(product),
                    batches: [{ id: COMMON_BATCH, units: getPlannedQty(product) }]
                })

                voucherProducts.push({
                    id: product.id,
                    invoiceRate: product.invoiceRate,
                    units: getPlannedQty(product)
                });
            }
        })

        Object.entries(rawMaterialList).forEach(([rmid, qty]) => {

            let total = 0;

            const fromBatch = Object.entries(qty).map(([batchid, units]) => {
                total += units;
                return { id: batchid, units: -1 * units }
            })

            const toBatch = Object.entries(qty).map(([batchid, units]) => {
                return { id: batchid, units: units }
            })

            // From
            voucher.transactions.push({
                customerName: inquiry.customerName,
                resourceID: rmid,
                accountID: inquiry.id,
                units: -1 * total,
                batches: [fromBatch]
            })

            // To
            voucher.transactions.push({
                resourceID: rmid,
                inquiryID: inquiry.id,
                accountID: toAccount,
                units: total,
                batches: [toBatch]
            })
        })

        const updatedInq = parshallyInquiryVoucher({
            inquiry: inquiry,
            voucher: { products: voucherProducts }
        });

        const cData = getRateDetails({
            inquiry: updatedInq,
            currentFirm: currentFirm
        });

        voucher.cData = cData;

        const refId = await serviceHelpers.getSeriesNumber(
            token, khID, { prefix: prefix });

        voucher.refranceId = refId.id;

        if (!generateChallan) {
            const inrBatch = getPartyINRBatchObject(
                TAX_INVOICE,
                currentDate,
                voucher.refranceId
            )

            voucher.batches.push(inrBatch);
            voucher.inrBatch = inrBatch.id;

            const inrTransactions = getSaleINRTransactions(updatedInq, cData);
            voucher.transactions.push(...inrTransactions);
        }
        else {
            voucher.invoiceID = NOT_AVAILABLE;
        }

        const result = await serviceHelpers.createVoucherAndAdjustAdvance(
            token, khID, voucher, inquiry.customerId);

        const update = {
            followUpDate: addDaysToToday(2, currentDate),
            dispatch: dispatch
        }

        const notes = docType + voucher.refranceId + " generated."

        const shouldRemove = balanceDetails.every(
            element => element.remainingDispatch === getPlannedQty(element))

        if (shouldRemove && currentFirm?.autoCloseOrders) update.tag = CLOSED_INQUIRY_TAG

        await serviceHelpers.updateLeadStatus(token, khID, update, inquiry.id, notes, currentDate);

        setDisabled(true);
        return result;
    }

    const navigateOut = (result) => navigate(navigateTo, {
        state: { voucherID: result.id, inquiryObject: inquiry },
        replace: true
    });

    const queryClient = useQueryClient();

    const { mutate, isPending } = useMutation(
        getMutateObject(
            queryClient,
            queryFunction,
            showSnackbar,
            "Challan generated succesfully",
            inquiry.id,
            navigateOut)
    )

    const onPlannedChange = () => {
        const totalPlanned = balanceDetails.reduce(
            (total, product) => total + getPlannedQty(product), 0);

        setDisabled(totalPlanned <= 0);
    }

    if (!(balanceDetails && inquiry)) {
        return <GenericErrorComponent error={"Data Not Present"} />
    }

    return (
        <>
            <GenericFormHeader title={pageTitle} enableBack={true} />
            <Paper elevation={2} sx={{ padding: 2 }}>
                <Grid2 container rowGap={2}>
                    <Grid2 xs={12} md={4}>
                        <QuotationInfo
                            title={"To:"}
                            name={inquiry.customerName}
                            city={inquiry.city}
                            gstin={inquiry.gstin}
                            pan={inquiry.panNumber}
                            phoneNumber={inquiry.contactPhone}
                            email={inquiry.contactEmail}
                        />

                    </Grid2>

                    <Grid2 xs={12} md={3}>
                        <Typography component={"span"} fontSize={commonFontSize}>
                            <Grid2 container rowGap={1}>
                                <Grid2 xs={4}>
                                    PO:
                                </Grid2>
                                <Grid2 xs={8}>
                                    {checkValue(inquiry.poNumber)}
                                </Grid2>

                                <Grid2 xs={4}>
                                    Date:
                                </Grid2>
                                <Grid2 xs={8}>
                                    {getLocalDateString(Date.now())}
                                </Grid2>

                            </Grid2>
                        </Typography>
                    </Grid2>


                    <Grid2 xs={12} md={5} container alignContent={"end"} rowGap={2}>
                        <Grid2 xs={4}>
                            Vehical:
                        </Grid2>
                        <Grid2 xs={8}>
                            <TextField
                                className="bg-light"
                                size="small"
                                fullWidth
                                value={vehical}
                                onChange={(event) => setVehical(event.target.value)}
                            />
                        </Grid2>
                        <Grid2 xs={4}>
                            Driver:
                        </Grid2>
                        <Grid2 xs={8}>
                            <TextField
                                className="bg-light"
                                size="small"
                                fullWidth
                                value={driver}
                                onChange={(event) => setDriver(event.target.value)}
                            />
                        </Grid2>
                        <Grid2 xs={4}>
                            Eway:
                        </Grid2>
                        <Grid2 xs={8}>
                            <TextField
                                className="bg-light"
                                size="small"
                                fullWidth
                                value={eway}
                                onChange={(event) => setEway(event.target.value)}
                            />
                        </Grid2>

                    </Grid2>

                    <Grid2 xs={12}>
                        <InquiryOutQtySelect
                            balanceDetails={balanceDetails}
                            onPlannedChange={onPlannedChange}
                            disableRate={!checkAdmin(currentFirm.currentAccess)} />
                    </Grid2>

                    <Grid2 xs={12} textAlign={"center"}>
                        {
                            isPending
                                ? <GenericSpinner />
                                : <Button onClick={mutate} disabled={disabled}>
                                    Generate
                                </Button>
                        }
                    </Grid2>

                </Grid2>
            </Paper>
        </>
    )
};

export default InquiryOutInv;