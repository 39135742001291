import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import ReportHeader from '../Formats/ReportHeader';
import WorkingList from './WorkingList';

const TotalWorking = ({ transform }) => {
    return (
        <Grid2 container rowGap={2}>
            <Grid2 xs={12} className="text-danger">
                <ReportHeader
                    total={""}
                    header={"Current Working"}
                    transform={transform} />
            </Grid2>

            <Grid2 xs={12} >
                <WorkingList transform={transform} />
            </Grid2>
        </Grid2>
    );
};

export default TotalWorking;