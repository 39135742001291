import { Typography } from '@mui/material';
import React from 'react';
import { commonFontSize } from '../../Helpers/ConstantProperties';
import GenericDialogWithButton from '../GenericComponents/Dialog/GenericDialogWithButton';
import Grid2 from '@mui/material/Unstable_Grid2';

const PurchaseProductList = ({ products, showUnits }) => {
    if (products.length === 0) {
        return <Typography fontSize={commonFontSize}>No Product Available</Typography>;
    }

    return (
        <GenericDialogWithButton
            maxWidth="md"
            buttonProps={{ variant: "outlined", fullWidth: true }}
            buttonText={
                <>
                    {"Details"}
                </>
            }
            dialogTitle={"Details"}
            dialogContents={
                <Grid2 container>
                    {products.map((productObj, index) => (
                        <React.Fragment key={index}>
                            <Grid2 xs={8}>
                                <Typography fontSize={commonFontSize} py={0.5} textAlign={'left'}>
                                    {productObj.name}
                                </Typography>
                            </Grid2>
                            {
                                showUnits &&
                                <Grid2 xs={4}>
                                    <Typography fontSize={commonFontSize} py={0.5} textAlign={'left'}>
                                        {productObj.units}
                                    </Typography>
                                </Grid2>
                            }

                        </React.Fragment>
                    ))}
                </Grid2>
            }
        />
    );
};

export default PurchaseProductList;