import React, { useContext } from 'react';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { commonFontSize, INR_RESOURCE_ID, NON_GST_B2C_PARTY, OUT_GST_ACCOUNT_ID, STATEMENT, TAX_INVOICE, UPDATE_ON_INR_ACCOUNT } from '../../Helpers/ConstantProperties';
import GenericSpinner from '../GenericComponents/FormComponent/GenericSpinner';
import { GenericErrorComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { useQuery } from '@tanstack/react-query';
import Grid2 from '@mui/material/Unstable_Grid2';
import GstrOneBToB from './GstrOneBToB';
import GstrOneHsn from './GstrOneHSN';
import SaveGSTROne from './SaveGstrOne';
import GstrOneBToCS from './GstrOneB2CS';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { Typography } from '@mui/material';

const GSTROneJSON = ({ selectedMonth }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const fromDate = selectedMonth.startOf("month");
    const toDate = selectedMonth.endOf("month");

    const queryKey = [
        token,
        khID,
        TAX_INVOICE,
        UPDATE_ON_INR_ACCOUNT,
        INR_RESOURCE_ID,
        STATEMENT + OUT_GST_ACCOUNT_ID,
        new Date(fromDate).getTime(),
        new Date(toDate).getTime()
    ];

    // Get all the transaction from given month for OutGST account
    const queryFn = async () => {
        const options = {
            accountID: OUT_GST_ACCOUNT_ID,
            resourceID: INR_RESOURCE_ID,
            fromDate: fromDate,
            toDate: toDate,
            type: TAX_INVOICE
        }

        return await serviceHelpers.getStatement(token, khID, options);
    }

    const { data, isLoading, error } = useQuery({
        queryKey: queryKey,
        queryFn: queryFn,
    })

    if (isLoading) return <GenericSpinner />
    if (error) return <GenericErrorComponent error={error} />

    if (data) {
        const bTob = [];
        const bToC = [];
        const hsnTable = {};

        data.forEach(element => {
            if (element.opening) return

            // Add HSN work here.
            Object.entries(element.hsnWiseList).forEach(([Key, hsnEntry]) => {
                if (!hsnTable[Key]) {
                    hsnTable[Key] = {
                        taxable: 0,
                        hsncode: hsnEntry.hsncode,
                        GSTRate: hsnEntry.GSTRate,
                        quantity: 0,
                        IGST: 0,
                        CGST: 0,
                        SGST: 0
                    }
                }

                hsnTable[Key].taxable += hsnEntry.taxable;
                hsnTable[Key].quantity += hsnEntry.quantity;
                hsnTable[Key].IGST += hsnEntry.IGST || 0
                hsnTable[Key].CGST += hsnEntry.CGST || 0
                hsnTable[Key].SGST += hsnEntry.SGST || 0
            })

            if (element.gstin !== NON_GST_B2C_PARTY) {
                bTob.push(element)
                return
            }

            bToC.push(element);
            return
        });

        var gstrJson = {
            "gstin": "27AAFFF3861R1ZS",
            "fp": "022025",
            "version": "GST3.2",
            "hash": "hash",
            "b2b": [],
            "b2cs": [],
            "hsn": {
                "data": []
            }
        }

        return (
            <Typography component={"span"} fontSize={commonFontSize}>
                <Grid2 container rowGap={1} textAlign={"center"}>
                    <Grid2 xs={12}>
                        <GstrOneBToB data={bTob} gstrJson={gstrJson} />
                    </Grid2>

                    <Grid2 xs={12}>
                        <GstrOneBToCS data={bToC} gstrJson={gstrJson} />
                    </Grid2>

                    <Grid2 xs={12}>
                        <GstrOneHsn data={hsnTable} gstrJson={gstrJson} />
                    </Grid2>

                    <Grid2 xs={12}>
                        <SaveGSTROne gstrJson={gstrJson} />
                    </Grid2>
                </Grid2>
            </Typography>
        )
    }

    return (
        <></>
    )
};

export default GSTROneJSON;