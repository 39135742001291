import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useContext, useState } from 'react';
import AddProperty from '../AddProperties/AddProperty';
import { SchemaTypes } from '../../Helpers/ExtraProperties';
import GenericFormHeader from '../GenericComponents/FormComponent/GenericFormHeader';
import GenericMutateButton from '../GenericComponents/Buttons/GenericMutateButton';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { INR_RESOURCE_ID, TRANSFER_TYPE, TRANSFER_VOUCHER, UPDATE_ON_TRANSFER } from '../../Helpers/ConstantProperties';
import { DateContext } from '../Contexts/DateContext';
import dayjs from 'dayjs';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';

const NewTransfer = () => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const { currentDate } = useContext(DateContext);

    const [selectedDate, setSelectedDate] = useState(dayjs(currentDate));
    const [fromAcc, setFromAcc] = useState(null);
    const [toAcc, setToAcc] = useState(null);
    const [amount, setAmount] = useState(0);
    const [notes, setNotes] = useState("");

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const canSubmit = fromAcc !== null
        && fromAcc?.id !== toAcc?.id
        && amount !== 0

    const onSuccess = async () => {
        await queryClient.invalidateQueries({
            predicate: (query) =>
                query.queryKey.includes(UPDATE_ON_TRANSFER)
        });

        navigate(-1)

        setFromAcc(null);
        setToAcc(null);
        setAmount("");
    }

    const createDoc = async () => {
        const cData = {
            from: { id: fromAcc.id, name: fromAcc.name },
            to: { id: toAcc.id, name: toAcc.name }
        }

        const voucher = {
            cData: cData,
            date: selectedDate,
            type: TRANSFER_VOUCHER,
            verified: true,
            refranceId: "Internal",
            transactions: [],
            partyName: TRANSFER_TYPE,
            ammount: amount,
            notes: "Transfer from " + fromAcc.name + " to " + toAcc.name
        }

        // From Account
        voucher.transactions.push({
            resourceID: INR_RESOURCE_ID,
            accountID: fromAcc.id,
            batches: [{ id: TRANSFER_VOUCHER, units: -amount }],
            units: -amount,
        })

        // To Account
        voucher.transactions.push({
            resourceID: INR_RESOURCE_ID,
            accountID: toAcc.id,
            batches: [{ id: TRANSFER_VOUCHER, units: amount }],
            units: amount,
        })

        return serviceHelpers.creteProductVoucher(token, khID, voucher);
    }

    return (
        <>
            <GenericFormHeader title={"Add New Transfer"} enableBack={true} />
            <Grid2 container p={2} rowGap={2}>
                <Grid2 xs={12} md={3} alignContent={"flex-end"}>

                    <AddProperty
                        data={{
                            item: {
                                displayName: 'Date',
                                name: 'receiptDate',
                                required: true,
                                type: SchemaTypes.DATEPICKER,
                                helperText: 'Please Select the Date',
                            },
                            extraProps: {
                                disableFuture: true
                            }
                        }}
                        deleteField={(element) => { setSelectedDate(dayjs()) }}
                        currentValue={selectedDate}
                        onChange={(e) => setSelectedDate(e.value)}
                    />

                </Grid2>

                <Grid2 xs={12} md={3}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: 'From Account ',
                                name: 'fromAcc',
                                required: true,
                                type: SchemaTypes.PAYACCOUNTDROPDOWN,
                                helperText: 'Please Select the Account',
                            }
                        }}
                        deleteField={
                            (element) => { setFromAcc(null) }}
                        currentValue={fromAcc}
                        onChange={(e) => setFromAcc(e.value)}
                    />
                </Grid2>

                <Grid2 xs={12} md={3}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: 'To Account ',
                                name: 'toAcc',
                                required: true,
                                type: SchemaTypes.PAYACCOUNTDROPDOWN,
                                helperText: 'Please Select the Account',
                            }
                        }}
                        deleteField={
                            (element) => { setToAcc(null) }}
                        currentValue={toAcc}
                        onChange={(e) => setToAcc(e.value)}
                    />
                </Grid2>

                <Grid2 xs={12} md={3}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: "Amount",
                                name: "amount",
                                type: SchemaTypes.INRAMOUNT,
                                required: true,
                                helperText: 'Please enter the Amount',
                            },
                        }}
                        deleteField={(element) => { setAmount(0) }}
                        currentValue={amount}
                        onChange={(e) => setAmount(e.value)}
                    />
                </Grid2>

                <Grid2 xs={12}>

                    <AddProperty
                        data={{
                            item: {
                                displayName: "Notes",
                                name: "notes",
                                type: SchemaTypes.String,
                                required: true,
                                helperText: 'Please enter Notes',
                            },
                        }}
                        deleteField={(element) => { setNotes("") }}
                        currentValue={notes}
                        onChange={(e) => setNotes(e.value)}
                    />

                </Grid2>

                <Grid2 xs={12} textAlign={"center"} paddingY={2}>
                    <GenericMutateButton
                        queryFn={createDoc}
                        disable={!canSubmit}
                        onSuccess={onSuccess}
                        successMessage="voucher Created" />
                </Grid2>

            </Grid2>
        </>
    )
};

export default NewTransfer;