import React, { useContext } from 'react';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { NEWPAYMENTS, PAYMENT_VOUCHER, TDSPAYMENT, UPDATE_ON_PAYMENTS } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import DataviewList from '../GenericComponents/DataView/DataviewList';
import DataView from '../GenericComponents/DataView/DataView';
import PaymentCard from './PaymentCard';
import { useNavigate } from 'react-router-dom';

const Payments = () => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const navigate = useNavigate();

    const queryKey = [
        token,
        khID,
        UPDATE_ON_PAYMENTS,
        PAYMENT_VOUCHER
    ];

    const queryFunction = async (pageParam) => {
        return await serviceHelpers.getPayments(token, khID, pageParam);
    }

    const getSearchableValue = (current) => {
        return (
            current.id + " "
            + current.vendorName
        )
    }

    const routeDetails = { heading: `Payments` }

    const actionList = [
        {
            onButtonClick: () => navigate(NEWPAYMENTS, { state: routeDetails }),
            buttonText: "New Payment",
        },
        {
            onButtonClick: () => navigate(TDSPAYMENT, { state: routeDetails }),
            buttonText: "TDS Payment",
        }
    ]

    return (
        <DataView
            routeDetails={routeDetails}
            actionList={actionList}
            getSearchableValue={getSearchableValue}
            queryKeyParameter={queryKey}
            queryFunctionParameter={queryFunction}
            ShowElement={{ Component: PaymentCard }}
            DisplayComponent={DataviewList}
            searchingPlaceholder={"Search By Id and Customer Name"}
        />
    )
};

export default Payments;