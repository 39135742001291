import { LAST_PART, MID_PART, TOP_PART } from "../../../Helpers/ConstantProperties";
import { deepCopyObject } from "../../../Helpers/helpers";
import { fomatComponents } from "../CommonComp/commonFormatData";
import { outChallanFormat } from "../CommonComp/OutChallanFormat";
import { statementLayout } from "../CommonComp/StatementFormat";
import NewItemFormat from "./NewItemFormat";
import TaxHSNTable from "./TaxHSNTable";

const formatLayOutData = [
    {
        section: TOP_PART,
        rows: [
            {
                label: "Logo",
                layout: {
                    xs: 2
                },
            },
            {
                label: "Heading",
                layout: {
                    xs: 10,
                },
            },
        ]
    },
    {
        section: TOP_PART,
        rows: [
            {
                label: "Divider",
                style: {
                    paddingVertical: "3mm",
                }
            }
        ]
    },
    {
        section: TOP_PART,
        rows: [
            {
                label: "From",
            },
            {
                label: "To",
            },
            {
                label: "Details",
            },
        ]
    },
    {
        section: MID_PART,
        rows: [
            {
                label: "Items",
                style: {
                    paddingVertical: "3mm",
                }
            },
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                showOnLast: true,
                label: "Tax",
            }
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                showOnLast: true,
                label: "Bank",
                layout: {
                    xs: 5
                },
                style: {
                    paddingVertical: "3mm",
                    fontSize: 8,
                }
            },
            {
                showOnLast: true,
                label: "Empty",
                layout: {
                    xs: 3
                },
            },
            {
                showOnLast: true,
                label: "Total",
                layout: {
                    xs: 4
                },
                style: {
                    paddingRight: "3mm",
                    paddingBottom: "3mm",
                    alignSelf: "flex-end"
                }
            }
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                showOnLast: true,
                label: "Terms",
                layout: {
                    xs: 4
                },
            },
            {
                showOnLast: true,
                label: "Receiver",
                layout: {
                    xs: 4
                },
                style: {
                    fontSize: 8,
                    textAlign: "center",
                },
            },
            {
                showOnLast: true,
                label: "Sign",
                layout: {
                    xs: 4
                },
                style: {
                    fontSize: 8,
                    textAlign: "center",
                },
            }
        ]
    }
]

const poFormatData = [
    {
        section: TOP_PART,
        rows: [
            {
                label: "Logo",
                layout: {
                    xs: 2
                },
            },
            {
                label: "Heading",
                layout: {
                    xs: 10,
                },
            },
        ]
    },
    {
        section: TOP_PART,
        rows: [
            {
                label: "Divider",
                style: {
                    paddingVertical: "3mm",
                }
            }
        ]
    },
    {
        section: TOP_PART,
        rows: [
            {
                label: "From",
            },
            {
                label: "To",
            },
            {
                label: "Details",
            },
        ]
    },
    {
        section: MID_PART,
        rows: [
            {
                label: "Items",
                style: {
                    paddingVertical: "3mm",
                }
            },
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                showOnLast: true,
                label: "Tax",
                layout: {
                    xs: 8
                },
                style: {
                    paddingRight: "3mm",
                    paddingBottom: "3mm",
                    alignSelf: "flex-start"
                }
            },
            {
                showOnLast: true,
                label: "Total",
                layout: {
                    xs: 4
                },
                style: {
                    paddingRight: "3mm",
                    paddingBottom: "3mm",
                    alignSelf: "flex-end"
                }
            }
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                showOnLast: true,
                label: "Terms",
                layout: {
                    xs: 4
                },
            },
            {
                showOnLast: true,
                label: "Receiver",
                layout: {
                    xs: 4
                },
                style: {
                    fontSize: 8,
                    textAlign: "center",
                },
            },
            {
                showOnLast: true,
                label: "Sign",
                layout: {
                    xs: 4
                },
                style: {
                    fontSize: 8,
                    textAlign: "center",
                },
            }
        ]
    }
]

const defaultGetComponent = ({ label, currentFirm, context, page }) => {
    let componentToReturn = null;

    switch (label) {
        case "Items":
            componentToReturn = <NewItemFormat context={context} page={page} />
            break;

        case "Tax":
            componentToReturn = <TaxHSNTable context={context} />
            break;

        default:
            componentToReturn = fomatComponents(label, currentFirm, context, page)
            break;
    }

    return componentToReturn;
}

// For BOM we need to change the item table
const bomLayout = deepCopyObject(formatLayOutData);

const midRows = bomLayout.find((entry) => entry.section === MID_PART);

if (midRows) {
    const index = midRows.rows.findIndex((entry) => entry.label === "Items");
    if (index !== -1) midRows.rows[index].label = "BOMItems"
}

export const newFormat = {
    maxInPage: 11,
    pageSize: 22,
    quoteLayout: formatLayOutData,
    invoiceLayout: formatLayOutData,
    bomLayout: bomLayout,
    statementLayout: statementLayout,
    outChallanLayout: outChallanFormat,
    poLayout: poFormatData,
    getComponent: defaultGetComponent,
}