import { MenuItem } from "@mui/material";
import TDSForm from "./TDSForm";

const AddTDS = ({ item, showDialog }) => {
    const onMenuClick = () => {
        showDialog({
            contents: <TDSForm item={item} />,
            title: "Change TDS Information",
        });
    }


    return (
        <>
            <MenuItem onClick={() => onMenuClick()}>
                {"Change TDS"}
            </MenuItem>
        </>
    );
};

export default AddTDS;