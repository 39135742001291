import Grid2 from "@mui/material/Unstable_Grid2";
import { DashBoardController } from "../../Dashboard/DashBoardController";
import ReportHeader from "../Formats/ReportHeader";
import ChartAndList from "../INR/ChartAndList";
import { ShowNumber } from "../../../Helpers/helpers";

function SourcewisePieChart({ transform }) {
    const pieChart = ({ leads }) => {
        const groupedLeads = Object.groupBy(leads, ({ sourceOfLeadId }) => sourceOfLeadId);

        const accList = []
        const chartData = []

        Object.entries(groupedLeads).forEach(([key, value]) => {
            accList.push({
                name: value[0].sourceOfLead,
                value: value.length
            })

            chartData.push({
                name: value[0].sourceOfLead,
                value: value.length,
                fill: '#198754',
                acc: accList
            })
        });

        return (
            <Grid2 container rowGap={2}>

                <Grid2 xs={12} className="text-success">
                    <ReportHeader
                        total={ShowNumber(leads.length, 0)}
                        header={"Current Inquiries"}
                        transform={transform} />
                </Grid2>

                <Grid2 xs={12}>
                    <ChartAndList
                        chartData={chartData}
                        transform={transform}
                        formatText={(text) => ShowNumber(text)}
                    />
                </Grid2>

            </Grid2>
        );
    }

    return <DashBoardController
        RenderedComponent={pieChart}
    />;
}

export default SourcewisePieChart;