import Grid2 from "@mui/material/Unstable_Grid2";
import { INQUIRY_REJECT_TAG, rejectComments } from "../../../Helpers/ExtraProperties";
import { DashBoardController } from "../../Dashboard/DashBoardController";
import ReportHeader from "../Formats/ReportHeader";
import { ShowNumber } from "../../../Helpers/helpers";
import ChartAndList from "../INR/ChartAndList";

function RejectedInquiryPieChart({ transform }) {

    const pieChart = ({ leads }) => {

        const groupedLeads = Object.groupBy(leads, ({ sourceOfLeadId }) => sourceOfLeadId);

        const chartData = []

        Object.entries(groupedLeads).forEach(([key, value]) => {

            const accList = [];

            const reasons = Object.groupBy(value, ({ rejectionReasonId }) => rejectionReasonId)

            Object.entries(reasons).forEach(([key, value]) => {
                accList.push({
                    name: rejectComments.find((reason) => reason.id === parseFloat(key))?.message,
                    value: value.length
                })
            });

            chartData.push({
                name: value[0].sourceOfLead,
                value: value.length,
                fill: '#198754',
                acc: accList
            })
        })

        return (
            <Grid2 container rowGap={2}>

                <Grid2 xs={12} className="text-danger">
                    <ReportHeader
                        total={ShowNumber(leads.length, 0)}
                        header={"Rejected Inquiries"}
                        transform={transform} />
                </Grid2>

                <Grid2 xs={12}>
                    <ChartAndList
                        chartData={chartData}
                        transform={transform}
                        formatText={(text) => ShowNumber(text)}
                    />
                </Grid2>

            </Grid2>
        );
    }

    return <DashBoardController
        RenderedComponent={pieChart}
        tag={INQUIRY_REJECT_TAG}
    />;
}

export default RejectedInquiryPieChart;