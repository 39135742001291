import React, { useContext } from 'react';
import NewPurchaseForm from '../Purchase/NewPurchseForm';
import { propertyList } from '../../Helpers/ExtraProperties';
import { COMMON_BATCH, INR_RESOURCE_ID, JOURNAL_VOUCHER, TOTAL_ASSETS_ID, UPDATE_ON_INR_ACCOUNT } from '../../Helpers/ConstantProperties';
import { getPartyINRBatchObject } from '../../Helpers/helpers';
import { getPurchaseINRTransaction } from '../Quotation/RateCalculator';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';

const PurchaseAssets = () => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const queryFn = async ({
        selectedDate,
        party,
        refID,
        cData
    }) => {
        const voucher = {
            vendorName: party.name,
            vendorId: party.id,
            cData: cData,
            date: selectedDate,
            verified: true,
            refranceId: refID,
            type: JOURNAL_VOUCHER,
            batches: [],
            transactions: [],
        }

        cData.items.forEach((product) => {

            voucher.transactions.push({
                accountID: product.id,
                resourceID: INR_RESOURCE_ID,
                units: product.taxableAmount,
                batches: [{ id: COMMON_BATCH, units: product.taxableAmount }]
            })

        })

        const inrBatch = getPartyINRBatchObject(
            JOURNAL_VOUCHER,
            selectedDate,
            refID
        )

        voucher.batches.push(inrBatch);
        voucher.inrBatch = inrBatch.id;

        const inrTransactions = getPurchaseINRTransaction(
            party,
            cData,
            (voucher.batches.length - 1),
            TOTAL_ASSETS_ID,
        )

        voucher.transactions.push(...inrTransactions);

        return await serviceHelpers.createVoucherAndAdjustAdvance(
            token, khID, voucher, party.id);

    }

    const qkeys = [UPDATE_ON_INR_ACCOUNT]

    const predicate = (query) =>
        qkeys.some((queryKey) => query.queryKey.includes(queryKey))

    return <NewPurchaseForm
        title={"Assets"}
        itemList={propertyList.purchaseAssetsList}
        queryFn={queryFn}
        predicate={predicate} />
};

export default PurchaseAssets;