import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useContext } from 'react';
import { SchemaTypes } from '../../Helpers/ExtraProperties';
import AddProperty from '../AddProperties/AddProperty';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { NOT_AVAILABLE, OUTWORD_CHALLAN, TAX_INVOICE } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { useQuery } from '@tanstack/react-query';
import { GenericErrorComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import GenericSpinner from '../GenericComponents/FormComponent/GenericSpinner';
import ChallanList from './ChallanList';

const CustomerChallans = ({ challanList, party, setParty }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const qKey = [
        token,
        khID,
        party?.id,
        OUTWORD_CHALLAN,
        TAX_INVOICE + NOT_AVAILABLE
    ]

    const qFn = async () => {
        if (party === null) return null

        const options = {
            customerId: party.id,
            type: OUTWORD_CHALLAN,
            invoiceID: NOT_AVAILABLE
        }

        return await serviceHelpers.getCustomChallans(token, khID, options)
    }

    const { data, isLoading, error } = useQuery({
        queryKey: qKey,
        queryFn: qFn
    })

    if (error) return <GenericErrorComponent error={error} />
    if (isLoading) return <GenericSpinner />

    return (
        <Grid2 container rowGap={1}>

            <Grid2 xs={6}>
                <AddProperty
                    data={{
                        item: {
                            displayName: "Customer",
                            name: 'customer',
                            required: true,
                            type: SchemaTypes.PARTYDROPDOWN,
                            helperText: 'Please Select the Customer',
                        }
                    }}
                    deleteField={(element) => { setParty(null) }}
                    currentValue={party}
                    onChange={(e) => setParty(e.value)}
                />
            </Grid2>


            <Grid2 xs={6}>
                <ChallanList
                    data={data}
                    list={challanList.data}
                    addEntry={challanList.addEntry}
                    removeEntry={challanList.removeEntry} />
            </Grid2>
        </Grid2>
    );
};

export default CustomerChallans;