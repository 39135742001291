import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import PurchaseVendor from './PurchaseVendor';
import PurchaseDetails from './PurchaseDetails';
import PurchaseItems from './PurchaseItems';
import PurchaseTax from './PurchaseTax';
import PurchaseTotal from './PurchaseTotal';

const PurchaseInvoice = ({ cData, removeEntry = null, }) => {
    if (!cData.skipCurrency && !cData.roundedTotal) return <></>

    return (
        <Grid2 padding={1} container rowGap={2} border={"1px, solid, lightgray"}>
            <Grid2 xs={12} md={6}>
                <PurchaseVendor cData={cData} />
            </Grid2>

            <Grid2 xs={12} md={6}>
                <PurchaseDetails cData={cData} />
            </Grid2>

            <Grid2 xs={12}>
                <PurchaseItems cData={cData} removeEntry={removeEntry} />
            </Grid2>

            {
                !cData.skipCurrency &&
                <Grid2 xs={12} md={9}>
                    <PurchaseTax cData={cData} />
                </Grid2>
            }

            {
                !cData.skipCurrency &&
                <Grid2 xs={12} md={3} alignContent={"end"}>
                    <PurchaseTotal cData={cData} />
                </Grid2>
            }
        </Grid2>
    )
};

export default PurchaseInvoice;