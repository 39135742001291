import { MenuItem } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CHANGE_BOM } from '../../Helpers/ConstantProperties';

const ChangeBOM = ({ item }) => {
    const navigate = useNavigate();

    const onMenuClick = () => {
        navigate(CHANGE_BOM, { state: { product: item } });
    }

    return (
        <>
            <MenuItem onClick={() => onMenuClick()}>
                {"Change BOM"}
            </MenuItem>
        </>
    );
};

export default ChangeBOM;