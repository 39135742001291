import { Button } from "@mui/material";

export default function SaveGSTROne({ gstrJson }) {
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToJson = () => {
        downloadFile({
            data: JSON.stringify(gstrJson),
            fileName: 'gstr.json',
            fileType: 'text/json',
        })
    }

    return <Button onClick={exportToJson}>Save</Button>
}