import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useState } from 'react';
import AddProperty from '../AddProperties/AddProperty';
import { SchemaTypes } from '../../Helpers/ExtraProperties';
import { Button } from '@mui/material';
import { grayBorder } from '../../Helpers/ConstantProperties';

const BOMDDQty = ({ itemList, addEntryToArray }) => {
    const [product, setProduct] = useState(null);
    const [qty, setQty] = useState(1);

    const canAdd = product !== null && qty > 0;

    const addToList = () => {
        addEntryToArray(product, qty);
        setProduct(null);
        setQty(1)
    }

    return (
        <Grid2 container border={grayBorder} padding={1}>
            <Grid2 xs={12}>
                <AddProperty
                    data={{
                        item: itemList,
                    }}
                    deleteField={(element) => { setProduct(null) }}
                    currentValue={product}
                    onChange={(e) => setProduct(e.value)}
                />
            </Grid2>

            <Grid2 xs={12}>
                <AddProperty
                    data={{
                        item: {
                            displayName: 'Quantity',
                            name: 'units',
                            required: true,
                            type: SchemaTypes.QUANTITY,
                            helperText: 'Quantity needed',
                            disabled: product === null
                        }
                    }}
                    deleteField={(element) => { setQty(1) }}
                    currentValue={qty}
                    onChange={(e) => setQty(e.value)}
                />
            </Grid2>

            <Grid2 xs={12} textAlign={"center"} alignSelf={"center"} paddingTop={2}>
                <Button
                    onClick={addToList}
                    disabled={!canAdd}
                    variant="contained"
                    color="success">
                    {"Add"}
                </Button>
            </Grid2>
        </Grid2>
    )

};

export default BOMDDQty;