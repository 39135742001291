import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import useArray from '../GenericComponents/Array/GenericArrayHandler';
import { Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import AddProperty from '../AddProperties/AddProperty';
import { PARTY_TAG, propertyList, SchemaTypes } from '../../Helpers/ExtraProperties';
import GenericFormHeader from '../GenericComponents/FormComponent/GenericFormHeader';
import TransactionTable from './TransactionTable';
import { ACCOUNT_PAYABLE, ACCOUNT_RECEIVABLE, COMMON_BATCH, DEMI_INR_RESOURCE_ID, INR_RESOURCE_ID, JOURNAL_VOUCHER, ROUND_ACCOUNT, ROUNDOFF_ID, TOTAL_OTHER_PAYABLE_ID, TOTAL_OTHER_RECEIVABLE_ID, UPDATE_ON_INR_ACCOUNT, UPDATE_ON_VOUCHER } from '../../Helpers/ConstantProperties';
import { getRoundUptoTwoPlaces } from '../../Helpers/helpers';
import GenericMutateButton from '../GenericComponents/Buttons/GenericMutateButton';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { useQueryClient } from '@tanstack/react-query';
import PartyInvoiceDetails from '../TaxInvoice/PartyInvoiceDetails';
import { DateContext } from '../Contexts/DateContext';

const AddNewINRVoucher = ({
    voucherType = JOURNAL_VOUCHER,
    getNote = null,
    batchID,
    initState = [] }) => {

    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const { currentDate } = useContext(DateContext);

    const [refID, setRefID] = useState("")
    const [voucherDate, setVoucherDate] = useState(dayjs(new Date(currentDate)));
    const [voucherNote, setVoucherNote] = useState(getNote ? getNote(voucherDate) : "");

    const onDateChange = (value) => {
        setVoucherDate(value);

        if (getNote) {
            setVoucherNote(getNote(value))
        }
    }

    const { data, addEntry, removeEntry, clearData } = useArray({ initState: initState });

    const voucher = {
        date: voucherDate,
        verified: true,
        type: voucherType,
        notes: voucherNote,
        transactions: [],
    }

    if (refID !== "") {
        voucher.refranceId = refID;
    }

    let diff = 0;

    const paybleBatches = [];
    const receivableBatches = [];

    data.forEach((txn) => {

        if (txn.account.tag === PARTY_TAG) {
            if (txn.effect < 0) {
                paybleBatches.push({ id: txn.account.id, units: txn.effect })
            }
            else {
                receivableBatches.push({ id: txn.account.id, units: txn.effect })
            }
        }

        voucher.transactions.push({
            resourceID: INR_RESOURCE_ID,
            accountID: txn.account.id,
            accountName: txn.account.name,
            batches: txn.batches,
            units: txn.effect,
        })

        diff += txn.effect;
    })

    if (paybleBatches.length > 0) {
        const total =
            paybleBatches.reduce((total, batch) => total + batch.units, 0);

        voucher.transactions.push({
            accountID: ACCOUNT_PAYABLE,
            resourceID: DEMI_INR_RESOURCE_ID,
            batches: paybleBatches,
            units: total
        })

        voucher.transactions.push({
            accountID: TOTAL_OTHER_PAYABLE_ID,
            resourceID: DEMI_INR_RESOURCE_ID,
            batches: [{ id: COMMON_BATCH, units: -total }],
            units: -total
        })
    }

    if (receivableBatches.length > 0) {
        const total =
            receivableBatches.reduce((total, batch) => total + batch.units, 0);

        voucher.transactions.push({
            accountID: ACCOUNT_RECEIVABLE,
            resourceID: DEMI_INR_RESOURCE_ID,
            batches: receivableBatches,
            units: total
        })

        voucher.transactions.push({
            accountID: TOTAL_OTHER_RECEIVABLE_ID,
            resourceID: DEMI_INR_RESOURCE_ID,
            batches: [{ id: COMMON_BATCH, units: -total }],
            units: -total
        })
    }

    diff = getRoundUptoTwoPlaces((diff));

    if (diff !== 0 && getRoundUptoTwoPlaces(Math.abs(diff)) <= 0.5) {

        voucher.transactions.push({
            resourceID: INR_RESOURCE_ID,
            accountID: ROUNDOFF_ID,
            accountName: ROUND_ACCOUNT.name,
            skipDelete: true,
            batches: [{ id: (batchID || COMMON_BATCH), units: -diff }],
            units: -diff,
        })

        diff -= diff
    }

    const voucherQueryFn = async () => {
        return await serviceHelpers.creteProductVoucher(token, khID, voucher);
    }

    const qkeys = [UPDATE_ON_VOUCHER, UPDATE_ON_INR_ACCOUNT]
    const queryClient = useQueryClient();

    const predicate = (query) =>
        qkeys.some((queryKey) => query.queryKey.includes(queryKey))

    const onSuccess = async () => {
        await queryClient.invalidateQueries({
            predicate: predicate
        });

        setRefID("");
        clearData()
    }

    return (
        <Box
            autoComplete="off"
            className="mx-auto"
            sx={{
                width: {
                    sm: "95%",
                }
            }}>
            <GenericFormHeader title={"New " + voucherType + " Voucher"} enableBack={true} />
            <Grid2 container rowSpacing={3}>
                <Grid2 xs={12} md={6}>
                    <AddProperty
                        data={{
                            item: {
                                ...propertyList.description,
                                disabled: getNote !== null,
                            }
                        }}
                        deleteField={(element) => { setVoucherNote("") }}
                        currentValue={voucherNote}
                        onChange={(e) => setVoucherNote(e.value)}
                    />

                </Grid2>
                <Grid2 xs={12} md={3}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: "Referance ID",
                                name: "refID",
                                type: SchemaTypes.String,
                                required: false,
                                helperText: 'Please enter the Referance ID',
                            },
                        }}
                        deleteField={(element) => { setRefID("") }}
                        currentValue={refID}
                        onChange={(e) => setRefID(e.value)}
                    />
                </Grid2>
                <Grid2 xs={12} md={3}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: 'Date',
                                name: 'documentDate',
                                required: true,
                                type: SchemaTypes.DATEPICKER,
                                helperText: 'Please Select the Date',
                            },
                            extraProps: {
                                disableFuture: true
                            },
                        }}
                        deleteField={(element) => { onDateChange(dayjs()) }}
                        currentValue={voucherDate}
                        onChange={(e) => onDateChange(e.value)}
                    />
                </Grid2>

                <Grid2 xs={12} md={6} className="bg-secondary-subtle">
                    <PartyInvoiceDetails
                        addReceipentToArray={addEntry}
                        useBatch={batchID} />
                </Grid2>

                <Grid2 xs={12} md={6} paddingX={1}>
                    <TransactionTable
                        voucher={voucher}
                        removeEntry={removeEntry} />
                </Grid2>

                <Grid2 xs={12} className="text-center">
                    <GenericMutateButton
                        queryFn={voucherQueryFn}
                        disable={voucher.transactions.length === 0 || diff !== 0}
                        onSuccess={onSuccess}
                        successMessage="voucher Created" />
                </Grid2>
            </Grid2>
        </Box>
    )
};

export default AddNewINRVoucher;