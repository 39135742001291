import { COMMON_BATCH, DEMI_INR_RESOURCE_ID, INQUIRY_STORE_ACCOUNT_ID, INR_RESOURCE_ID, TAX_INVOICE } from "../../Helpers/ConstantProperties";
import { PARTY_TAG } from "../../Helpers/ExtraProperties";
import { getPartyINRBatchObject } from "../../Helpers/helpers";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { getSaleINRTransactions } from "../Quotation/RateCalculator";
import { getStoreBatches } from "./invoiceHelper";

export const createVoucherObject = async ({
    cData,
    title,
    type,
    collAccounts,
    token,
    khID,
    prefix
}) => {
    let note =
        title
        + " by "
        + cData.bank.name
        + " as "
        + cData.payMode
        + ". Ref: "
        + cData.refID
        + " \r\n";

    if (cData.notes) note += cData.notes;

    // Create Voucher
    const voucher = {
        cData: cData,
        type: type,
        date: cData.date,
        verified: true,
        transactions: [],
        notes: note
    }

    const partyBatches = [];
    let totalAmount = 0;

    if (cData.accList.length === 1) {
        // This means payment for only One Party.
        voucher.partyName = cData.accList[0].account.name;
    }
    else {
        voucher.partyName = "Multiple Accounts";
    }

    cData.accList.forEach((party) => {

        totalAmount += parseFloat(party.effect);

        voucher.transactions.push({
            accountID: party.account.id,
            resourceID: INR_RESOURCE_ID,
            units: party.effect,
            batches: party.batches
        })

        if (party.account.tag === PARTY_TAG) {
            partyBatches.push({ id: party.account.id, units: party.effect });
        }
    })

    if (partyBatches.length > 0) {
        // Demi INR collection Accounts only if this is party
        const total = partyBatches.reduce((total, entry) => total + entry.units, 0);

        voucher.transactions.push({
            accountID: collAccounts[0],
            resourceID: DEMI_INR_RESOURCE_ID,
            batches: partyBatches,
            units: total
        })

        voucher.transactions.push({
            accountID: collAccounts[1],
            resourceID: DEMI_INR_RESOURCE_ID,
            batches: [{ id: COMMON_BATCH, units: -total }],
            units: -total,
        })
    }

    // To Bank
    voucher.transactions.push({
        accountID: cData.bank.id,
        resourceID: INR_RESOURCE_ID,
        units: -totalAmount,
        batches: [{ id: cData.payMode, units: -totalAmount }]
    })

    const refId = await serviceHelpers.getSeriesNumber(
        token, khID, { prefix: prefix });

    voucher.refranceId = refId.id;
    voucher.cData.refranceId = refId.id;
    voucher.ammount = Math.abs(totalAmount);

    return serviceHelpers.creteProductVoucher(token, khID, voucher);
}

const getBatchObject = (batches, multiplyer) => {
    return Object.entries(batches).map(([Key, Value]) => {
        return { id: Key, units: (multiplyer * Value) }
    })
}

export const createTaxInvoiceVoucher = async ({
    token,
    khID,
    prefix,
    data,
    party,
    cData,
    currentDate,
    vType = TAX_INVOICE,
    extraProps = {}
}) => {
    const voucher = {
        customerName: party.name,
        city: party.city,
        customerId: party.id,
        type: vType,
        date: currentDate,
        verified: true,
        cData: cData,
        transactions: [],
        batches: [],
        ...extraProps
    }

    const storeBatches = data.map(async (entry) => {
        const batches = await getStoreBatches(token, khID, entry.product, entry.units);

        const txnObject = {
            customerName: party.name,
            resourceID: entry.product.id,
        }

        // From Store
        voucher.transactions.push({
            ...txnObject,
            accountID: INQUIRY_STORE_ACCOUNT_ID,
            units: entry.units * -1,
            batches: getBatchObject(batches, -1)
        })

        // To Party
        voucher.transactions.push({
            ...txnObject,
            accountID: party.id,
            units: entry.units,
            batches: getBatchObject(batches, 1)
        })
    })

    await Promise.all(storeBatches);

    const refId = await serviceHelpers.getSeriesNumber(
        token, khID, { prefix: prefix });

    voucher.refranceId = refId.id;

    if (!cData.skipCurrency) {
        const inrBatch = getPartyINRBatchObject(
            TAX_INVOICE,
            currentDate,
            voucher.refranceId
        )

        voucher.batches.push(inrBatch);
        voucher.inrBatch = inrBatch.id;

        const inqObject = {
            gstin: party.gstin,
            customerName: party.name,
            customerId: party.id
        }

        const inrTransactions = getSaleINRTransactions(inqObject, cData);
        voucher.transactions.push(...inrTransactions);
    }

    const result = await serviceHelpers.createVoucherAndAdjustAdvance(
        token, khID, voucher, party.id);

    return { id: result.id, refId: refId.id }
}