import { useQuery } from '@tanstack/react-query';
import { BALANCE, INR_RESOURCE_ID, TDS_COLLECTED_ID, UPDATE_ON_INR_ACCOUNT, UPDATE_ON_VOUCHER } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import GenericSpinner from '../GenericComponents/FormComponent/GenericSpinner';
import { GenericErrorComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { useContext } from 'react';
import { Alert, Box } from '@mui/material';
import { getLocalDateString } from '../../Helpers/helpers';
import Grid2 from '@mui/material/Unstable_Grid2';
import TDSBalancePay from './TDSBalancePay';

const TDSBalance = ({ selectedMonth }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const asOnDate = selectedMonth.endOf("month");

    const queryKey = [
        token,
        khID,
        UPDATE_ON_VOUCHER,
        UPDATE_ON_INR_ACCOUNT,
        INR_RESOURCE_ID,
        BALANCE + TDS_COLLECTED_ID,
        new Date(asOnDate).getTime()
    ];

    // Get all the transaction from given month for OutGST account
    const queryFn = async () => {
        const tdsOptions = {
            accountID: TDS_COLLECTED_ID,
            resourceID: INR_RESOURCE_ID,
            date: asOnDate,
            withBatches: true,
        }

        return await serviceHelpers.getResourceBalance(token, khID, tdsOptions)
    }

    const { data, isLoading, error } = useQuery({
        queryKey: queryKey,
        queryFn: queryFn,
    })

    if (isLoading) return <GenericSpinner />
    if (error) return <GenericErrorComponent error={error} />

    if (data && data[0]) {

        if (data[0].units === 0) {
            return (
                <Box paddingX={2}>
                    <Alert color='success'>
                        {"No TDS amount is due as on date " + getLocalDateString(asOnDate)}
                    </Alert>
                </Box>
            )
        }

        return (
            <Box paddingX={2}>
                <Grid2 container>
                    {
                        Object.entries(data[0].batches).map(([id, value]) => (
                            <Grid2 xs={12} key={id}>
                                <TDSBalancePay id={id} value={value} />
                            </Grid2>
                        ))
                    }
                </Grid2>
            </Box>
        )

    }

    return <></>
};

export default TDSBalance;