import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useQuery } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { INR_RESOURCE_ID, TAX_INVOICE } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { ShowNumber } from '../../Helpers/helpers';

const PartyInvoiceAndBalance = ({ item }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const invoiceCount = {
        queryKey: [
            token,
            khID,
            TAX_INVOICE + item.id
        ],
        queryFn: async () => {
            return await serviceHelpers.getInvoices(token, khID, null, null, item.id, true);
        }
    }

    const results = useQuery(invoiceCount);

    if (results.data) {
        return (
            <>
                <Grid2 xs={12} sm={3} md={2} lg={3}>
                    <Typography
                        variant="body2"
                        sx={{
                            textAlign: { xs: 'left', sm: 'center' },
                            paddingLeft: { xs: '0.3rem' },
                            color: 'text.secondary'
                        }}
                    >
                        Invoices: {results.data[0] || 0}
                    </Typography>
                </Grid2>

                <Grid2 xs={12} sm={3} md={2} lg={3}>
                    <Typography
                        variant="body2"
                        sx={{
                            textAlign: { xs: 'left', sm: 'center' },
                            paddingLeft: { xs: '0.3rem' },
                            color: 'text.secondary'
                        }}
                    >
                        Balance: {ShowNumber((item[INR_RESOURCE_ID] || 0), 2, true)}
                    </Typography>
                </Grid2>
            </>
        );
    }

    return <></>
};

export default PartyInvoiceAndBalance;