import { Pie, PieChart, Sector } from "recharts";

const SegmentedPieChart = ({
    formatText = (text) => text,
    data,
    activeIndex,
    setActiveIndex,
    width = 200,
    height = 200,
    dataKey = "value" }) => {

    const renderActiveShape = (props) => {
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value } = props;

        const RADIAN = Math.PI / 180;

        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);

        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;

        const ex = mx;
        const ey = my;

        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={-10} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>

                <text x={cx} y={cy} dy={20} textAnchor="middle" fill={fill}>
                    {formatText(value)}
                </text>

                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={payload.fill}
                />

                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 5}
                    outerRadius={outerRadius + 7}
                    fill={payload.fill}
                />

                <text
                    x={ex + (cos >= 0 ? 1 : -1) * 5}
                    y={ey}
                    textAnchor={textAnchor}
                    fill="#333">

                </text>
            </g>
        );
    }

    const innerRadius = 0.30 * width;
    const outerRadius = 0.35 * width;

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    return (
        <PieChart width={width} height={height}>
            <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={data}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                dataKey={dataKey}
                onMouseEnter={onPieEnter}
            />
        </PieChart>
    );

};

export default SegmentedPieChart;