import React from 'react';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { useNavigate } from 'react-router-dom';
import { GenericErrorComponent, GenericSuccessComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { COLOR_GREEN, commonFontSize, commonFontWeight, PRIMARY_COLOR, PUR_INV_VIEW } from '../../Helpers/ConstantProperties';
import GenericSkeleton from '../GenericComponents/DataView/GenericSkeleton';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Paper, Typography } from '@mui/material';
import CardActionButtons from '../GenericComponents/DataView/CardActionButtons';
import ViewMenuItem from '../GenericComponents/DataView/ViewMenuItem';
import AddTransport from '../Dashboard/DashboardMenuOptions/AddTransport';
import DeleteMenuItem from '../GenericComponents/DataView/DeleteMenuItem';
import { getLocalDateString, ShowNumber } from '../../Helpers/helpers';
import PurchaseProductList from './PurchaseProductList';
import ApplyTDS from '../TaxInvoice/ApplyTDS';
import PostPayment from '../TaxInvoice/PostPayment';

const PurchaseCardBody = ({ item, navTo, qKey, showUnits, ignoreTransport = false }) => {
    const deleteChallan = async (token, khID) => {
        return await serviceHelpers.deleteChallans(token, khID, item.id);
    };

    const navigate = useNavigate();

    const onDelete = {
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={"Invoice Deleted."} />,
        navigateTo: navTo,
        queryFunction: deleteChallan,
        queryKeyValue: qKey,
    };

    if (item.showSkeleton) return <GenericSkeleton />;

    const menuList = []

    if (!ignoreTransport) {
        menuList.push(<AddTransport item={item} />)
    }

    menuList.push(<PostPayment item={item} />)
    menuList.push(<ApplyTDS item={item} />)
    menuList.push(<ViewMenuItem onView={() => {
        navigate(
            PUR_INV_VIEW,
            { state: { voucherObject: item } })
    }} />)
    menuList.push(<DeleteMenuItem onDelete={onDelete} />)

    return (
        <Grid2 key={item.id} xs={12}>
            <Paper
                sx={{
                    borderLeft: `15px solid ${COLOR_GREEN}`,
                    padding: '0.3rem',
                    minWidth: 0,
                    px: 1
                }}
                elevation={2} >

                <Grid2 container alignItems="center" spacing={2}>
                    <Grid2 xs={12} md={3}>
                        <Typography
                            noWrap
                            variant="subtitle2"
                            color={"primary.main"}
                            sx={{
                                fontWeight: commonFontWeight,
                                textAlign: { xs: 'left', sm: 'left' }
                            }}
                        >
                            {item.vendorName}
                        </Typography>
                    </Grid2>

                    <Grid2 xs={12} md={2}>
                        <Typography
                            noWrap
                            color={PRIMARY_COLOR}
                            fontSize={commonFontSize}
                            fontWeight={commonFontWeight}
                        >
                            {item.refranceId}
                        </Typography>

                    </Grid2>

                    <Grid2 xs={12} md={1}>
                        <Typography
                            noWrap
                            fontSize={commonFontSize}
                        >
                            {getLocalDateString(item.date)}
                        </Typography>
                    </Grid2>

                    <Grid2 xs={12} md={3}>
                        <Typography
                            noWrap
                            textAlign={"right"}
                            paddingRight={2}
                            fontSize={commonFontSize}
                        >
                            {ShowNumber(item.cData.roundedTotal, 2, true)}
                        </Typography>

                    </Grid2>

                    <Grid2 xs={12} md={2}>
                        <PurchaseProductList
                            products={item.cData.items || []}
                            showUnits={showUnits} />
                    </Grid2>

                    <Grid2
                        xs={12}
                        md
                        sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

                        <CardActionButtons menuList={menuList} />
                    </Grid2>
                </Grid2>
            </Paper>
        </Grid2>
    )
};

export default PurchaseCardBody;