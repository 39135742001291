import { useNavigate } from "react-router-dom";
import { NEWTDSVOUCHER } from "../../Helpers/ConstantProperties";
import { MenuItem } from "@mui/material";
import ViewTDSForm from "./ViewTDSForm";

const ApplyTDS = ({ item, showDialog }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(
            NEWTDSVOUCHER,
            { state: { document: item } });
    }

    const onMenuClick = () => {
        showDialog({
            contents: <ViewTDSForm document={item} />,
            title: "TDS information for " + item.refranceId
        });
    }

    if (item.tdsAdjusted) {
        return (
            <>
                <MenuItem onClick={() => onMenuClick()}>
                    {"View TDS"}
                </MenuItem>
            </>
        );
    }

    return (
        <>
            <MenuItem onClick={() => handleClick()}>
                {"Apply TDS"}
            </MenuItem>
        </>
    );
};

export default ApplyTDS;