import { useContext, useState } from "react";
import { DateContext } from "../Contexts/DateContext";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useQueryClient } from "@tanstack/react-query";
import Grid2 from "@mui/material/Unstable_Grid2";
import GenericFormHeader from "../GenericComponents/FormComponent/GenericFormHeader";
import { Box, Paper } from "@mui/material";
import AddProperty from "../AddProperties/AddProperty";
import { PAY_MODE_CASH, propertyList, SchemaTypes } from "../../Helpers/ExtraProperties";
import { CASH_ACCOUNT_ID, UPDATE_ON_INR_ACCOUNT } from "../../Helpers/ConstantProperties";
import useArray from "../GenericComponents/Array/GenericArrayHandler";
import AccountAmt from "./AccountAmt";
import PartyInvoiceDetails from "./PartyInvoiceDetails";
import GenericMutateButton from "../GenericComponents/Buttons/GenericMutateButton";

const NewPaymentForm = ({ title, queryFn, updateKey, nevigateRoute, batchMulti = 1 }) => {
    const { currentDate } = useContext(DateContext);

    const location = useLocation();
    const state = location.state || {};

    const defaultDate = state.date ? dayjs(state.date) : dayjs(currentDate)

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [selectedDate, setSelectedDate] = useState(defaultDate);
    const [refID, setRefID] = useState(state.refID ? state.refID : "");
    const [notes, setNotes] = useState(state.notes || "");
    const [payMode, setPayMode] = useState(state.payMode || null);
    const [bank, setBank] = useState(state.bank || null);

    const { data, addEntry, removeEntry, clearData } =
        useArray({ initState: state.initState ? state.initState : [] });

    const setBankAccount = (value) => {

        if (value?.id === CASH_ACCOUNT_ID) {
            setBank(value);
            setPayMode(PAY_MODE_CASH);
            setRefID(PAY_MODE_CASH);
        }
        else {
            setBank(value);
        }
    }

    const cData = {
        date: selectedDate,
        payMode: payMode,
        refID: refID,
        notes: notes,
        accList: data
    }

    if (bank) {
        cData.bank = { id: bank.id, name: bank.name, tag: bank.tag }
    }

    const createDoc = async () => {
        return await queryFn({ cData: cData })
    }

    const canSubmit = data.length !== 0
        && refID !== ""
        && payMode !== null
        && bank !== null

    const onSuccess = async () => {

        const qkeys = [updateKey, UPDATE_ON_INR_ACCOUNT]

        await queryClient.invalidateQueries({
            predicate: (query) =>
                qkeys.some((queryKey) => query.queryKey.includes(queryKey))
        });

        navigate(nevigateRoute)

        setBank(null);
        setPayMode(null);
        setRefID("");
        clearData();
    }

    return (
        <>
            <GenericFormHeader title={"Add New " + title} enableBack={true} />
            <Paper elevation={1} sx={{ m: 1 }}>
                <Grid2 container rowGap={1} padding={1}>

                    <Grid2 xs={12} md={2.5} alignContent={"flex-end"}>

                        <AddProperty
                            data={{
                                item: {
                                    displayName: 'Date',
                                    name: 'receiptDate',
                                    required: true,
                                    type: SchemaTypes.DATEPICKER,
                                    helperText: 'Please Select the Date',
                                },
                                extraProps: {
                                    disableFuture: true
                                }
                            }}
                            deleteField={(element) => { setSelectedDate(dayjs()) }}
                            currentValue={selectedDate}
                            onChange={(e) => setSelectedDate(e.value)}
                        />

                    </Grid2>

                    <Grid2 xs={12} md={4}>

                        <AddProperty
                            data={{
                                item: {
                                    displayName: 'Select Account ',
                                    name: 'payaccount',
                                    required: true,
                                    type: SchemaTypes.PAYACCOUNTDROPDOWN,
                                    helperText: 'Please Select the Account',
                                }
                            }}
                            deleteField={
                                (element) => { setBankAccount(null) }}
                            currentValue={bank}
                            onChange={(e) => setBankAccount(e.value)}
                        />

                    </Grid2>

                    <Grid2 xs={12} md={2.5}>

                        <AddProperty
                            data={{
                                item: propertyList.paymentMode
                            }}
                            deleteField={(element) => { setPayMode(null) }}
                            currentValue={payMode}
                            onChange={(e) => setPayMode(e.value)}
                        />

                    </Grid2>

                    <Grid2 xs={12} md={3}>

                        <AddProperty
                            data={{
                                item: {
                                    displayName: "Referance ID",
                                    name: "refID",
                                    type: SchemaTypes.String,
                                    required: true,
                                    helperText: 'Please enter the Referance ID',
                                },
                            }}
                            deleteField={(element) => { setRefID("") }}
                            currentValue={refID}
                            onChange={(e) => setRefID(e.value)}
                        />
                    </Grid2>

                    <Grid2 xs={12}>

                        <AddProperty
                            data={{
                                item: {
                                    displayName: "Notes",
                                    name: "notes",
                                    type: SchemaTypes.String,
                                    required: true,
                                    helperText: 'Please enter Notes',
                                },
                            }}
                            deleteField={(element) => { setNotes("") }}
                            currentValue={notes}
                            onChange={(e) => setNotes(e.value)}
                        />

                    </Grid2>

                    <Grid2 xs={12} md={6}>
                        <Box className="bg-secondary-subtle">
                            <PartyInvoiceDetails
                                addReceipentToArray={addEntry}
                                DefaultbatchMulti={batchMulti} />
                        </Box>
                    </Grid2>

                    <Grid2 xs={12} md={6}>
                        <AccountAmt cData={cData} removeEntry={removeEntry} />
                    </Grid2>

                    <Grid2 xs={12} textAlign={"center"} paddingY={2}>
                        <GenericMutateButton
                            queryFn={createDoc}
                            disable={!canSubmit}
                            onSuccess={onSuccess}
                            successMessage="voucher Created" />
                    </Grid2>
                </Grid2 >
            </Paper >
        </>
    )

};

export default NewPaymentForm;