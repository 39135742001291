import { useContext } from "react";
import { FirmContext } from "../Contexts/FirmContext";
import { AuthContext } from "../Auth/Auth";
import { PRODUCT_TYPE_RAW, PRODUCT_TYPE_SEMIFINISHED, PRODUCTION_PLAN_RESOURCE_ID, RMLIST } from "../../Helpers/ConstantProperties";
import { useQueries } from "@tanstack/react-query";
import GenericSpinner from "../GenericComponents/FormComponent/GenericSpinner";
import { GenericErrorComponent } from "../GenericComponents/FormComponent/GenericAlertComponent";
import InquiryStockCard from "./InquiryStockCard";
import { getProdPlanQureyObject, getProductResultDetails, getStoreProductQueryObject } from "./InquiryCalculations";
import { DateContext } from "../Contexts/DateContext";

export default function InquiryStock({ inquiry }) {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const { currentDate } = useContext(DateContext);

    const combinedRMList = {};
    const queries = [];
    const productsToManu = [...(inquiry.products || []), ...(inquiry.sfg || [])];

    productsToManu.forEach((product) => {
        product[RMLIST]?.forEach((raw) => {
            combinedRMList[raw.product.id] = 1;
        })

        product.product.bom?.forEach((req) => {
            if ([PRODUCT_TYPE_RAW, PRODUCT_TYPE_SEMIFINISHED].includes(req.product.type)) {
                combinedRMList[req.product.id] = 1;
            }
        });

        queries.push(getStoreProductQueryObject(token, khID, product.product.id, currentDate));
    })

    Object.keys(combinedRMList).map((rmID) => queries.push(
        getStoreProductQueryObject(token, khID, rmID, currentDate)));

    // Get the balance of Planned Data
    queries.push(getProdPlanQureyObject(token, khID, inquiry.id, currentDate))

    const results = useQueries({ queries: queries });

    if (results.some((result) => result.isPending)) return <GenericSpinner />

    // TODO: Need Better Error Handeling
    if (results.some((result) => result.isError)) return <GenericErrorComponent />

    if (results.every((result) => result.isSuccess)) {

        // There is only one planned balance query, we are not adding it for the store
        // so we can be sure that the planned balance is for the inquiry.
        const plannedBalanceResult = results.find(
            (res) => res.data[0]?.resourceID === PRODUCTION_PLAN_RESOURCE_ID);

        const plannedBalance = plannedBalanceResult?.data[0];
        const balanceData = getProductResultDetails(
            inquiry,
            plannedBalance,
            results);

        return <InquiryStockCard inquiry={inquiry} balanceDetails={balanceData} />
    }

    return <></>
}