import { OPENING_TYPE } from '../../Helpers/ConstantProperties';
import { getLocalDateString } from '../../Helpers/helpers';
import AddNewINRVoucher from './AddNewINRVoucher';

const AddClosingBalance = () => {
    const getNote = (voucherDate) => {
        return "Closing Balance as on " + getLocalDateString(voucherDate);
    }
    return <AddNewINRVoucher
        voucherType={OPENING_TYPE}
        getNote={getNote}
        batchID={OPENING_TYPE} />
};

export default AddClosingBalance;