import { Box, Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useState } from 'react';
import AddProperty from '../AddProperties/AddProperty';
import { SchemaTypes } from '../../Helpers/ExtraProperties';
import { ShowNumber } from '../../Helpers/helpers';
import { useNavigate } from 'react-router-dom';
import { NEWPAYMENTS, TDS_COLLECTED } from '../../Helpers/ConstantProperties';

const TDSBalancePay = ({ id, value }) => {
    const [cin, setCIN] = useState("");

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(
            NEWPAYMENTS,
            {
                state: {
                    refID: cin,
                    notes: "TDS payment for " + id,
                    initState: [{
                        account: {
                            name: TDS_COLLECTED.name,
                            tag: TDS_COLLECTED.tag,
                            id: TDS_COLLECTED.id
                        },
                        effect: -value.units,
                        batches: [{ id: id, units: -value.units }]
                    }]
                }
            });
    }

    return (
        <Box>
            <Grid2 container>
                <Grid2 xs={3}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: "TDS Section",
                                name: "section",
                                type: SchemaTypes.LABELED_TEXT,
                                required: true,
                                helperText: 'Please enter the CIN',
                            },
                        }}
                        deleteField={() => { }}
                        currentValue={id}
                        onChange={() => { }}
                    />
                </Grid2>

                <Grid2 xs={3}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: "Balance",
                                name: "balance",
                                type: SchemaTypes.LABELED_TEXT,
                                required: true,
                                helperText: 'Please enter the CIN',
                            },
                        }}
                        deleteField={() => { }}
                        currentValue={ShowNumber(Math.abs(value.units), 2, true)}
                        onChange={() => { }}
                    />
                </Grid2>

                <Grid2 xs={3}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: "CIN",
                                name: "cin",
                                type: SchemaTypes.String,
                                required: true,
                                helperText: 'Please enter the CIN',
                            },
                        }}
                        deleteField={(element) => { setCIN("") }}
                        currentValue={cin}
                        onChange={(e) => setCIN(e.value)}
                    />
                </Grid2>

                <Grid2 xs={3} textAlign={"center"} alignSelf={"center"}>
                    <Button
                        disabled={cin === ""}
                        variant='outlined'
                        onClick={handleClick}>
                        Make Payment
                    </Button>
                </Grid2>

            </Grid2>
        </Box>
    );
};

export default TDSBalancePay;