import React, { useContext } from 'react';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { GenericErrorComponent, GenericSuccessComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { GenericVerifyComponent } from '../GenericComponents/VerifyComponent/FirmVerifyComponent';
import GenericForm from '../GenericComponents/FormComponent/GenericForm';
import { FirmContext } from '../Contexts/FirmContext';
import { INTERNAL_PROCESS, NUMBERS, PRODUCT_TYPE_SEMIFINISHED } from '../../Helpers/ConstantProperties';
import { HSN_CODE_PROP, productProps, productPropsForInternalProc, productPropsForSFG } from '../../Helpers/ExtraProperties';
import { proceessPostDispatch, productConfig } from '../../Helpers/helpers';
import { useLocation } from 'react-router-dom';

const GenCreateProduct = () => {
    const { currentFirm } = useContext(FirmContext);
    const location = useLocation();

    const productType = location.state?.type;
    const processType = location.state?.processType;

    const formData = productConfig[productType];
    var propertyList = productProps;

    if (productType === PRODUCT_TYPE_SEMIFINISHED) {
        propertyList = productPropsForSFG;
    }

    if (processType === INTERNAL_PROCESS) {
        propertyList = productPropsForInternalProc;
    }

    const queryFunction = async (state, token, khID) => {
        state.type = productType;
        if (processType) state.processType = processType;

        return await serviceHelpers.createProduct(token, khID, state);
    }

    var currentData = {
        unit: NUMBERS,
        [HSN_CODE_PROP]: currentFirm[HSN_CODE_PROP],
        GSTRate: currentFirm.defaultGST?.toString()
    }

    const newFormProps = {
        ...formData,
        propertyList,
        queryFunction,
        navigateTo: -1,
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message="Added Suucesfully" />,
        enableBack: true,
        enableVerify: true,
        VerifyAlertComponent: GenericVerifyComponent,
        currentData: { ...currentData, ...location.state },
        enableClear: true,
        buttonClasses: "",
        afterDispatch: proceessPostDispatch,
    }

    return <GenericForm {...newFormProps} />
};

export default GenCreateProduct;