import { useContext } from 'react';
import { CREATEPRODUCT, EXTERNAL_PROCESS, INTERNAL_PROCESS, PRODUCT_TYPE_PROCESS, UPDATE_ON_PRODUCT } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import DataView from '../GenericComponents/DataView/DataView';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import DataviewList from '../GenericComponents/DataView/DataviewList';
import ProductCardComponent from './ProductCardComponent';
import { productConfig } from '../../Helpers/helpers';
import { useNavigate } from 'react-router-dom';

export function ManageProduct({ productType }) {
    const { currentUserObject, token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const formData = productConfig[productType];
    const navigate = useNavigate();

    const queryKey = [
        currentUserObject.uid,
        khID,
        UPDATE_ON_PRODUCT,
        formData.queryKeyValue
    ];

    const queryFunction = async (pageParam) => {
        return await serviceHelpers.getProducts(
            token,
            khID,
            null,
            { type: formData.productType, sort: "createdAt", desending: true },
            pageParam
        );
    }

    const getSearchableValue = (current) => {
        return (
            current.name + " "
            + current.productHSNcode + " "
            + current.productItemcode + " "
            + current.GSTRate + " "
            + current.saleRate + " "
            + current.unit
        )
    }

    const actionList = formData.productType === PRODUCT_TYPE_PROCESS
        ? [
            {
                onButtonClick: () => navigate(
                    CREATEPRODUCT,
                    { state: { type: formData.productType, processType: INTERNAL_PROCESS } }),

                buttonText: "Add Internal",
            },
            {
                onButtonClick: () => navigate(
                    CREATEPRODUCT,
                    { state: { type: formData.productType, processType: EXTERNAL_PROCESS } }),
                buttonText: "Add External",
            },
        ]
        : [
            {
                onButtonClick: () => navigate(
                    CREATEPRODUCT, { state: { type: formData.productType } }),
                buttonText: formData.addButtonText,
            },
        ]

    return (
        <DataView
            routeDetails={{ heading: formData.heading, subText: formData.subText }}
            limitSupported={true}
            getSearchableValue={getSearchableValue}
            queryKeyParameter={queryKey}
            queryFunctionParameter={queryFunction}
            ShowElement={{ Component: ProductCardComponent }}
            searchingPlaceholder={"Search By Name, Company Name, city, phoneNumber, email"}
            DisplayComponent={DataviewList}
            actionList={actionList}
        />
    );
}