import React, { useState } from 'react';
import { commonFontSize } from '../../../Helpers/ConstantProperties';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Box, Typography } from '@mui/material';
import SegmentedPieChart from '../../GenericComponents/Charts/SegmentedPieChart';

const ChartAndList = ({ chartData, transform, formatText }) => {

    const [activeIndex, setActiveIndex] = useState(0);

    const direction = transform ? "row-reverse" : "row";
    const justify = transform ? "end" : "start";
    const leftPadding = transform ? 1 : 0;

    if (chartData.length === 0) return <></>;

    return (
        <Typography component={"span"} fontSize={commonFontSize}>
            <Grid2 container direction={direction}>
                <Grid2 xs={12} md={6} justifyItems={justify}>

                    <SegmentedPieChart
                        formatText={formatText}
                        data={chartData}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex} />
                </Grid2>

                <Grid2 xs={12} md={6}>
                    <Box maxHeight={"90%"} overflow={"auto"}>
                        {
                            chartData[activeIndex].acc.map((acc, index) => (
                                <Grid2 key={index} container paddingLeft={leftPadding}>
                                    <Grid2 xs={8}>
                                        {acc.name}
                                    </Grid2>

                                    <Grid2 xs={4}>
                                        {acc.value}
                                    </Grid2>
                                </Grid2>
                            ))
                        }
                    </Box>
                </Grid2>
            </Grid2>
        </Typography >
    )
};

export default ChartAndList;