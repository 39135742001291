import React from 'react';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { GenericErrorComponent, GenericSuccessComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { PAY_ACCOUNTS, UPDATE_ON_INR_ACCOUNT } from '../../Helpers/ConstantProperties';
import GenericSkeleton from '../GenericComponents/DataView/GenericSkeleton';
import { Box, Paper } from '@mui/material';
import CardActionButtons from '../GenericComponents/DataView/CardActionButtons';
import DeleteMenuItem from '../GenericComponents/DataView/DeleteMenuItem';

const PayAccountCard = ({ item }) => {
    let successMessage = "Inquiry Source Deleted SuccessFully"

    const deleteInquiry = async (token, khid) => {
        return await serviceHelpers.deletePaymentAccount(token, khid, item.id);
    }

    const onDelete = {
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        navigateTo: PAY_ACCOUNTS,
        queryFunction: deleteInquiry,
        queryKeyValue: UPDATE_ON_INR_ACCOUNT,
    }

    const menuList = item.known
        ? []
        : [<DeleteMenuItem onDelete={onDelete} />]

    if (item.showSkeleton) return <GenericSkeleton />;

    return (
        <Paper className="bg-white">
            <CardActionButtons menuList={menuList} />
            <Box padding={1}>
                {item.name}
            </Box>
        </Paper>
    );
};

export default PayAccountCard;