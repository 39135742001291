import { Box, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { commonFontWeight, PRODUCT_DETAILS } from '../../Helpers/ConstantProperties';
import { useNavigate } from 'react-router-dom';
import { HSN_CODE_PROP } from '../../Helpers/ExtraProperties';

const ProductName = ({ product }) => {
    const navigate = useNavigate()

    var handleContactClick = () => {
        navigate(PRODUCT_DETAILS, { state: { product: product } })
    };

    return (
        <>
            <Grid2 xs={6} md={3}>
                <Box className='px-2'>
                    <Typography
                        noWrap
                        variant="subtitle2"
                        color={"primary.main"}
                        onClick={() => handleContactClick()}
                        sx={{
                            fontWeight: commonFontWeight,
                            cursor: 'pointer',
                            textAlign: { xs: 'left', sm: 'left' }
                        }}
                    >
                        {product.name}
                    </Typography>
                    <Typography
                        noWrap
                        variant="body2"
                        sx={{
                            textAlign: { xs: 'left', sm: 'left' },
                            color: 'text.secondary'
                        }}
                    >
                        {product[HSN_CODE_PROP] || product.type}
                    </Typography>
                </Box>
            </Grid2>
            <Grid2 xs={6} md={1.5}>
                <Typography
                    variant="subtitle2"
                    noWrap
                    sx={{
                        paddingRight: { xs: 2, sm: 0 },
                        textAlign: { xs: 'right', sm: 'center' },
                    }}
                >
                    {product.unit}
                </Typography>
            </Grid2>
        </>
    )
};

export default ProductName;