import React, { useContext } from 'react';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { ADD_PAY_ACCOUNTS, UPDATE_ON_INR_ACCOUNT, UPDATE_ON_PAYMENT_ACCOUNT } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import DataView from '../GenericComponents/DataView/DataView';
import PayAccountCard from './PayAccountCard';

const PaymentAccounts = () => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const queryKey = [
        token,
        khID,
        UPDATE_ON_INR_ACCOUNT,
        UPDATE_ON_PAYMENT_ACCOUNT
    ];

    const queryFunction = async (pageParam) => {
        return await serviceHelpers.getPaymentAccounts(
            token, khID, null, null, pageParam);
    }

    const getSearchableValue = (current) => {
        return (
            current.name
        )
    }

    return (
        <DataView
            buttonDetails={{ text: "Add New", navigateTo: ADD_PAY_ACCOUNTS }}
            routeDetails={{ heading: "Payment Accounts", subText: "Accounts" }}
            limitSupported={true}
            getSearchableValue={getSearchableValue}
            queryKeyParameter={queryKey}
            queryFunctionParameter={queryFunction}
            ShowElement={{ Component: PayAccountCard }}
            searchingPlaceholder={" Search By Name "}
        />
    );
};

export default PaymentAccounts;