import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';

const StatementAmount = ({ txn }) => {
    if (txn.units > 0) {
        return (
            <CallMadeIcon color='error' />
        )
    }

    if (txn.units < 0) {
        return (
            <CallReceivedIcon color='success' />
        )
    }

    return (
        <MultipleStopIcon color='primary' />
    )

};

export default StatementAmount;