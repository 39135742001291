import React, { useContext } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Box, Typography } from '@mui/material';
import { ShowNumber } from '../../../Helpers/helpers';
import { commonFontWeight, DELETE_FIELD, UPDATE_ON_INQUIRY, UPDATE_ON_JV_PURCHASE, UPDATE_ON_VOUCHER } from '../../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../../Helpers/ServiceHelpers';
import { useQueryClient } from '@tanstack/react-query';
import GenericMutateButton from '../../GenericComponents/Buttons/GenericMutateButton';
import { AuthContext } from '../../Auth/Auth';
import { FirmContext } from '../../Contexts/FirmContext';

const TransportInput = ({ document }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const deleteTransport = async () => {
        // Delete INR voucher
        const tranportVID = document.transportVoucher;

        const voucherUpdate = {
            transportConst: DELETE_FIELD,
            transportVoucher: DELETE_FIELD,
        }

        const note = "Transport voucher " + tranportVID + " deleted.";

        await serviceHelpers.deleteChallans(token, khID, tranportVID);
        await serviceHelpers.patchVoucher(token, khID, voucherUpdate, document.id);
        return await serviceHelpers.updateLeadStatus(token, khID, {}, document.inquiryId, note);
    }

    const queryClient = useQueryClient();

    const qkeys = [UPDATE_ON_JV_PURCHASE, UPDATE_ON_VOUCHER, UPDATE_ON_INQUIRY]

    const onSuccess = async () => {
        queryClient.invalidateQueries({
            predicate: (query) => qkeys.some((queryKey) => query.queryKey.includes(queryKey)),
        })

        return true;
    }

    return (
        <Box>
            <Grid2 container rowGap={2}>
                <Grid2 xs={12}>
                    <Typography
                        component={"span"}
                        fontWeight={commonFontWeight}>
                        {
                            "Transport Cost: "
                        }
                    </Typography>

                    <Typography
                        component={"span"}
                        color={"primary"}>
                        {ShowNumber(document.transportConst, 2, true)}
                    </Typography>
                </Grid2>

                <Grid2 xs={12} textAlign={"right"}>
                    <GenericMutateButton
                        buttonText={"Delete"}
                        disable={false}
                        onSuccess={onSuccess}
                        queryFn={deleteTransport}
                        successMessage={"Transport cost deleted."} />
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default TransportInput;