import React, { useContext } from 'react';
import { ACCOUNT_PAYABLE, PAYMENT_VOUCHER, PAYMENTS, TOTAL_OTHER_PAYABLE_ID, UPDATE_ON_PAYMENTS } from '../../Helpers/ConstantProperties';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import NewPaymentForm from './NewPaymentForm';
import { createVoucherObject } from './VoucherHelper';

const NewPayment = () => {
    const { token } = useContext(AuthContext);
    const { khID, currentFirm } = useContext(FirmContext);

    const queryFn = async ({ cData }) => {

        return createVoucherObject({
            cData: cData,
            title: "Paid",
            type: PAYMENT_VOUCHER,
            collAccounts: [ACCOUNT_PAYABLE, TOTAL_OTHER_PAYABLE_ID],
            token: token,
            khID: khID,
            prefix: currentFirm.prefixes.payment
        })
    }

    return (
        <NewPaymentForm
            queryFn={queryFn}
            title="Payment"
            updateKey={UPDATE_ON_PAYMENTS}
            nevigateRoute={PAYMENTS} />
    )
};

export default NewPayment;