import React from 'react';
import { PURCHASE_VOUCHER, PURCHASES } from '../../Helpers/ConstantProperties';
import PurchaseCardBody from './PurchaseCardBody';

const PurchaseCard = ({ item }) => {
    return <PurchaseCardBody
        showUnits={true}
        item={item}
        navTo={PURCHASES}
        qKey={PURCHASE_VOUCHER} />
};

export default PurchaseCard;