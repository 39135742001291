import { Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { commonFontSize, commonFontWeight } from '../../Helpers/ConstantProperties';
import { CGST_HSN_TAX_HEADER, IGST_HSN_TAX_HEADER, ShowNumber } from '../../Helpers/helpers';

const rightBorder = "1px solid gray";

const PurchaseTax = ({ cData }) => {
    if (Object.keys(cData.hsnWiseList).length === 0) return <></>;

    const headerList = cData.igstApp ? IGST_HSN_TAX_HEADER : CGST_HSN_TAX_HEADER;

    return (
        <Grid2 container paddingTop={2} textAlign={"center"} fontSize={commonFontSize}>
            <Grid2 xs={12}>
                <Box fontWeight={commonFontWeight} className="d-none d-sm-block">
                    <Grid2 container paddingY={1} className="bg-primary-subtle rounded">
                        {
                            headerList.map((head, index) => (
                                <Grid2 key={index} md={head.columnSize}>
                                    {head.content}
                                </Grid2>
                            ))
                        }

                    </Grid2>
                </Box>
            </Grid2>

            {
                Object.keys(cData.hsnWiseList).map((key, index) => {
                    const { taxable, hsncode, GSTRate, quantity, tax } = cData.hsnWiseList[key];
                    let count = 0;

                    return (
                        <Grid2 xs={12} key={index} borderBottom={rightBorder}>
                            <Box paddingY={1}>
                                <Grid2 container>
                                    <Grid2
                                        borderRight={rightBorder}
                                        md={headerList[count++].columnSize}>
                                        {hsncode}
                                    </Grid2>

                                    <Grid2
                                        borderRight={rightBorder}
                                        md={headerList[count++].columnSize}>
                                        {GSTRate} %
                                    </Grid2>

                                    <Grid2
                                        borderRight={rightBorder}
                                        md={headerList[count++].columnSize}>
                                        {ShowNumber(quantity, 2)}
                                    </Grid2>

                                    <Grid2
                                        borderRight={rightBorder}
                                        md={headerList[count++].columnSize}
                                        textAlign={"right"}
                                        paddingRight={1}>
                                        {ShowNumber(taxable, 2, true)}
                                    </Grid2>

                                    {
                                        cData.igstApp &&
                                        <Grid2
                                            borderRight={rightBorder}
                                            md={headerList[count++].columnSize}
                                            textAlign={"right"}
                                            paddingRight={1}>
                                            {ShowNumber(tax, 2, true)}
                                        </Grid2>
                                    }

                                    {
                                        !cData.igstApp &&
                                        <>
                                            <Grid2
                                                borderRight={rightBorder}
                                                md={headerList[count++].columnSize}
                                                textAlign={"right"}
                                                paddingRight={1}>
                                                {ShowNumber(tax / 2, 2, true)}
                                            </Grid2>
                                            <Grid2
                                                borderRight={rightBorder}
                                                md={headerList[count++].columnSize}
                                                textAlign={"right"}
                                                paddingRight={1}>
                                                {ShowNumber(tax / 2, 2, true)}
                                            </Grid2>
                                        </>
                                    }

                                    <Grid2
                                        borderRight={rightBorder}
                                        md={headerList[count++].columnSize}
                                        textAlign={"right"}
                                        paddingRight={1}>
                                        {ShowNumber(tax, 2, true)}
                                    </Grid2>
                                </Grid2>
                            </Box>
                        </Grid2>
                    )
                })
            }

            <Grid2 xs={12}>
                <Box fontWeight={commonFontWeight} className="d-none d-sm-block">
                    <Grid2 container paddingY={1} className="bg-secondary-subtle rounded">
                        <Grid2 md={3} borderRight={rightBorder}>
                            Total
                        </Grid2>

                        <Grid2 md={1} borderRight={rightBorder}>
                            {ShowNumber(cData.totalQuantity, 2)}
                        </Grid2>

                        <Grid2
                            borderRight={rightBorder}
                            md={2.5}
                            textAlign={"right"}
                            paddingRight={1}>
                            {ShowNumber(cData.totalTaxableAmount, 2, true)}
                        </Grid2>

                        <Grid2
                            borderRight={rightBorder}
                            md={5.5}
                            textAlign={"right"}
                            paddingRight={1}>
                            {ShowNumber(cData.totalTax, 2, true)}
                        </Grid2>
                    </Grid2>
                </Box>
            </Grid2>
        </Grid2>
    );
};

export default PurchaseTax;