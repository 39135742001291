import { Box, Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useContext, useState } from 'react';
import GenericFormHeader from '../GenericComponents/FormComponent/GenericFormHeader';
import dayjs from 'dayjs';
import AddProperty from '../AddProperties/AddProperty';
import { INPUT_TYPE_NUM, propertyList, SchemaTypes } from '../../Helpers/ExtraProperties';
import useArray from '../GenericComponents/Array/GenericArrayHandler';

import { deepCopyObject, getUpdatedProductSchema } from '../../Helpers/helpers';
import { getRateDetails } from '../Quotation/RateCalculator';
import { FirmContext } from '../Contexts/FirmContext';
import PurchaseInvoice from './PurchaseInvoice';
import GenericMutateButton from '../GenericComponents/Buttons/GenericMutateButton';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { INWORD_CHALLAN, NOT_APPLICABLE, OUTWORD_CHALLAN, TAX_INVOICE } from '../../Helpers/ConstantProperties';
import { DateContext } from '../Contexts/DateContext';

const NewPurchaseForm = ({
    title,
    itemList,
    queryFn,
    navRoute = -1,
    predicate,
    refranceId = null,
    expenses = null,
    desc = "" }) => {
    const { currentFirm } = useContext(FirmContext);
    const { currentDate } = useContext(DateContext);

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const disableDate = title === TAX_INVOICE;
    const getRateFromMaster = title === TAX_INVOICE;

    const [selectedDate, setSelectedDate] = useState(dayjs(currentDate));
    const [refID, setRefID] = useState(refranceId ? refranceId : "");
    const [party, setParty] = useState(null);

    const [product, setProduct] = useState(expenses);
    const [saleRate, setSaleRate] = useState(0);
    const [qty, setQty] = useState(1);
    const [description, setDescription] = useState(desc);
    const [gstRate, setGstRate] = useState(undefined);

    const skipCurrency = [INWORD_CHALLAN, OUTWORD_CHALLAN].includes(title);

    const gstDataAdded = party?.gstin
        ? (gstRate !== undefined)
        : true;

    const canAdd =
        refID !== "" &&
        party !== null &&
        product !== null &&
        (skipCurrency || saleRate > 0) &&
        (skipCurrency || gstDataAdded) &&
        qty > 0;

    var cData = {};

    const enableGstAndHSN = (party?.gstin) && (product?.GSTRate === undefined);

    const gstORrcm = (product && product.rcmEnabled) ? "RCM Rate" : "GST Rate";

    const { data, addEntry, removeEntry, clearData } = useArray({ initState: [] });

    const onSuccess = async () => {
        await queryClient.invalidateQueries({
            predicate: predicate
        });

        navigate(navRoute)
        setParty(null);
        setRefID("");
        clearData()
    }

    const addProductToArray = () => {
        const entry = {
            product: deepCopyObject(product),
            saleRate: saleRate,
            units: qty,
            productdescription: description
        }

        if (!skipCurrency && enableGstAndHSN) {
            entry.product.GSTRate = parseFloat(gstRate);
            entry.product.productHSNcode = NOT_APPLICABLE;
        }

        getUpdatedProductSchema(entry);

        addEntry(entry);

        setProductObject(null);
        setSaleRate(0);
        setQty(1);
        setDescription("");
    }

    const setProductObject = (value) => {
        if (getRateFromMaster) {
            setSaleRate(value ? value.saleRate : undefined)

        }
        setGstRate(value ? value.GSTRate : undefined);
        setProduct(value)
    }

    if (party && data.length > 0) {

        let forceGST = false;

        const inquiryObject = {
            city: party.city,
            gstin: party.gstin,
            panNumber: party.panNumber,
            contactPerson: party.contactPerson,

            products: data
        }

        if (title === TAX_INVOICE) {
            forceGST = true;
            inquiryObject.customerName = party.name
        }
        else {
            inquiryObject.vendorName = party.name
        }

        cData = getRateDetails({
            inquiry: inquiryObject,
            forceGST: forceGST,
            currentFirm: currentFirm,
        });

        cData.documentDate = selectedDate;
        cData.refID = refID;
        cData.skipCurrency = skipCurrency;
    }

    const voucherQueryFn = async () => await queryFn({
        data: data,
        selectedDate: selectedDate,
        party: party,
        refID: refID,
        cData: cData
    })

    return (
        <>
            <GenericFormHeader title={"Add " + title} enableBack={true} />
            <Grid2 container p={2} rowGap={2}>
                <Grid2 xs={12} md={3}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: title + ' Date',
                                name: 'documentDate',
                                required: true,
                                type: SchemaTypes.DATEPICKER,
                                helperText: 'Please Select the Date',
                            },
                            extraProps: {
                                disabled: disableDate,
                                disableFuture: true
                            }
                        }}
                        deleteField={(element) => { setSelectedDate(dayjs(currentDate)) }}
                        currentValue={selectedDate}
                        onChange={(e) => setSelectedDate(e.value)}
                    />
                </Grid2>

                <Grid2 xs={12} md={6}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: title + ' Party',
                                name: 'documentParty',
                                required: true,
                                type: SchemaTypes.PARTYDROPDOWN,
                                helperText: 'Please Select the Party',
                            }
                        }}
                        deleteField={(element) => { setParty(null) }}
                        currentValue={party}
                        onChange={(e) => setParty(e.value)}
                    />
                </Grid2>

                <Grid2 xs={12} md={3}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: "Referance ID",
                                name: "refID",
                                type: SchemaTypes.String,
                                required: true,
                                disabled: refranceId !== null,
                                helperText: 'Please enter the Referance ID',
                            },
                        }}
                        deleteField={(element) => { setRefID("") }}
                        currentValue={refID}
                        onChange={(e) => setRefID(e.value)}
                    />
                </Grid2>

                <Grid2 xs={12} paddingY={1}>
                    <Box className="bg-secondary-subtle">
                        <Grid2 container p={1} rowGap={2}>

                            <Grid2 xs={12}>
                                <Box>
                                    <Grid2 container>

                                        <Grid2 xs={12} md={4}>
                                            <AddProperty
                                                data={{
                                                    item: itemList,
                                                }}
                                                deleteField={(element) => { setProductObject(null) }}
                                                currentValue={product}
                                                onChange={(e) => setProductObject(e.value)}
                                            />
                                        </Grid2>

                                        {
                                            !skipCurrency &&
                                            <Grid2 xs={12} md={1.5}>
                                                <AddProperty
                                                    data={{
                                                        item: {
                                                            displayName: "Rate",
                                                            name: "amount",
                                                            type: SchemaTypes.Number,
                                                            required: true,
                                                            disabled: product === null,
                                                            helperText: 'Please enter the Amount',
                                                        },
                                                        inputMode: INPUT_TYPE_NUM
                                                    }}
                                                    deleteField={(element) => { setSaleRate(0) }}
                                                    currentValue={saleRate}
                                                    onChange={(e) => setSaleRate(e.value)}
                                                />
                                            </Grid2>
                                        }

                                        {
                                            !skipCurrency &&
                                            <Grid2 xs={12} md={1.5}>
                                                <AddProperty
                                                    data={{
                                                        item: {
                                                            ...propertyList.ProductgstRate,
                                                            displayName: gstORrcm,
                                                            disabled: !enableGstAndHSN,
                                                        }
                                                    }}
                                                    deleteField={(element) => { setGstRate(null) }}
                                                    currentValue={gstRate}
                                                    onChange={(e) => setGstRate(e.value)}
                                                />
                                            </Grid2>
                                        }

                                        <Grid2 xs={12} md={1.5}>
                                            <AddProperty
                                                data={{
                                                    item: {
                                                        displayName: 'Quantity',
                                                        name: 'units',
                                                        required: true,
                                                        type: SchemaTypes.QUANTITY,
                                                        helperText: 'Quantity needed',
                                                        disabled: product === null
                                                    }
                                                }}
                                                deleteField={(element) => { setQty(1) }}
                                                currentValue={qty}
                                                onChange={(e) => setQty(e.value)}
                                            />
                                        </Grid2>

                                        <Grid2 xs={12} md={skipCurrency ? 6.5 : 3.5}>
                                            <AddProperty
                                                data={{
                                                    item: {
                                                        ...propertyList.description,
                                                        disabled: product === null,
                                                    }
                                                }}
                                                deleteField={(element) => { setDescription(1) }}
                                                currentValue={description}
                                                onChange={(e) => setDescription(e.value)}
                                            />
                                        </Grid2>
                                    </Grid2>
                                </Box>
                            </Grid2>

                            <Grid2 xs={12} textAlign={"center"}>
                                <Button
                                    onClick={addProductToArray}
                                    disabled={!canAdd}
                                    variant="contained"
                                    color="success">
                                    {"Add To List"}
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Box>
                </Grid2>

                <Grid2 xs={12}>
                    <PurchaseInvoice cData={cData} removeEntry={removeEntry} />
                </Grid2>

                <Grid2 xs={12} textAlign={"center"} paddingY={2}>
                    <GenericMutateButton
                        queryFn={voucherQueryFn}
                        disable={!cData.skipCurrency && !cData.roundedTotal}
                        onSuccess={onSuccess}
                        successMessage="voucher Created" />
                </Grid2>
            </Grid2>
        </>
    );
};

export default NewPurchaseForm;