import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { Label } from './PurchaseVendor';
import { getLocalDateString } from '../../Helpers/helpers';

const PurchaseDetails = ({ cData }) => {
    return (
        <Grid2 container>
            <Grid2 xs={12} padding={0.5}>
                <Typography variant='body2' >{"Details"}</Typography>
            </Grid2>

            <Grid2 xs={12}>
                <Label label="Document ID:" value={cData.refID} />
            </Grid2>

            <Grid2 xs={12}>
                <Label label="Date:" value={getLocalDateString(cData.documentDate)} />
            </Grid2>

        </Grid2>
    )
};

export default PurchaseDetails;