import { MenuItem } from '@mui/material';
import React from 'react';
import InspectionParams from './InspectionParams';

const ChangeInspection = ({ item, showDialog }) => {
    const onMenuClick = () => {
        showDialog({
            contents: <InspectionParams item={item} />,
            title: "Change Inspection Parameters",
            maxWidth: "md"
        });
    }


    return (
        <>
            <MenuItem onClick={() => onMenuClick()}>
                {"Change Inspection"}
            </MenuItem>
        </>
    );
};

export default ChangeInspection;