import Grid2 from '@mui/material/Unstable_Grid2';
import { ACCOUNT_RECEIVABLE } from '../../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../../Helpers/ServiceHelpers';
import BalanceTotal from './BalanceTotal';
import { useContext } from 'react';
import { AuthContext } from '../../Auth/Auth';
import { FirmContext } from '../../Contexts/FirmContext';
import AccountChartAndList from './AccountChartAndList';

const TotalReceivable = ({ transform }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    return (
        <Grid2 container rowGap={2}>

            <Grid2 xs={12} className="text-danger">
                <BalanceTotal
                    transform={transform}
                    header={"Receivable"}
                    account={ACCOUNT_RECEIVABLE} />
            </Grid2>

            <Grid2 xs={12} >
                <AccountChartAndList
                    transform={transform}
                    account={ACCOUNT_RECEIVABLE}
                    query={
                        async () =>
                            await serviceHelpers.getReceivableAccounts(token, khID)
                    }
                />
            </Grid2>

        </Grid2>
    );
};

export default TotalReceivable;