import { Alert, Box, IconButton } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { getLocalDateString } from '../../Helpers/helpers';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const ChallanList = ({ data, list, addEntry, removeEntry }) => {
    if (!data) {
        return (
            <Alert color='warning'>
                Please select the customer
            </Alert>
        )
    }

    if (data.length === 0) {
        return (
            <Alert color='warning'>
                No data to display
            </Alert>
        )
    }

    return (
        <Grid2 container>
            {
                data.map((challan) => {
                    const isAdded = list.find((entry) => entry.id === challan.id);
                    const filterFn = (item) => item.id === challan.id

                    return (
                        <Grid2 key={challan.id} xs={12}>
                            <Box>
                                <Grid2 container
                                    textAlign={"center"}
                                    paddingY={1}
                                    alignItems={"center"}>

                                    <Grid2 xs={4}>
                                        {challan.refranceId}
                                    </Grid2>

                                    <Grid2 xs={4}>
                                        {getLocalDateString(challan.date)}
                                    </Grid2>

                                    {
                                        isAdded &&
                                        <Grid2 xs={4}>
                                            <IconButton onClick={() => removeEntry(filterFn)}>
                                                <DeleteIcon fontSize='small' color='error' />
                                            </IconButton>
                                        </Grid2>
                                    }

                                    {
                                        !isAdded &&
                                        <Grid2 xs={4}>
                                            <IconButton onClick={() => addEntry(challan)}>
                                                <AddIcon fontSize='small' color='success' />
                                            </IconButton>
                                        </Grid2>
                                    }

                                </Grid2>
                            </Box>
                        </Grid2>
                    )
                })
            }
        </Grid2>
    )
};

export default ChallanList;