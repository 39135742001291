import { useContext } from "react";
import { BALANCE, DEMI_INR_RESOURCE_ID, INQUIRY_STORE_ACCOUNT_ID, INR_RESOURCE_ID, PURCHASE_BATCH_STORE_SUFFIX, PURCHASE_VOUCHER, TOTAL_PURCHASE_ID, UPDATE_ON_INQUIRY } from "../../Helpers/ConstantProperties";
import { propertyList } from "../../Helpers/ExtraProperties";
import NewPurchaseForm from "./NewPurchseForm";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { getPartyINRBatchObject, inwordIdWithDate, ShowNumber } from "../../Helpers/helpers";
import { getPurchaseINRTransaction } from "../Quotation/RateCalculator";
import { useLocation } from "react-router-dom";

const NewPurchase = () => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const location = useLocation();
    const inquiry = location.state?.inquiry;

    const queryFn = async ({
        data,
        selectedDate,
        party,
        refID,
        cData
    }) => {

        const batches = []
        const transaction = []
        let description = "Invoice for: \r\n";

        data.forEach((entry, index) => {

            batches.push({
                id: inwordIdWithDate(index, new Date(selectedDate)),
                name: party.name,
                rate: entry.saleRate,
                entityPublicAccess: true
            })

            transaction.push({
                accountID: party.id,
                resourceID: entry.product.id,
                units: entry.units * -1,
                rate: entry.saleRate,
                batches: [{ id: refID, units: entry.units * -1 }]
            })

            transaction.push({
                accountID: inquiry?.id || INQUIRY_STORE_ACCOUNT_ID,
                resourceID: entry.product.id,
                units: entry.units,
                batches: [{ index: index, units: entry.units }]
            })

            description += entry.product.name + ": " + entry.units + " \r\n";
        })

        const storeTxn = {
            units: 0,
            batches: [],
            accountID: INQUIRY_STORE_ACCOUNT_ID,
            resourceID: INR_RESOURCE_ID,
        }

        cData.items.forEach((product) => {
            const suffix = PURCHASE_BATCH_STORE_SUFFIX;

            storeTxn.units += product.taxableAmount;
            storeTxn.batches.push({
                id: suffix + product.id,
                units: product.taxableAmount
            })
        })

        transaction.push(storeTxn)

        const inrBatch = getPartyINRBatchObject(
            PURCHASE_VOUCHER,
            selectedDate,
            refID
        )

        batches.push(inrBatch)

        const inrTransactions = getPurchaseINRTransaction(
            party,
            cData,
            (batches.length - 1),
            TOTAL_PURCHASE_ID
        )

        transaction.push(...inrTransactions);

        if (inquiry) {
            transaction.push({
                resourceID: DEMI_INR_RESOURCE_ID,
                accountID: party.id,
                batches: [{ id: inquiry.id, units: -cData.totalTaxableAmount }],
                units: -cData.totalTaxableAmount,
            })

            transaction.push({
                resourceID: DEMI_INR_RESOURCE_ID,
                accountID: inquiry.id,
                batches: [{ id: party.id, units: cData.totalTaxableAmount }],
                units: cData.totalTaxableAmount,
                name: party.name,
                description: description
            })
        }

        const voucher = {
            vendorName: party.name,
            vendorId: party.id,
            city: party.city,
            cData: cData,
            date: selectedDate,
            verified: true,
            refranceId: refID,
            type: PURCHASE_VOUCHER,
            batches: batches,
            transactions: transaction,
            inrBatch: inrBatch.id
        }

        if (inquiry) {
            voucher.inquiryId = inquiry.id
        }

        const result = await serviceHelpers.createVoucherAndAdjustAdvance(
            token, khID, voucher, party.id);

        if (inquiry) {
            const note = "Invoice cost of "
                + ShowNumber(cData.totalTaxableAmount, 2, true)
                + " for "
                + party.name
                + " added. Invoice ID: " + refID

            await serviceHelpers.updateLeadStatus(token, khID, {}, inquiry.id, note);
        }

        return result;
    }

    const qkeys = [PURCHASE_VOUCHER, (BALANCE + INQUIRY_STORE_ACCOUNT_ID)]

    if (inquiry) {
        qkeys.push(UPDATE_ON_INQUIRY)
    }

    const predicate = (query) =>
        qkeys.some((queryKey) => query.queryKey.includes(queryKey))

    return <NewPurchaseForm
        title={PURCHASE_VOUCHER}
        itemList={propertyList.purchaseProductList}
        queryFn={queryFn}
        predicate={predicate} />
};

export default NewPurchase;