import { Alert, Paper } from "@mui/material";
import { getRateDetails } from "../Quotation/RateCalculator";
import { useContext } from "react";
import { FirmContext } from "../Contexts/FirmContext";
import PurchaseInvoice from "../Purchase/PurchaseInvoice";
import { DateContext } from "../Contexts/DateContext";
import Grid2 from "@mui/material/Unstable_Grid2";
import GenericMutateButton from "../GenericComponents/Buttons/GenericMutateButton";
import { createTaxInvoiceVoucher } from "./VoucherHelper";
import { AuthContext } from "../Auth/Auth";
import { useNavigate } from "react-router-dom";
import { TAX_INVOICE, UPDATE_ON_INR_ACCOUNT } from "../../Helpers/ConstantProperties";
import { useQueryClient } from "@tanstack/react-query";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { addDaysToToday } from "../../Helpers/helpers";

const ChallanItems = ({ challans, party, setParty, clearData }) => {
    const { token } = useContext(AuthContext);
    const { currentFirm, khID } = useContext(FirmContext);
    const { currentDate } = useContext(DateContext);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    if (!party || challans.length === 0) return (
        <Alert color="warning">No Challans selected.</Alert>
    )

    const inquiryObject = {
        city: party.city,
        gstin: party.gstin,
        panNumber: party.panNumber,
        contactPerson: party.contactPerson,
        customerName: party.name,
    }

    const productList = {}
    let challanList = "";
    const inquiryList = {};

    challans.forEach(challan => {

        if (challanList !== "") challanList += ", "
        challanList += challan.refranceId;

        inquiryList[challan.inquiryId] = true;

        challan.cData.items.forEach(item => {
            if (productList[item.id]) {

                productList[item.id].units += item.units;
                productList[item.id].totalTaxableAmount += (item.taxableAmount || 0);

                productList[item.id].saleRate =
                    productList[item.id].totalTaxableAmount / productList[item.id].units

                productList[item.id].productdescription += ", " + challan.refranceId
            }
            else {
                productList[item.id] = {
                    product: { ...item },
                    saleRate: item.saleRate,
                    totalTaxableAmount: item.taxableAmount,
                    units: item.units,
                    productdescription: "From Challans: " + challan.refranceId
                }
            }
        })
    });

    inquiryObject.products = Object.values(productList);

    const cData = getRateDetails({
        inquiry: inquiryObject,
        forceGST: true,
        currentFirm: currentFirm,
    });

    cData.documentDate = currentDate;
    cData.refID = "AUTO";

    const voucherQueryFn = async () => {
        cData.challans = challanList;

        const extraProps = {}

        if (Object.keys(inquiryList).length === 1) {
            extraProps.inquiryId = Object.keys(inquiryList)[0]
        }

        const result = await createTaxInvoiceVoucher({
            token: token,
            khID: khID,
            prefix: currentFirm.prefixes.invoice,
            data: [],
            party: party,
            cData: cData,
            currentDate: currentDate,
            extraProps: extraProps
        })

        const voucherUpdate = {
            invoiceID: result.refId,
            invoiceDate: currentDate.getTime(),
        }

        await Promise.all(
            challans.map(async (challan) => {
                await serviceHelpers.patchVoucher(token, khID, voucherUpdate, challan.id)
            })
        )

        await Promise.all(
            Object.keys(inquiryList).map(async (inquiryId) => {

                const update = {
                    followUpDate: addDaysToToday(2, currentDate),
                }

                const notes = "Invoice " + result.refId + " generated.";

                await serviceHelpers.updateLeadStatus(
                    token, khID, update, inquiryId, notes, currentDate);
            })
        )

        return result.id
    }

    const qkeys = [TAX_INVOICE, UPDATE_ON_INR_ACCOUNT];

    const predicate = (query) =>
        qkeys.some((queryKey) => query.queryKey.includes(queryKey))

    const onSuccess = async () => {
        await queryClient.invalidateQueries({
            predicate: predicate
        });

        navigate(-1)
        setParty(null);
        clearData();
    }

    return (
        <Paper elevation={2}>
            <Grid2 container>
                <Grid2 xs={12}>
                    <PurchaseInvoice cData={cData} />
                </Grid2>

                <Grid2 xs={12} textAlign={"center"} paddingY={2}>
                    <GenericMutateButton
                        queryFn={voucherQueryFn}
                        disable={!cData.roundedTotal}
                        onSuccess={onSuccess}
                        successMessage="voucher Created" />
                </Grid2>
            </Grid2>

        </Paper>
    )
};

export default ChallanItems;