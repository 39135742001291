import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { ShowNumber } from '../../Helpers/helpers';
import { Typography } from '@mui/material';
import { largeFontSize } from '../../Helpers/ConstantProperties';

const GridRow = ({ label, value }) => {
    return (
        <>
            <Grid2 xs={6}>
                {label}
            </Grid2>
            <Grid2 xs={6}>
                {value}
            </Grid2>
        </>
    )
}

const PurchaseTotal = ({ cData }) => {
    return (
        <>
            <Grid2 container textAlign={"right"} paddingRight={1}>

                <GridRow
                    label="Total Taxable:"
                    value={ShowNumber(cData.totalTaxableAmount, 2, true)} />

                <GridRow
                    label="Total Tax:"
                    value={ShowNumber(cData.totalTax, 2, true)} />

                {
                    cData.totalRCMTax > 0 &&
                    <GridRow
                        label="RCM Payble:"
                        value={ShowNumber(-cData.totalRCMTax, 2, true)} />
                }

                <GridRow label="Round-Off:" value={ShowNumber(cData.roundOff, 2, true)} />

            </Grid2>

            <Typography component={"span"} fontSize={largeFontSize}>
                <Grid2
                    marginLeft={1}
                    container
                    textAlign={"right"}
                    paddingRight={1}
                    paddingY={1}
                    className="bg-info-subtle rounded">
                    <GridRow label="Grand Total:" value={ShowNumber(cData.roundedTotal, 2, true)} />
                </Grid2>

            </Typography>
        </>
    );
};

export default PurchaseTotal;