import { useContext } from "react";
import { COMMON_BATCH, DEMI_INR_RESOURCE_ID, INR_RESOURCE_ID, JOURNAL_VOUCHER, TOTAL_EXPENSES_ID, TRANSPORT_ACCOUNT_ID, UPDATE_ON_INQUIRY, UPDATE_ON_JV_PURCHASE, UPDATE_ON_VOUCHER } from "../../Helpers/ConstantProperties";
import { propertyList } from "../../Helpers/ExtraProperties";
import NewPurchaseForm from "./NewPurchseForm";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { getPurchaseINRTransaction } from "../Quotation/RateCalculator";
import { useLocation } from "react-router-dom";
import { getPartyINRBatchObject, ShowNumber } from "../../Helpers/helpers";

const NewExpense = () => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const location = useLocation();
    const document = location.state?.document;
    const expenses = location.state?.expenses;
    const description = location.state?.description;

    const queryFn = async ({
        selectedDate,
        party,
        refID,
        cData
    }) => {
        const batches = []
        const transaction = []

        cData.items.forEach((product) => {

            transaction.push({
                jvPurchase: true,
                accountID: product.id,
                resourceID: INR_RESOURCE_ID,
                units: product.taxableAmount,
                batches: [{ id: COMMON_BATCH, units: product.taxableAmount }]
            })
        })

        const inrBatch = getPartyINRBatchObject(
            JOURNAL_VOUCHER,
            selectedDate,
            refID
        )

        batches.push(inrBatch)

        const inrTransactions = getPurchaseINRTransaction(
            party,
            cData,
            (batches.length - 1),
            TOTAL_EXPENSES_ID,
            { jvPurchase: true }
        )

        transaction.push(...inrTransactions);

        if (document?.inquiryId) {
            transaction.push({
                resourceID: DEMI_INR_RESOURCE_ID,
                accountID: expenses.id,
                batches: [{ id: COMMON_BATCH, units: -cData.totalTaxableAmount }],
                units: -cData.totalTaxableAmount,
                description: description
            })

            transaction.push({
                resourceID: DEMI_INR_RESOURCE_ID,
                accountID: document.inquiryId,
                batches: [{ id: COMMON_BATCH, units: cData.totalTaxableAmount }],
                units: cData.totalTaxableAmount,
                description: description
            })
        }

        const voucher = {
            vendorName: party.name,
            jvPurchase: true,
            vendorId: party.id,
            city: party.city,
            cData: cData,
            date: selectedDate,
            verified: true,
            refranceId: refID,
            type: JOURNAL_VOUCHER,
            batches: batches,
            transactions: transaction,
            inrBatch: inrBatch.id
        }

        const result = await serviceHelpers.createVoucherAndAdjustAdvance(
            token, khID, voucher, party.id);

        if (document?.inquiryId && expenses.id === TRANSPORT_ACCOUNT_ID) {

            const note = "Transport cost of "
                + ShowNumber(cData.totalTaxableAmount, 2, true)
                + " for "
                + (document.refranceId || document.id)
                + " added. Voucher ID: " + result.id;

            const voucherUpdate = {
                transportConst: cData.totalTaxableAmount,
                transportVoucher: result.id,
            }

            await serviceHelpers.patchVoucher(token, khID, voucherUpdate, document.id);
            await serviceHelpers.updateLeadStatus(token, khID, {}, document.inquiryId, note);
        }

        return result;
    }

    const qkeys = [UPDATE_ON_JV_PURCHASE]

    if (document) {
        qkeys.push(UPDATE_ON_VOUCHER)
        qkeys.push(UPDATE_ON_INQUIRY)
    }

    const predicate = (query) =>
        qkeys.some((queryKey) => query.queryKey.includes(queryKey))

    return <NewPurchaseForm
        title={"Expenses"}
        itemList={propertyList.purchaseExpList}
        queryFn={queryFn}
        predicate={predicate}
        expenses={expenses}
        desc={description} />
};

export default NewExpense;