import React, { useContext } from 'react';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { ADDINRACCOUNT, UPDATE_ON_CUSTOMER, UPDATE_ON_EXPE_ACCOUNT, UPDATE_ON_INR_ACCOUNT, UPDATE_ON_LABOUR, UPDATE_ON_PARTY, UPDATE_ON_PAYMENT_ACCOUNT, UPDATE_ON_VENDOR } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import DataView from '../GenericComponents/DataView/DataView';
import DataviewList from '../GenericComponents/DataView/DataviewList';
import ExpenseAccCard from './ExpenseAccCard';

const ChartOfAccounts = () => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const queryKey = [
        token,
        khID,
        UPDATE_ON_INR_ACCOUNT,
        UPDATE_ON_PAYMENT_ACCOUNT,
        UPDATE_ON_EXPE_ACCOUNT,
        UPDATE_ON_LABOUR,
        UPDATE_ON_PARTY,
        UPDATE_ON_CUSTOMER,
        UPDATE_ON_VENDOR,
    ];

    const queryFunction = async (pageParam) => {
        return await serviceHelpers.getAllAccounts(token, khID, pageParam);
    }

    const getSearchableValue = (current) => {
        return (
            current.name + " " + current.tag
        )
    }

    return (
        <DataView
            buttonDetails={{ text: "Add New", navigateTo: ADDINRACCOUNT }}
            routeDetails={{ heading: "Chart of Accounts" }}
            limitSupported={true}
            getSearchableValue={getSearchableValue}
            queryKeyParameter={queryKey}
            DisplayComponent={DataviewList}
            queryFunctionParameter={queryFunction}
            ShowElement={{ Component: ExpenseAccCard }}
            searchingPlaceholder={" Search By Name and tag "}
        />
    );
};

export default ChartOfAccounts;