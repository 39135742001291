import FormatLogo from "./FormatLogo";
import DocumentHeading from "./DocumentHeading";
import GenericDivider from "../../GenericComponents/Layout/GenericDivide";
import FromComponent from "./FromComponent";
import ToComponent from "./ToComponent";
import DetailsComponent from "./DetailsComponent";
import TotalComponent from "./TotalComponent";
import TotalInWords from "./TotalInWords";
import BankComponent from "./BankComponent";
import TermsComponent from "./TermsComponent";
import ReceiverSignature from "./ReceiverSignature";
import SignatureComp from "./SignatureComp";
import BOMItems from "./BOMItems";
import StmtBankComponent from "./StmtBankComponent";
import EndOfStatement from "./EndOfStatement";
import StmtTransactions from "./StmtTransactions";
import StmtClosingBalance from "./StmtClosingBalance";
import { LAST_PART, MID_PART, TOP_PART } from "../../../Helpers/ConstantProperties";
import ChallanItemsFormat from "./ChallanItemsFormat";

export const defaultFormatLayout = [
    {
        section: TOP_PART,
        rows: [
            {
                label: "Logo",
                layout: {
                    xs: 2,
                },
            },
            {
                label: "Heading",
                layout: {
                    xs: 10,
                },
            },
        ]
    },
    {
        section: TOP_PART,
        rows: [
            {
                label: "Divider",
                style: {
                    paddingVertical: "3mm",
                }
            }
        ]
    },
    {
        section: TOP_PART,
        rows: [
            {
                label: "From",
            },
            {
                label: "To",
            },
            {
                label: "Details",
            },
        ]
    },
    {
        section: MID_PART,
        rows: [
            {
                label: "Items",
                style: {
                    paddingVertical: "3mm",
                }
            },
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                label: "Tax",
                layout: {
                    xs: 8,
                },
            },
            {
                label: "Total",
                layout: {
                    xs: 4,
                },
                style: {
                    paddingLeft: "3mm",
                }
            },
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                label: "Words",
                style: {
                    paddingVertical: "3mm",
                }
            },
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                label: "Bank",
                layout: {
                    xs: 4,
                },
                style: {
                    fontSize: 7,
                }
            },
            {
                label: "Terms",
                layout: {
                    xs: 3,
                },
            },
            {
                label: "Receiver",
                layout: {
                    xs: 2.5,
                },
                style: {
                    fontSize: 7,
                    textAlign: "center",
                },
            },
            {
                label: "Sign",
                layout: {
                    xs: 2.5,
                },
                style: {
                    fontSize: 7,
                    textAlign: "center",
                },
            },
        ]
    },
];

export const poFormatLayout = [
    {
        section: TOP_PART,
        rows: [
            {
                label: "Logo",
                layout: {
                    xs: 2,
                },
            },
            {
                label: "Heading",
                layout: {
                    xs: 10,
                },
            },
        ]
    },
    {
        section: TOP_PART,
        rows: [
            {
                label: "Divider",
                style: {
                    paddingVertical: "3mm",
                }
            }
        ]
    },
    {
        section: TOP_PART,
        rows: [
            {
                label: "From",
            },
            {
                label: "To",
            },
            {
                label: "Details",
            },
        ]
    },
    {
        section: MID_PART,
        rows: [
            {
                label: "Items",
                style: {
                    paddingVertical: "3mm",
                }
            },
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                label: "Tax",
                layout: {
                    xs: 8,
                },
            },
            {
                label: "Total",
                layout: {
                    xs: 4,
                },
                style: {
                    paddingLeft: "3mm",
                }
            },
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                label: "Words",
                style: {
                    paddingVertical: "3mm",
                }
            },
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                label: "Terms",
                layout: {
                    xs: 5,
                },
            },
            {
                label: "Receiver",
                layout: {
                    xs: 3.5,
                },
                style: {
                    fontSize: 7,
                    textAlign: "center",
                },
            },
            {
                label: "Sign",
                layout: {
                    xs: 3.5,
                },
                style: {
                    fontSize: 7,
                    textAlign: "center",
                },
            },
        ]
    },
];

export const fomatComponents = (label, currentFirm, context, page) => {
    let componentToReturn = null;

    switch (label) {
        case "Logo":
            componentToReturn = <FormatLogo currentFirm={currentFirm} />;
            break;

        case "Heading":
            componentToReturn = <DocumentHeading context={context} />;
            break;

        case "EndDivider":
        case "Divider":
            componentToReturn = <GenericDivider />
            break;

        case "From":
            componentToReturn = <FromComponent currentFirm={currentFirm} />;
            break;

        case "To":
            componentToReturn = <ToComponent context={context} />;
            break;

        case "Details":
            componentToReturn = <DetailsComponent context={context} page={page} />;
            break;

        case "Total":
            componentToReturn = <TotalComponent context={context} />;
            break;

        case "Words":
            componentToReturn = <TotalInWords context={context} />;
            break;

        case "Bank":
            componentToReturn = <BankComponent context={context} currentFirm={currentFirm} />;
            break;

        case "Terms":
            componentToReturn = <TermsComponent context={context} />;
            break;

        case "Receiver":
            componentToReturn = <ReceiverSignature context={context} />;
            break;

        case "Sign":
            componentToReturn = <SignatureComp currentFirm={currentFirm} />;
            break;

        case "Empty":
            componentToReturn = <></>;
            break;

        case "BOMItems":
            componentToReturn = <BOMItems context={context} />;
            break;

        case "StatementBank":
            componentToReturn = <StmtBankComponent context={context} currentFirm={currentFirm} />
            break;

        case "EndOfStatement":
            componentToReturn = <EndOfStatement context={context} page={page} />
            break

        case "Transactions":
            componentToReturn = <StmtTransactions context={context} />
            break;

        case "StatementTotal":
            componentToReturn = <StmtClosingBalance context={context} />
            break;

        case "ChallanItems":
            componentToReturn = <ChallanItemsFormat context={context} page={page} />
            break;

        default:
            break;
    }

    return componentToReturn;
}