import React from 'react';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { GenericErrorComponent, GenericSuccessComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { CASH_TAG, CHARTOF_ACC, DEFAULT_STORE_TAG, EDITEXPENSESACCOUNTS, INR_RESOURCE_ID, NOT_APPLICABLE, UPDATE_ON_INR_ACCOUNT } from '../../Helpers/ConstantProperties';
import DeleteMenuItem from '../GenericComponents/DataView/DeleteMenuItem';
import GenericSkeleton from '../GenericComponents/DataView/GenericSkeleton';
import { Paper } from '@mui/material';
import CardActionButtons from '../GenericComponents/DataView/CardActionButtons';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';
import EditMenuItem from '../GenericComponents/DataView/EditMenuItem';
import { ShowNumber } from '../../Helpers/helpers';
import AddRCMMenu from './AddRCMMenu';
import { ACC_GROUPS_LIST, BANK_ACCOUNT_TAG, EMPLOYEE_TAG, PARTY_TAG } from '../../Helpers/ExtraProperties';
import StatementAmount from '../Statements/StatementAmount';
import ShowBalance from './ShowBalance';

export const getAccountGroupDD = (tag) => {

    switch (tag) {
        case PARTY_TAG:
            return { lable: "Party", value: PARTY_TAG }

        case EMPLOYEE_TAG:
            return { lable: "Employee", value: EMPLOYEE_TAG }

        case BANK_ACCOUNT_TAG:
            return { lable: "Bank Account", value: BANK_ACCOUNT_TAG }

        case CASH_TAG:
            return { lable: "Cash In Hand", value: CASH_TAG }

        case DEFAULT_STORE_TAG:
            return { lable: "Store Account", value: DEFAULT_STORE_TAG }

        default:
            break;
    }

    return ACC_GROUPS_LIST.find((group) => group.value === tag)
}

const ExpenseAccCard = ({ item }) => {
    const navigate = useNavigate();
    let successMessage = "Account Deleted SuccessFully"

    const deleteInquiry = async (token, khid) => {
        return await serviceHelpers.deleteAccount(token, khid, item.id);
    }

    // Edit only for few Account Tags.
    const allowEditTags = ACC_GROUPS_LIST.map((group) => group.value);

    const onEdit = () => {
        navigate(EDITEXPENSESACCOUNTS, { state: item });
    };

    const onDelete = {
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        navigateTo: CHARTOF_ACC,
        queryFunction: deleteInquiry,
        queryKeyValue: UPDATE_ON_INR_ACCOUNT,
    }

    const menuItems = [
        <AddRCMMenu item={item} />,
        <ShowBalance item={item} />
    ]

    if (!item.known) {
        if (allowEditTags.includes(item.tag)) {
            menuItems.push(<EditMenuItem onEdit={onEdit} />)
        }

        menuItems.push(<DeleteMenuItem onDelete={onDelete} />)
    }

    if (item.showSkeleton) return <GenericSkeleton />;

    return (
        <Grid2 key={item.id} xs={12}>
            <Paper className="bg-white">
                <Grid2 container alignItems={"center"} paddingX={1}>

                    <Grid2 xs={12} md={4}>
                        {item.name}
                    </Grid2>

                    <Grid2 xs={12} md={2}>
                        {getAccountGroupDD(item.tag)?.lable || item.tag}
                    </Grid2>

                    <Grid2 xs={12} md={2}>
                        RCM: {item.rcmEnabled ? (item.GSTRate + "%") : NOT_APPLICABLE}
                    </Grid2>

                    <Grid2 xs={11} md={2} textAlign={"right"}>
                        {ShowNumber(Math.abs(item[INR_RESOURCE_ID] || 0), 2, true)}
                    </Grid2>

                    <Grid2 xs={1} textAlign={"center"}>
                        <StatementAmount txn={{ units: (item[INR_RESOURCE_ID] || 0) }} />
                    </Grid2>

                    <Grid2 md></Grid2>

                    <CardActionButtons menuList={menuItems} />

                </Grid2>
            </Paper>
        </Grid2>
    );
};

export default ExpenseAccCard;