import React, { useState } from 'react';
import GenericFormHeader from '../GenericComponents/FormComponent/GenericFormHeader';
import Grid2 from '@mui/material/Unstable_Grid2';
import CustomerChallans from './CustomerChallans';
import useArray from '../GenericComponents/Array/GenericArrayHandler';
import ChallanItems from './ChallanItems';

const InvoiceFromChallan = () => {
    const challanList = useArray({ initState: [] });
    const [party, setParty] = useState(null);

    return (
        <>
            <GenericFormHeader title={"New Invoice From Challans"} enableBack={true} />
            <Grid2 container padding={1} rowSpacing={2}>
                <Grid2 xs={12}>
                    <CustomerChallans party={party} setParty={setParty} challanList={challanList} />
                </Grid2>

                <Grid2 xs={12}>
                    <ChallanItems
                        challans={challanList.data}
                        party={party}
                        setParty={setParty}
                        clearData={challanList.clearData} />
                </Grid2>
            </Grid2>
        </>
    );
};

export default InvoiceFromChallan;