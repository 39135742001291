import React, { useContext, useState } from 'react';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import Grid2 from '@mui/material/Unstable_Grid2';
import StaticDropDown from '../GenericComponents/DropDown/StaticDropDown';
import { COMMON_BATCH, INR_RESOURCE_ID, ROUNDOFF_ID, TAX_INVOICE, TDS_DEDUCTED_ID, TDS_COLLECTED_ID, TDS_SECTIONS, TDS_VOUCHER, UPDATE_ON_VOUCHER, NOT_AVAILABLE } from '../../Helpers/ConstantProperties';
import GenericMutateButton from '../GenericComponents/Buttons/GenericMutateButton';
import AddProperty from '../AddProperties/AddProperty';
import { SchemaTypes } from '../../Helpers/ExtraProperties';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRoundUptoTwoPlaces } from '../../Helpers/helpers';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import useArray from '../GenericComponents/Array/GenericArrayHandler';
import { Button } from '@mui/material';
import GenericFormHeader from '../GenericComponents/FormComponent/GenericFormHeader';
import TDSEntryTable from './TDSEntryTable';
import { DateContext } from '../Contexts/DateContext';

const ApplyTDSForm = () => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const { currentDate } = useContext(DateContext);

    const navigate = useNavigate();

    const location = useLocation();
    const document = location.state?.document;

    const voucherDetails = document.type === TAX_INVOICE
        ? {
            accountID: TDS_DEDUCTED_ID,
            partyName: document.customerName,
            partyID: document.customerId,
            note: "TDS Deducted by customer for invoice " + document.refranceId,
        }
        : {
            accountID: TDS_COLLECTED_ID,
            partyName: document.vendorName,
            partyID: document.vendorId,
            note: "TDS collected from vendor for invoice " + document.refranceId,
        }

    voucherDetails.panNumber = document.panNumber;

    const dataFromDocument = [];

    document.cData?.items.forEach((item) => {
        if (item.tdsSection) {
            const existing = dataFromDocument.find((entry) =>
            (
                entry.section.section === item.tdsSection.section
                && entry.rate === item.tdsSection.rate
            ));

            if (existing) {
                existing.taxable += item.taxableAmount;
                existing.products.push(item.name)
            }
            else {
                dataFromDocument.push({
                    section: item.tdsSection,
                    taxable: item.taxableAmount,
                    rate: item.tdsSection.rate,
                    products: [item.name],
                })
            }
        }
    })

    const [section, setSection] = useState(null);
    const [taxable, setTaxable] = useState(0);
    const [rate, setRate] = useState(section ? section.rate : 0);

    const { data, addEntry, removeEntry, clearData } = useArray({ initState: dataFromDocument });

    const setTdsSection = (value) => {
        setSection(value);
        setRate(value.rate);
    }

    const addSectionToArray = () => {
        const entry = {
            section: section,
            taxable: taxable,
            rate: rate,
            products: [NOT_AVAILABLE]
        }

        addEntry(entry);

        setSection(null);
        setTaxable(0);
        setRate(0);
    }

    const canSubmit = section !== null && taxable > 0 && rate > 0;

    const canSave = data.length !== 0;

    const queryClient = useQueryClient();

    const onSuccess = async () => {
        await queryClient.invalidateQueries({
            predicate: (query) =>
                query.queryKey.includes(UPDATE_ON_VOUCHER)
        });

        navigate(-1)
        setSection(null);
        setTaxable(0);
        setRate(0);
        clearData();
    }

    const queryFn = async () => {

        const voucher = {
            date: currentDate.getTime(),
            type: TDS_VOUCHER,
            verified: true,
            transactions: [],
            refranceId: document.refranceId,
            notes: voucherDetails.note,
            tdsData: data
        }

        let totalTax = 0;
        let sectionWise = [];

        data.forEach((tds) => {
            const tdsTax = ((tds.taxable * tds.rate) / 100);
            totalTax += tdsTax;

            // Section list is only used for TDS Collected
            // so we can safely negate it.
            sectionWise.push({
                id: tds.section.section,
                units: -tdsTax
            })
        })

        totalTax = getRoundUptoTwoPlaces(totalTax);

        let partyAmt = Math.round(totalTax);
        let roundOff = getRoundUptoTwoPlaces(partyAmt - totalTax);

        if (document.type === TAX_INVOICE) {
            // This TDS is considered as payment received from the party
            partyAmt = -1 * partyAmt;
        }
        else {
            // This TDS is considered as payment paid to the party.
            totalTax = -1 * totalTax
            roundOff = -1 * roundOff;
        }

        const txnParams = {
            name: voucherDetails.partyName,
            panNumber: voucherDetails.panNumber,
            invoiceDate: document.date,
            resourceID: INR_RESOURCE_ID,
            tdsData: data,
        }

        // TAX Transaction
        const tdsTxn = {
            ...txnParams,
            accountID: voucherDetails.accountID,
            units: totalTax,
        }

        tdsTxn.batches = document.type === TAX_INVOICE
            ? [{ id: voucherDetails.partyID, units: totalTax }]
            : sectionWise

        voucher.transactions.push(tdsTxn);

        // Customer Transaction
        voucher.transactions.push({
            ...txnParams,
            accountID: voucherDetails.partyID,
            units: partyAmt,
            batches: [{
                id: (document.inrBatch?.toString() || voucherDetails.invoiceID),
                units: partyAmt
            }],
        })

        // Round-Off
        if (roundOff !== 0) {
            voucher.transactions.push({
                ...txnParams,
                accountID: ROUNDOFF_ID,
                units: roundOff,
                batches: [{ id: COMMON_BATCH, units: roundOff }],
            })
        }

        const result = await serviceHelpers.creteProductVoucher(token, khID, voucher);

        const voucherUpdate = {
            tdsAdjusted: true,
            tdsVoucherID: result.id,
            tdsData: data
        }

        await serviceHelpers.patchVoucher(token, khID, voucherUpdate, document.id);
        return result;
    }

    return (
        <>
            <GenericFormHeader
                title={"TDS entry for " + document.refranceId}
                enableBack={true} />

            <Grid2 container rowGap={2} px={2}>
                <Grid2 xs={12} md={4}>
                    <StaticDropDown
                        getOptionLabel={(option) => (option.section + " - " + option.rate + "%")}
                        currentValue={section}
                        props={{
                            data: {
                                item: {
                                    displayName: "TDS Section",
                                    name: "tdsSection",
                                    required: true,
                                    dropDownList: TDS_SECTIONS
                                }
                            },
                            onChange: (value) => setTdsSection(value.value),
                        }}
                        isOptionEqualToValue={(option, value) => option.section === value.section}
                    />
                </Grid2>

                <Grid2 xs={12} md={3}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: "Total Amount",
                                name: "taxable",
                                type: SchemaTypes.INRAMOUNT,
                                required: true,
                                helperText: 'Please enter the Taxable amount',
                            }
                        }}
                        currentValue={taxable}
                        onChange={(value) => setTaxable(value.value)} />
                </Grid2>

                <Grid2 xs={12} md={3}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: "TDS Rate",
                                name: "tdsRate",
                                type: SchemaTypes.GSTRATE,
                                required: true,
                                helperText: 'Please enter the TDS Rate',
                            }
                        }}
                        currentValue={rate}
                        onChange={(value) => setRate(value.value)} />
                </Grid2>

                <Grid2 xs={12} md={2} textAlign={"center"} alignSelf={"center"}>
                    <Button
                        onClick={addSectionToArray}
                        disabled={!canSubmit}
                        variant="contained"
                        color="success">
                        {"Add To List"}
                    </Button>
                </Grid2>
            </Grid2>

            <Grid2 container paddingTop={3}>

                <Grid2 xs={12}>
                    <TDSEntryTable taxData={data} removeEntry={removeEntry} />
                </Grid2>

                <Grid2 xs={12} textAlign={"center"} paddingTop={3}>
                    <GenericMutateButton
                        buttonText={"Save"}
                        disable={!canSave}
                        onSuccess={onSuccess}
                        queryFn={queryFn}
                        successMessage={"TDS infomation changed."} />
                </Grid2>
            </Grid2>
        </>
    )

};

export default ApplyTDSForm;