import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import GenericForm from '../GenericComponents/FormComponent/GenericForm';
import { GenericErrorComponent, GenericSuccessComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { GenericVerifyComponent } from '../GenericComponents/VerifyComponent/FirmVerifyComponent';

const CreateVendorForm = ({
    routeDetail,
    formTitle,
    propertyList,
    invalidateQueryKey,
    successMessage,
    type
}) => {
    const queryFunction = async (state, token, khID) => {
        state.type = type;
        const response = await serviceHelpers.createParty(token, khID, state);
        return response
    }

    const newFormProps = {
        formTitle: formTitle,
        propertyList: propertyList,
        queryFunction: queryFunction,
        queryKeyValue: invalidateQueryKey,
        navigateTo: routeDetail,
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        addButtonText: "Add",
        enableVerify: true,
        VerifyAlertComponent: GenericVerifyComponent,
        buttonClasses: "",
        currentData: {},
        enableClear: true
    }

    return (
        <GenericForm
            {...newFormProps}
        />
    )
}
export default CreateVendorForm