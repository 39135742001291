import React, { useContext, useState } from 'react';
import { DateContext } from '../Contexts/DateContext';
import dayjs from 'dayjs';
import GenericFormHeader from '../GenericComponents/FormComponent/GenericFormHeader';
import Grid2 from '@mui/material/Unstable_Grid2';
import AddProperty from '../AddProperties/AddProperty';
import { SchemaTypes } from '../../Helpers/ExtraProperties';
import TDSBalance from './TDSBalance';

const TDSPayment = () => {
    const { currentDate } = useContext(DateContext);
    const [selectedDate, setSelectedDate] = useState(dayjs(currentDate).subtract(1, 'month'));

    return (
        <>
            <GenericFormHeader title={"TDS Payment"} enableBack={true} />
            <Grid2 container alignItems={"center"}>
                <Grid2 xs></Grid2>

                <Grid2 xs={3}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: 'As of Month',
                                name: 'documentDate',
                                required: true,
                                type: SchemaTypes.DATEPICKER,
                                helperText: 'Please Select the Date',
                            },
                            extraProps: {
                                views: ['year', 'month']
                            }
                        }}
                        deleteField={(element) => { setSelectedDate(dayjs(currentDate)) }}
                        currentValue={selectedDate}
                        onChange={(e) => setSelectedDate(e.value)}
                    />
                </Grid2>

                <Grid2 xs={12}>
                    <TDSBalance selectedMonth={selectedDate} />
                </Grid2>

            </Grid2>
        </>
    );
};

export default TDSPayment;