import React, { useContext } from 'react';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { BALANCE, UPDATE_ON_INR_ACCOUNT, UPDATE_ON_VOUCHER } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { useQuery } from '@tanstack/react-query';
import GenericSpinner from '../GenericComponents/FormComponent/GenericSpinner';
import { GenericErrorComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { Alert, Box } from '@mui/material';
import { getLocalDateString, ShowNumber } from '../../Helpers/helpers';
import Grid2 from '@mui/material/Unstable_Grid2';
import StatementAmount from '../Statements/StatementAmount';

const BalanceDetails = ({ account, resource, date }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const queryKey = [
        token,
        khID,
        UPDATE_ON_VOUCHER,
        UPDATE_ON_INR_ACCOUNT,
        resource.id,
        BALANCE + account.id,
        new Date(date).getTime()
    ];

    // Get all the transaction from given month for OutGST account
    const queryFn = async () => {
        const tdsOptions = {
            accountID: account.id,
            resourceID: resource.id,
            date: date,
            withBatches: true,
        }

        return await serviceHelpers.getResourceBalance(token, khID, tdsOptions)
    }

    const { data, isLoading, error } = useQuery({
        queryKey: queryKey,
        queryFn: queryFn,
    })

    if (isLoading) return <GenericSpinner />
    if (error) return <GenericErrorComponent error={error} />

    if (data) {

        if (data.length === 0 || data[0].units === 0) {
            return (
                <Box paddingX={2}>
                    <Alert color='success'>
                        {"No balance available as on date " + getLocalDateString(date)}
                    </Alert>
                </Box>
            )
        }

        return (
            <Box paddingX={2}>
                <Grid2 container>
                    {
                        Object.entries(data[0].batches).map(([id, value]) => (
                            <Grid2 xs={12} key={id}>
                                <Box>
                                    <Grid2 container>
                                        <Grid2 xs={8}>
                                            {value.BatchObject?.invoiceID || id}
                                        </Grid2>

                                        <Grid2 xs={3} textAlign={"right"} paddingRight={1}>
                                            {ShowNumber(Math.abs(value.units), 2, true)}
                                        </Grid2>

                                        <Grid2 xs={1} textAlign={"right"} paddingRight={1}>
                                            <StatementAmount txn={value} />
                                        </Grid2>
                                    </Grid2>
                                </Box>
                            </Grid2>
                        ))
                    }
                </Grid2>
            </Box>
        )

    }

    return <></>
};

export default BalanceDetails;