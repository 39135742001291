import React from 'react';
import { useLocation } from 'react-router-dom';
import ProductDetails from './ProductDetails';
import { Box, Paper } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import GenericFormHeader from '../GenericComponents/FormComponent/GenericFormHeader';
import { DetailPaneContext } from '../Inquiry/InquiryInformation';
import DetailPaneWithDropdown from '../GenericComponents/Layout/DetailPaneWithDropdown';
import ProductInspectionTable from './ProductInspectionTable';
import ProductBOMList from './ProductBOMList';

const ProductInformation = () => {
    const { state } = useLocation();

    const product = state.product;
    const tabData = [
        { id: 0, name: "Product Detail", Component: ProductDetails },
        { id: 1, name: "Inspection Points", Component: ProductInspectionTable },
        { id: 2, name: "BOM", Component: ProductBOMList },
    ];

    return (
        <Paper elevation={1} className='p-3'>
            <GenericFormHeader enableBack={true} title={product.name} />

            <Grid2 container display={"flex"}>
                <Grid2 xs={12} flexGrow={1}>
                    <Box height={"80vh"} overflow={"auto"}>
                        <DetailPaneContext.Provider value={{ disableAction: true }}>
                            <DetailPaneWithDropdown
                                item={product}
                                tabData={tabData}
                                paneID={"ProductInformation"}
                            />
                        </DetailPaneContext.Provider>
                    </Box>
                </Grid2>
            </Grid2>
        </Paper>
    )
};

export default ProductInformation;