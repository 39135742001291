import { Typography } from '@mui/material';
import React from 'react';
import { commonFontSize, commonFontWeight, largeFontSize } from '../../Helpers/ConstantProperties';
import Grid2 from '@mui/material/Unstable_Grid2';
import { checkValue } from '../../Helpers/helpers';

export const Label = ({ label, value }) => (
    <Typography
        className='text-break'
        variant={"body2"}
        noWrap={false}
        fontSize={commonFontSize}
        padding={0.5}
    >
        <span><strong>{label}</strong> {value}</span>
    </Typography>
);

const PurchaseVendor = ({ cData }) => {
    return (
        <Grid2 container>
            <Grid2 xs={12} padding={0.5}>
                <Typography variant='body2' >{"Party"}</Typography>
            </Grid2>

            <Grid2 xs={12}>
                <Typography
                    paddingX={0.5}
                    variant="body1"
                    sx={{ fontWeight: commonFontWeight, fontSize: largeFontSize }}
                    color={'primary.main'}>
                    {cData.vendorName || cData.customerName}
                </Typography>
            </Grid2>

            <Grid2 xs={12}>
                <Label label="City:" value={checkValue(cData.city)} />
            </Grid2>
            <Grid2 xs={12}>
                <Label label="GSTIN:" value={checkValue(cData.gstin)} />
            </Grid2>
        </Grid2>
    );
};

export default PurchaseVendor;