import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { CHARTOF_ACC, UPDATE_ON_INR_ACCOUNT } from '../../Helpers/ConstantProperties';
import { GenericErrorComponent, GenericSuccessComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { GenericVerifyComponent } from '../GenericComponents/VerifyComponent/FirmVerifyComponent';
import GenericForm from '../GenericComponents/FormComponent/GenericForm';
import { InrAccountList } from '../../Helpers/ExtraProperties';
import { handleAccountPostDispatch } from '../../Helpers/helpers';

const AddNewINRAccount = () => {
    const successMessage = "Account Added SuccessFully "

    const queryFunction = async (state, token, khID) => {
        state.tag = state.accountGroup.value;

        delete state.accountGroup;
        delete state.account;

        return await serviceHelpers.createInrAccount(token, khID, state)
    }

    const invalidateQueryKey = UPDATE_ON_INR_ACCOUNT;

    const newFormProps = {
        formTitle: "Add New INR Account",
        propertyList: InrAccountList,
        queryFunction: queryFunction,
        queryKeyValue: invalidateQueryKey,
        navigateTo: CHARTOF_ACC,
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        addButtonText: "Add",
        enableVerify: true,
        VerifyAlertComponent: GenericVerifyComponent,
        buttonClasses: "",
        currentData: {},
        enableClear: true,
        afterDispatch: handleAccountPostDispatch
    }

    return (
        <GenericForm
            {...newFormProps}
        />
    )
};

export default AddNewINRAccount;