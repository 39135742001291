import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useContext, useState } from 'react';
import AddProperty from '../AddProperties/AddProperty';
import { propertyList } from '../../Helpers/ExtraProperties';
import GenericMutateButton from '../GenericComponents/Buttons/GenericMutateButton';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { useQueryClient } from '@tanstack/react-query';
import { UPDATE_ON_INR_ACCOUNT } from '../../Helpers/ConstantProperties';

const RCMRateForm = ({ item }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const [rate, setRate] = useState(item.rcmRate);

    const changeRCM = async () => {
        return await serviceHelpers.changeRCMOnAccount(token, khID, item.id, rate);
    }

    const queryClient = useQueryClient();

    const qkeys = [UPDATE_ON_INR_ACCOUNT]

    const onSuccess = async () => {
        queryClient.invalidateQueries({
            predicate: (query) => qkeys.some((queryKey) => query.queryKey.includes(queryKey)),
        })

        return true;
    }

    return (
        <Grid2 container>
            <Grid2 xs={12}>
                <AddProperty
                    data={{
                        item: propertyList.ProductgstRate,
                    }}
                    deleteField={(element) => { setRate(null) }}
                    currentValue={rate}
                    onChange={(e) => setRate(e.value)}
                />
            </Grid2>

            <Grid2 xs={12} textAlign={"center"} paddingTop={2}>
                <GenericMutateButton
                    disable={false}
                    onSuccess={onSuccess}
                    queryFn={changeRCM}
                    successMessage={"RCM Rate changed."} />
            </Grid2>
        </Grid2>
    );
};

export default RCMRateForm;