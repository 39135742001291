import { LAST_PART, MID_PART, TOP_PART } from "../../../Helpers/ConstantProperties";

export const statementLayout = [
    {
        section: TOP_PART,
        rows: [
            {
                label: "Logo",
                layout: {
                    xs: 2
                },
            },
            {
                label: "Heading",
                layout: {
                    xs: 10,
                },
            },
        ]
    },
    {
        section: TOP_PART,
        rows: [
            {
                label: "Divider",
                style: {
                    paddingVertical: "3mm",
                }
            }
        ]
    },
    {
        section: TOP_PART,
        rows: [
            {
                label: "From",
            },
            {
                label: "To",
            },
            {
                label: "Details",
            },
        ]
    },
    {
        section: MID_PART,
        rows: [
            {
                label: "Transactions",
                style: {
                    paddingVertical: "3mm",
                }
            },
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                showOnLast: true,
                label: "StatementBank",
                layout: {
                    xs: 5
                },
                style: {
                    paddingVertical: "3mm",
                    fontSize: 8,
                }
            },
            {
                showOnLast: true,
                label: "Empty",
                layout: {
                    xs: 3
                },
            },
            {
                showOnLast: true,
                label: "StatementTotal",
                layout: {
                    xs: 4
                },
                style: {
                    paddingRight: "3mm",
                    paddingBottom: "3mm",
                    alignSelf: "flex-end"
                }
            }
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                showOnLast: true,
                label: "EndDivider",
                style: {
                    paddingVertical: "3mm",
                }
            }
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                showOnLast: true,
                label: "EndOfStatement",
            }
        ]
    }
]