import { LAST_PART, TOP_PART } from "../../../Helpers/ConstantProperties";

export const outChallanFormat = [
    // {
    //     section: TOP_PART,
    //     rows: [
    //         {
    //             label: "Logo",
    //             layout: {
    //                 xs: 2
    //             },
    //         },
    //         {
    //             label: "Heading",
    //             layout: {
    //                 xs: 10,
    //             },
    //         },
    //     ]
    // },
    // {
    //     section: TOP_PART,
    //     rows: [
    //         {
    //             label: "Divider",
    //             style: {
    //                 paddingVertical: "3mm",
    //             }
    //         }
    //     ]
    // },
    {
        section: TOP_PART,
        rows: [
            {
                label: "From",
            },
            {
                label: "To",
            },
            {
                label: "Details",
            },
        ]
    },
    {
        section: TOP_PART,
        rows: [
            {
                label: "ChallanItems",
                style: {
                    paddingVertical: "3mm",
                }
            },
        ]
    },
    {
        section: TOP_PART,
        rows: [
            {
                label: "Logo",
                layout: {
                    xs: 4
                },
            },
            {
                showOnLast: true,
                label: "Receiver",
                layout: {
                    xs: 4
                },
                style: {
                    fontSize: 8,
                    textAlign: "center",
                },
            },
            {
                showOnLast: true,
                label: "Sign",
                layout: {
                    xs: 4
                },
                style: {
                    fontSize: 8,
                    textAlign: "center",
                },
            }
        ]
    },

    {
        section: LAST_PART,
        rows: [
            {
                label: "From",
            },
            {
                label: "To",
            },
            {
                label: "Details",
            },
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                label: "ChallanItems",
                style: {
                    paddingVertical: "3mm",
                }
            },
        ]
    },
    {
        section: LAST_PART,
        rows: [
            {
                label: "Logo",
                layout: {
                    xs: 4
                },
            },
            {
                showOnLast: true,
                label: "Receiver",
                layout: {
                    xs: 4
                },
                style: {
                    fontSize: 8,
                    textAlign: "center",
                },
            },
            {
                showOnLast: true,
                label: "Sign",
                layout: {
                    xs: 4
                },
                style: {
                    fontSize: 8,
                    textAlign: "center",
                },
            }
        ]
    }
]