import { Box, IconButton, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { commonFontWeight, PRIMARY_COLOR, smallFontSize } from '../../Helpers/ConstantProperties';
import { ShowNumber } from '../../Helpers/helpers';
import DeleteIcon from '@mui/icons-material/Delete';

const rightBorder = "1px solid gray";

const PurchaseItems = ({ cData, removeEntry = null }) => {
    let nameWidth = removeEntry ? 3 : 3.5;
    if (cData.skipCurrency) nameWidth += 3;

    return (
        <Grid2 container paddingTop={1} textAlign={"center"}>

            <Grid2 xs={12}>
                <Box fontWeight={commonFontWeight} className="d-none d-sm-block">
                    <Grid2 container paddingY={1} className="bg-primary-subtle rounded">
                        <Grid2 md={cData.skipCurrency ? 6.5 : 3.5}>
                            Item Name
                        </Grid2>

                        <Grid2 md={1.5}>
                            HSN Code
                        </Grid2>

                        <Grid2 md={cData.skipCurrency ? 4 : 2}>
                            Quantity
                        </Grid2>

                        {
                            !cData.skipCurrency &&
                            <>
                                <Grid2 md={2.5}>
                                    Rate
                                </Grid2>

                                <Grid2 md={2.5}>
                                    Taxable Amount
                                </Grid2>
                            </>
                        }
                    </Grid2>
                </Box>
            </Grid2>

            {
                cData.items.map((item, index) => (
                    <Grid2 xs={12} key={index} borderBottom={rightBorder}>
                        <Box padding={1}>
                            <Grid2 container>
                                {
                                    removeEntry &&
                                    <Grid2 md={0.5}>

                                        <IconButton
                                            onClick={() => removeEntry((entry) =>
                                                entry.product.id === item.id)}>

                                            <DeleteIcon color='error' />

                                        </IconButton>
                                    </Grid2>
                                }


                                <Grid2 md={nameWidth} borderRight={rightBorder}>
                                    {item.name}
                                    <Typography fontSize={smallFontSize} color={PRIMARY_COLOR}>
                                        {item.productdescription}
                                    </Typography>
                                </Grid2>

                                <Grid2 md={1.5} borderRight={rightBorder}>
                                    {item.productHSNcode}
                                    <Typography fontSize={smallFontSize} color={PRIMARY_COLOR}>
                                        {item.GSTRate} %
                                    </Typography>
                                </Grid2>

                                <Grid2 md={cData.skipCurrency ? 4 : 2} borderRight={rightBorder}>
                                    {ShowNumber(item.units, 2)}
                                    <Typography fontSize={smallFontSize} color={PRIMARY_COLOR}>
                                        {item.unit}
                                    </Typography>
                                </Grid2>

                                {
                                    !cData.skipCurrency &&
                                    <>
                                        <Grid2 md={2.5}
                                            textAlign={"right"}
                                            paddingRight={1}
                                            borderRight={rightBorder}>
                                            {ShowNumber(item.saleRate, 2, true)}
                                            <Typography fontSize={smallFontSize} color={PRIMARY_COLOR}>
                                                {ShowNumber(item.inclusiveRate, 2, true)}
                                            </Typography>
                                        </Grid2>

                                        <Grid2 md={2.5} textAlign={"right"} paddingRight={1}>
                                            {ShowNumber(item.taxableAmount, 2, true)}
                                            <Typography fontSize={smallFontSize} color={PRIMARY_COLOR}>
                                                {item.gstValueString}
                                            </Typography>
                                        </Grid2>
                                    </>
                                }
                            </Grid2>
                        </Box>
                    </Grid2>
                ))
            }

            <Grid2 xs={12}>
                <Box fontWeight={commonFontWeight} className="d-none d-sm-block">
                    <Grid2 container paddingY={1} className="bg-secondary-subtle rounded">
                        <Grid2 md={cData.skipCurrency ? 8 : 5}>
                            Total
                        </Grid2>

                        <Grid2 md={cData.skipCurrency ? 4 : 2}>
                            {ShowNumber(cData.totalQuantity, 2)}
                        </Grid2>

                        {
                            !cData.skipCurrency &&
                            <Grid2 md={5} textAlign={"right"} paddingRight={1}>
                                {ShowNumber(cData.totalTaxableAmount, 2, true)}
                            </Grid2>
                        }
                    </Grid2>
                </Box>
            </Grid2>

        </Grid2>
    );
};

export default PurchaseItems;