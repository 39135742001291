import { useLocation } from "react-router-dom";
import DisplayInChallan from "./DisplayInChallan";
import { DOC_OUT_CHALLAN, OUTWORD_CHALLAN } from "../../Helpers/ConstantProperties";
import { checkValue, getLocalDateString } from "../../Helpers/helpers";
import DocumentFormat from "../GenericComponents/Layout/DocumentFormat";

const DisplayChallan = () => {
    const location = useLocation();
    const item = location.state;

    if (item.type === OUTWORD_CHALLAN) {
        const cData = item.cData;

        const documentTitle = "Delivery Challan";
        const documentID = item.refranceId;
        const documentDate = new Date(item.date * 1)

        const detailsToShow = {
            ID: documentID,
            Date: getLocalDateString(documentDate),
            Eway: checkValue(item.eway),
            Vehical: checkValue(item.vehicalNo),
        }

        if (cData.invoiceID) {
            detailsToShow.Invoice = cData.invoiceID
        }

        const context = {
            document: DOC_OUT_CHALLAN,
            documentTitle: documentTitle,
            cData: cData,
            detailsToShow: detailsToShow
        }

        return <DocumentFormat context={context} />
    }

    return <DisplayInChallan item={item} />
}

export default DisplayChallan