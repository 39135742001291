import { MID_PART } from "../../../Helpers/ConstantProperties";
import { deepCopyObject } from "../../../Helpers/helpers";
import { defaultFormatLayout, fomatComponents, poFormatLayout } from "../CommonComp/commonFormatData";
import { outChallanFormat } from "../CommonComp/OutChallanFormat";
import { statementLayout } from "../CommonComp/StatementFormat";
import ItemsFormat from "./ItemsFormat";
import TaxTable from "./TaxTable";
const defaultGetComponent = ({ label, currentFirm, context, page }) => {
    let componentToReturn = null;

    switch (label) {
        case "Items":
            componentToReturn = <ItemsFormat context={context} page={page} />
            break;

        case "Tax":
            componentToReturn = <TaxTable context={context} />
            break;

        default:
            componentToReturn = fomatComponents(label, currentFirm, context, page);
            break;
    }

    return componentToReturn;
}

// For BOM we need to change the item table
const bomLayout = deepCopyObject(defaultFormatLayout);

const midRows = bomLayout.find((entry) => entry.section === MID_PART);

if (midRows) {
    const index = midRows.rows.findIndex((entry) => entry.label === "Items");
    if (index !== -1) midRows.rows[index].label = "BOMItems"
}

export const defaultFormat = {
    maxInPage: 12,
    pageSize: 19,
    quoteLayout: defaultFormatLayout,
    invoiceLayout: defaultFormatLayout,
    bomLayout: bomLayout,
    statementLayout: statementLayout,
    outChallanLayout: outChallanFormat,
    poLayout: poFormatLayout,
    getComponent: defaultGetComponent,
}