import React from 'react';
import { useLocation } from 'react-router-dom';
import PurchaseInvoice from './PurchaseInvoice';
import GenericFormHeader from '../GenericComponents/FormComponent/GenericFormHeader';
import { Box } from '@mui/material';

const ViewPurchaseInv = () => {
    const location = useLocation();
    const { voucherObject } = location.state;

    if (!voucherObject) return <></>;

    return (
        <>
            <GenericFormHeader
                title={"Purchase Invoice " + voucherObject.refranceId}
                enableBack={true} />
            <Box paddingX={2}>
                <PurchaseInvoice cData={voucherObject.cData} />
            </Box>
        </>
    )
};

export default ViewPurchaseInv;