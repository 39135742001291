import { PRODUCT_TYPE_PROCESS, PRODUCT_TYPE_RAW, PRODUCT_TYPE_SEMIFINISHED } from "../../Helpers/ConstantProperties"
import { getRoundUptoThreePlaces } from "../../Helpers/helpers";

export const addReqToBOM = (product, units, combinedBOM) => {

    product.bom.forEach((req) => {
        const unitsToAdd = getRoundUptoThreePlaces(req.fgRate * units);

        if (req.product.type === PRODUCT_TYPE_SEMIFINISHED) {
            addReqToBOM(req.product, unitsToAdd, combinedBOM)
        }
        else {
            const existing = combinedBOM.find((item) => item.product.id === req.product.id);

            if (existing) {
                existing.fgRate += unitsToAdd;
            }
            else {
                combinedBOM.push({
                    product: req.product,
                    fgRate: unitsToAdd
                })
            }
        }
    })
}

export const getTypeWiseBOM = (product) => {
    const grouped = product.bom
        ? Object.groupBy(product.bom, ({ product }) => product.type)
        : {};

    return {
        [PRODUCT_TYPE_SEMIFINISHED]: [],
        [PRODUCT_TYPE_RAW]: [],
        [PRODUCT_TYPE_PROCESS]: [],
        ...grouped
    }
}