import React from 'react';
import NewInvoiceReceipt from './NewInvoiceReceipt';
import { MenuItem } from '@mui/material';
import { NEWPAYMENTS } from '../../Helpers/ConstantProperties';

const PostPayment = ({ item, showDialog }) => {
    const onMenuClick = () => {
        showDialog({
            contents: <NewInvoiceReceipt
                document={item}
                action={"Payment"}
                formLocation={NEWPAYMENTS}
                multiplier={1} />,
            title: "Payment for " + item.refranceId
        });
    }

    return (
        <>
            <MenuItem onClick={() => onMenuClick()}>
                {"Add Payment"}
            </MenuItem>
        </>
    );
};

export default PostPayment;