import { useContext, useState } from "react";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import { commonFontSize, commonFontWeight, DELETE_FIELD, INQUIRY_STORE_ACCOUNT_ID } from "../../Helpers/ConstantProperties";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../Contexts/SnackbarProvider";
import { addDaysToToday, checkValueNumber, ShowNumber } from "../../Helpers/helpers";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { calculateGoodTotalsForAssignment, getMutateObject, removeFromStore } from "../Dashboard/InquiryCalculations";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Button, Typography } from "@mui/material";
import GenericSpinner from "../GenericComponents/FormComponent/GenericSpinner";
import AllotPlan from "./AllotPlan";
import { DialogContext } from "../GenericComponents/Dialog/GenericDialogWithButton";

function AssignGoods({ balanceDetails, inquiryID, canAllotAll }) {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const { closeDialog } = useContext(DialogContext);

    const { showSnackbar } = useSnackbar();
    const [disabled, setDisabled] = useState(true);

    const queryFunction = async (obj) => {
        let note = "Balance Alloted: \r\n"
        let totalFg = 0;

        await Promise.all(

            Object.entries(obj).map(([productId, values]) => {

                note += "\r\n For " + values.name + ": \r\n"
                totalFg += values.units

                return Promise.all(
                    Object.entries(values.batches).map(async ([batchID, units]) => {

                        note += "From Batch: " + batchID + ": " + ShowNumber(units, 2) + " \r\n";

                        return await serviceHelpers.patchBalance(token, khID, {
                            accountID: INQUIRY_STORE_ACCOUNT_ID,
                            resourceID: productId,
                            units: units,
                            fromBatch: batchID,
                            toBatch: inquiryID,
                            notes: `${batchID} has been transferred`,
                        });
                    })
                );
            })
        );

        const update = {
            readyToDispatch: true,
            followUpDate: addDaysToToday(2),
        };

        if (removeFromStore(balanceDetails, totalFg)) {
            update.storeNeeded = DELETE_FIELD;
            note += "No more Goods needed, inquiry moved out of store."
        }

        const result = await serviceHelpers.updateLeadStatus(token, khID, update, inquiryID, note);

        setDisabled(true);
        if (closeDialog) closeDialog();

        return result;
    }

    const queryClient = useQueryClient();
    const { mutate, isPending } = useMutation(
        getMutateObject(queryClient, queryFunction, showSnackbar, "Assigned Succesfully", inquiryID)
    );

    const getPlannedQty = (product) => product.planned;
    const getRemainingQty = (product) => product.remaining;

    const handleSubmit = (getQty) => {
        let obj = {}

        balanceDetails
            .filter((product) => getQty(product) > 0)
            .forEach((details) => {

                const { returnBatch } =
                    calculateGoodTotalsForAssignment(
                        details.storeBatches,
                        getQty(details));

                obj[details.id] = {
                    batches: returnBatch,
                    name: details.name,
                    units: getQty(details)
                }
            })

        mutate(obj)
    }

    const onPlanChange = () => {
        const validInput = balanceDetails.every((product) => product.allotable >= product.planned);
        setDisabled(!validInput);
    }

    if (isPending) <GenericSpinner />

    return (
        <Grid2 container>
            <Grid2 xs={12} textAlign={"left"} padding={1}>
                {
                    isPending
                        ? <GenericSpinner />
                        : <Button
                            onClick={() => handleSubmit(getRemainingQty)}
                            disabled={!canAllotAll}>
                            Allot All
                        </Button>
                }
            </Grid2>

            <Grid2 xs={12} className="d-none d-sm-block">
                <Typography
                    component={"span"}
                    fontSize={commonFontSize}
                    fontWeight={commonFontWeight}>
                    <Grid2
                        textAlign={"center"}
                        container
                        className='bg-primary-subtle rounded p-2'>
                        <Grid2 md={3}>Product Name</Grid2>
                        <Grid2 md>Required</Grid2>
                        <Grid2 md>In Store</Grid2>
                        <Grid2 md>Ready</Grid2>
                        <Grid2 md>Remaining</Grid2>
                        <Grid2 md>Max Plannable</Grid2>
                        <Grid2 md>Plan</Grid2>
                    </Grid2>
                </Typography>
            </Grid2>

            <Grid2 xs={12} >
                <Typography component={"span"} fontSize={commonFontSize}>
                    {
                        balanceDetails.filter((product) => product.allotable > 0)
                            .map((details, index) => (
                                <Grid2
                                    alignItems={"center"}
                                    key={index}
                                    textAlign={"center"}
                                    container
                                    className="p-2">

                                    <Grid2 xs={12} md={3}>{details.name}</Grid2>
                                    <Grid2 xs={12} md>
                                        {checkValueNumber(details.required)}
                                    </Grid2>
                                    <Grid2 xs={12} md>
                                        {checkValueNumber(details.storeBalance)}
                                    </Grid2>
                                    <Grid2 xs={12} md>
                                        {checkValueNumber(details.productionDone)}
                                    </Grid2>
                                    <Grid2 xs={12} md>
                                        {checkValueNumber(details.remaining)}
                                    </Grid2>

                                    <Grid2 xs={12} md>
                                        {checkValueNumber(details.allotable)}
                                    </Grid2>

                                    <Grid2 xs={12} md>
                                        <AllotPlan
                                            ProductBalance={details}
                                            maxPossible={details.allotable}
                                            onPlannChange={onPlanChange} />
                                    </Grid2>

                                </Grid2>
                            ))
                    }
                </Typography>
            </Grid2>

            <Grid2 xs={12} textAlign={"center"}>
                {
                    isPending
                        ? <GenericSpinner />
                        : <Button onClick={() => handleSubmit(getPlannedQty)} disabled={disabled}>
                            Allot
                        </Button>
                }
            </Grid2>

        </Grid2>
    )
}

export default AssignGoods;