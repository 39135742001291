import { Box, Typography } from "@mui/material";
import { getRoundUptoTwoPlaces, ShowNumber } from "../../Helpers/helpers";
import { getRateWiseObject } from "./gstHelper";
import Grid2 from "@mui/material/Unstable_Grid2";
import { commonFontWeight, largeFontSize, PRIMARY_COLOR } from "../../Helpers/ConstantProperties";

export default function GstrOneBToCS({ data, gstrJson }) {
    const taxable = {}

    data.forEach(txn => {
        const rateWiseList = txn.rateWiseList
            ? txn.rateWiseList
            : getRateWiseObject(txn.hsnWiseList)

        Object.values(rateWiseList).forEach(element => {
            const rate = element.GSTRate;
            if (!taxable[rate]) taxable[rate] = 0;
            taxable[rate] += element.taxable;
        });
    });

    const border = "1px solid lightgray";

    return (
        <Grid2 container padding={1} rowGap={0.5}>
            <Grid2 xs={12} textAlign={"left"} paddingLeft={2}>
                <Typography fontSize={largeFontSize} color={PRIMARY_COLOR}>
                    B2C Total
                </Typography>
            </Grid2>

            <Grid2
                xs={12}
                border={border}
                fontWeight={commonFontWeight}>

                <Box>
                    <Grid2 container paddingY={0.5} className="bg-primary-subtle">
                        <Grid2 xs={12} md={3}>Type</Grid2>
                        <Grid2 xs={12} md={3}>Place Of Supply</Grid2>
                        <Grid2 xs={12} md={3}>Rate</Grid2>
                        <Grid2 xs={12} md={3}>Taxable Value</Grid2>
                    </Grid2>
                </Box>
            </Grid2>

            {
                Object.entries(taxable).map(([Key, Value], index) => {
                    const halfTax = getRoundUptoTwoPlaces(Key * Value / 200)

                    gstrJson.b2cs.push({
                        "sply_ty": "INTRA",
                        "pos": "27",
                        "typ": "OE",
                        "txval": getRoundUptoTwoPlaces(Value),
                        "rt": parseFloat(Key),
                        "iamt": 0,
                        "camt": halfTax,
                        "samt": halfTax,
                        "csamt": 0
                    })

                    return (
                        <Grid2 xs={12} key={index} border={border}>
                            <Box>
                                <Grid2 container paddingY={0.5}>
                                    <Grid2
                                        xs={12} md={3}
                                        borderLeft={border}
                                        borderRight={border}>
                                        OE
                                    </Grid2>

                                    <Grid2 xs={12} md={3} borderRight={border}>
                                        27-Maharashtra
                                    </Grid2>

                                    <Grid2 xs={12} md={3} borderRight={border}>
                                        {Key}
                                    </Grid2>

                                    <Grid2
                                        textAlign={"right"}
                                        paddingRight={1}
                                        xs={12} md={3}
                                        borderRight={border}>

                                        {ShowNumber(Value, 2, true)}
                                    </Grid2>
                                </Grid2>
                            </Box>
                        </Grid2>
                    )
                })
            }

        </Grid2>
    )
}