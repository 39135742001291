import { useContext } from 'react';
import { UPDATE_ON_VOUCHER, VOUCHER } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import DataView from '../GenericComponents/DataView/DataView';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import DataviewList from '../GenericComponents/DataView/DataviewList';
import ChallanCard from './ChallanCard';
// import { useNavigate } from 'react-router-dom';

function Challan({ type, natigateTo }) {
    const { currentUserObject, token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const queryKey = [
        currentUserObject.uid,
        khID,
        UPDATE_ON_VOUCHER,
        VOUCHER,
        type
    ];

    const queryFunction = async (pageParam) => {
        return await serviceHelpers.getChallans(token, khID, null, { type: type });
    }

    const getSearchableValue = (current) => {
        return (
            current.refranceId
        )
    }

    const routeDetails = { heading: ` ${type}` }

    return (
        <DataView
            routeDetails={routeDetails}
            buttonDetails={{ text: "Add New", navigateTo: natigateTo }}
            getSearchableValue={getSearchableValue}
            queryKeyParameter={queryKey}
            queryFunctionParameter={queryFunction}
            ShowElement={{ Component: ChallanCard }}
            searchingPlaceholder={"Search By Refrance Id"}
            DisplayComponent={DataviewList}
            limitSupported={false}
        />
    )
}

export default Challan;