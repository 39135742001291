import { INQUIRY_STORE_ACCOUNT_ID } from "../../Helpers/ConstantProperties"
import { filterAndSortBatchObject } from "../../Helpers/helpers"
import { serviceHelpers } from "../../Helpers/ServiceHelpers"
import { calculateGoodTotalsForAssignment } from "../Dashboard/InquiryCalculations"

export const getStoreBatches = async (token, khID, product, units) => {
    // Get Store balance and batches
    const storeOptions = {
        accountID: INQUIRY_STORE_ACCOUNT_ID,
        resourceID: product.id,
        date: Date.now(),
        withBatches: true,
    }

    const storeBalance = await serviceHelpers.getResourceBalance(token, khID, storeOptions);

    if (storeBalance && storeBalance[0]) {
        // Sort batches
        const storeBatches = filterAndSortBatchObject(storeBalance[0].batches);

        // Get the batches as per the qty
        const { returnBatch } = calculateGoodTotalsForAssignment(storeBatches, units);

        return returnBatch;
    }

    return {}
}