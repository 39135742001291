import React from 'react';
import PurchaseCardBody from './PurchaseCardBody';
import { EXPENSES, UPDATE_ON_JV_PURCHASE } from '../../Helpers/ConstantProperties';

const ExpenseCard = ({ item }) => {
    return <PurchaseCardBody
        ignoreTransport={true}
        showUnits={false}
        item={item}
        navTo={EXPENSES}
        qKey={UPDATE_ON_JV_PURCHASE} />
};

export default ExpenseCard;