import { Alert, Box, IconButton } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { commonFontWeight } from '../../Helpers/ConstantProperties';
import { INSPECTION_REPORT_GROUP_NAME } from '../../Helpers/ExtraProperties';
import DeleteIcon from '@mui/icons-material/Delete';

const rightBorder = "1px solid gray";

const ProductInspectionTable = ({ item, removeEntry = null }) => {
    if (
        !item[INSPECTION_REPORT_GROUP_NAME] ||
        item[INSPECTION_REPORT_GROUP_NAME].length === 0) {
        return <Alert color='warning'>No Data</Alert>
    }

    const filterFn = (row) => (item) => item.inspectionParameter === row.inspectionParameter

    return (
        <Grid2 container padding={2} textAlign={"center"}>

            <Grid2 xs={12}>
                <Box fontWeight={commonFontWeight} className="d-none d-sm-block">
                    <Grid2 container paddingY={1} className="bg-primary-subtle rounded">
                        <Grid2 md={4}>
                            Parameter Name
                        </Grid2>

                        <Grid2 md={4}>
                            Expected Value
                        </Grid2>

                        <Grid2 md={4}>
                            Tolerance
                        </Grid2>
                    </Grid2>
                </Box>
            </Grid2>

            {
                item[INSPECTION_REPORT_GROUP_NAME]?.map((entry, index) => (
                    <Grid2 xs={12} key={index} borderBottom={rightBorder}>
                        <Box padding={1}>
                            <Grid2 container>
                                {
                                    removeEntry &&
                                    <Grid2 md={0.5}>

                                        <IconButton
                                            onClick={() => removeEntry(filterFn(entry))}>

                                            <DeleteIcon color='error' />

                                        </IconButton>
                                    </Grid2>
                                }


                                <Grid2 md={3.5} borderRight={rightBorder}>
                                    {entry.inspectionParameter}
                                </Grid2>

                                <Grid2 md={4} borderRight={rightBorder}>
                                    {entry.inspectionDiamention}
                                </Grid2>

                                <Grid2 md={4}>
                                    {entry.inspectionTolerance}
                                </Grid2>
                            </Grid2>
                        </Box>
                    </Grid2>
                ))
            }

        </Grid2>
    );
};

export default ProductInspectionTable;