import React from 'react';
import DeleteInvoice from './DeleteInvoice';
import { MenuItem } from '@mui/material';

const DeleteInvoiceMenu = ({ item, showDialog }) => {
    const onMenuClick = () => {
        showDialog({
            contents: <DeleteInvoice item={item} />,
            title: "Delete Invoice " + item.refranceId
        });
    }

    return (
        <MenuItem onClick={() => onMenuClick()}>
            {"Delete Invoice"}
        </MenuItem>
    );
};

export default DeleteInvoiceMenu;