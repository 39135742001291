import { Alert, IconButton, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { PRIMARY_COLOR, regularFontSize } from '../../Helpers/ConstantProperties';
import { ShowNumber } from '../../Helpers/helpers';
import DeleteIcon from '@mui/icons-material/Delete';

const BOMItemTable = ({ productType, bomItems, removeEntry = null }) => {
    const filterFn = (row) => (item) => item.product.id === row.product.id

    return (
        <Grid2 container paddingX={2} rowSpacing={0.5} alignItems={"center"}>
            <Grid2 xs={12} textAlign={"center"} paddingY={1}>
                <Typography
                    component={"span"}
                    fontSize={regularFontSize}
                    color={PRIMARY_COLOR}>
                    {productType}
                </Typography>
            </Grid2>

            {
                bomItems?.map((item) => {
                    return (
                        <React.Fragment key={item.product.id}>
                            {
                                removeEntry &&
                                <Grid2 md={0.5}>

                                    <IconButton
                                        size='small'
                                        onClick={() => removeEntry(filterFn(item))}>

                                        <DeleteIcon color='error' />

                                    </IconButton>
                                </Grid2>
                            }
                            <Grid2 xs={7.5} textAlign={"left"} paddingLeft={2}>
                                {item.product.name}
                            </Grid2>
                            <Grid2 xs={4} textAlign={"right"} paddingRight={1}>
                                {ShowNumber(item.fgRate, 3)}
                            </Grid2>
                        </React.Fragment>
                    )
                })
            }

            {
                (!bomItems || bomItems.length === 0) &&
                <Grid2 xs={12}>
                    <Alert color='warning'>No Items of this type.</Alert>
                </Grid2>
            }
        </Grid2>
    );
};

export default BOMItemTable;