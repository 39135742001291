import { ACCOUNT_PAYABLE, ACCOUNT_RECEIVABLE, CGST_BATCH_ID, CODE_IP, COLOR_RED, COMMON_BATCH, DEMI_INR_RESOURCE_ID, EIGHTEEN_GST, GREEN_BUTTON, IGST_BATCH_ID, INPUT_GST_ACCOUNT_ID, INQUIRY_STORE_ACCOUNT_ID, INR_RESOURCE_ID, INTERNAL_PROCESS, LABOUR_COST, NOT_APPLICABLE, OUT_GST_ACCOUNT_ID, NON_GST_B2C_PARTY, PRIMARY_COLOR, ROUNDOFF_ID, SALE_BATCH_STORE_SUFFIX, SGST_BATCH_ID, TOTAL_SALE_ID, BATCH_BALANCE_UPDATE_TYPE, RCM_GST_ACCOUNT_ID, TOTAL_OTHER_RECEIVABLE_ID, TOTAL_OTHER_PAYABLE_ID } from "../../Helpers/ConstantProperties";
import { NO_DATA, PAYMENT_ADJUSTMENT } from "../../Helpers/ExtraProperties";
import { checkValue, getRoundUptoTwoPlaces, getSaleRate, getTotalUnitsForReq, isIGST, ShowNumber } from "../../Helpers/helpers";
import { addReqToBOM } from "../Product/ProductHelper";

export const getRateDetails = ({
    inquiry,
    currentFirm,
    forceGST = true,
    docFormat = null,
    isBOM = false,
}) => {

    const igstApp = isIGST(currentFirm, inquiry);

    const retVal = {
        igstApp: igstApp,
        items: [],
        totalQuantity: 0,
        totalTaxableAmount: 0,
        totalTax: 0,
        totalRCMTaxable: 0,
        totalRCMTax: 0,
        totalAmountAfterTax: 0,
        taxTypeWiseList: [],
        hsnWiseList: {},
        rateWiseList: {},
        roundedTotal: 0,
        roundOff: 0,
        totalPages: 1,
        tdsApplicable: false,
    }

    // Save few of the inquiry properties to the return object
    // This will enable us to show the document without inquiry
    // object present.
    retVal.poNumber = inquiry.poNumber;
    retVal.customerName = inquiry.customerName;
    retVal.vendorName = inquiry.vendorName;
    retVal.contactPerson = inquiry.contactPerson;
    retVal.city = inquiry.city;
    retVal.gstin = inquiry.gstin;
    retVal.panNumber = inquiry.panNumber;
    retVal.contactPhone = inquiry.contactPhone;
    retVal.contactEmail = inquiry.contactEmail;

    retVal.paymentTerms = inquiry.paymentTerms;
    retVal.transportTerms = inquiry.transportTerms;
    retVal.otherTnC = inquiry.otherTnC;

    const calculateTaxData = (itemToAdd) => {

        if (forceGST || inquiry.gstin || itemToAdd.rcmEnabled) {

            itemToAdd.tax = itemToAdd.taxableAmount * itemToAdd.GSTRate / 100;

            // Make the tax even this is because we spilt the tax in 2
            // in case of CGST, and due to rounding error, the total
            // doesnt match.
            const halfTax = getRoundUptoTwoPlaces(itemToAdd.tax / 2);
            itemToAdd.tax = halfTax * 2;

            if (itemToAdd.rcmEnabled) {
                itemToAdd.rcmTax = itemToAdd.tax;
                itemToAdd.rcmTaxable = itemToAdd.taxableAmount;
            }

            itemToAdd.gstString = igstApp
                ? itemToAdd.GSTRate + "%"
                : (itemToAdd.GSTRate / 2) + "% - " + (itemToAdd.GSTRate / 2) + "%"

            itemToAdd.gstValueString = igstApp
                ? "IGST: " + ShowNumber(itemToAdd.tax, 2, true)
                : "C/S GST: " + ShowNumber(itemToAdd.tax / 2, 2, true)

            itemToAdd.inclusiveRate = itemToAdd.saleRate * (100 + (itemToAdd.GSTRate)) / 100;
            itemToAdd.inclusiveRate = getRoundUptoTwoPlaces(itemToAdd.inclusiveRate);

            if (!retVal.rateWiseList[itemToAdd.GSTRate]) {
                retVal.rateWiseList[itemToAdd.GSTRate] = {
                    GSTRate: itemToAdd.GSTRate,
                    taxable: 0,
                    IGST: 0,
                    CGST: 0,
                    SGST: 0,
                    quantity: 0
                }
            }

            retVal.rateWiseList[itemToAdd.GSTRate].taxable += itemToAdd.taxableAmount;
            retVal.rateWiseList[itemToAdd.GSTRate].quantity += itemToAdd.units;

            if (igstApp) {
                retVal.rateWiseList[itemToAdd.GSTRate].IGST += itemToAdd.tax;
            }
            else {
                retVal.rateWiseList[itemToAdd.GSTRate].CGST = halfTax
                retVal.rateWiseList[itemToAdd.GSTRate].SGST = halfTax
            }

            const gstID = itemToAdd.productHSNcode + "-" + itemToAdd.GSTRate;

            if (!retVal.hsnWiseList[gstID]) {
                retVal.hsnWiseList[gstID] = {
                    taxable: 0,
                    hsncode: itemToAdd.productHSNcode,
                    GSTRate: itemToAdd.GSTRate,
                    quantity: 0,
                    tax: 0,
                };
            }

            retVal.hsnWiseList[gstID].taxable += itemToAdd.taxableAmount;
            retVal.hsnWiseList[gstID].quantity += itemToAdd.units;
            retVal.hsnWiseList[gstID].tax += itemToAdd.tax;

            if (igstApp) {
                retVal.hsnWiseList[gstID].IGST += itemToAdd.tax;
            }
            else {
                retVal.hsnWiseList[gstID].CGST = halfTax
                retVal.hsnWiseList[gstID].SGST = halfTax
            }
        }
        else {
            itemToAdd.tax = 0;
            itemToAdd.gstString = NOT_APPLICABLE;
            itemToAdd.gstValueString = NOT_APPLICABLE;
            itemToAdd.inclusiveRate = itemToAdd.saleRate
        }

        itemToAdd.totalAmount = itemToAdd.taxableAmount + itemToAdd.tax;
        return itemToAdd;
    }

    const getItemWithDetails = (itemToAdd, product, fgQty = 1) => {
        // Sale Rate and Inclusive Rate needs to be rounded.
        itemToAdd.saleRate = parseFloat(getSaleRate(product));

        itemToAdd.units = getTotalUnitsForReq(product, fgQty)
        itemToAdd.productdescription = product.productdescription;

        itemToAdd.taxableAmount = itemToAdd.saleRate * itemToAdd.units;

        if (itemToAdd.tdsSection) {
            retVal.tdsApplicable = true;
        }

        return itemToAdd;
    }

    inquiry.products?.forEach((product) => {
        let itemToAdd = getItemWithDetails({ ...product.product }, product);

        if (isBOM) {
            itemToAdd.taxableAmount = 0;
            itemToAdd.units = 0;
            let requirements = []

            if (product.product.bom) {
                addReqToBOM(product.product, 1, requirements);
            }
            else {
                requirements = [...(product.rmlist || []), ...(product.processes || [])];
            }

            var labour = {
                fgCode: itemToAdd.productItemcode,
                id: CODE_IP,
                productItemcode: CODE_IP,
                name: LABOUR_COST,
                productHSNcode: NO_DATA,
                units: 1,
                saleRate: 0,
                taxableAmount: 0,
                GSTRate: EIGHTEEN_GST
            }

            requirements.forEach((req) => {
                const reqToAdd = getItemWithDetails({ ...req.product }, req, product.units);
                reqToAdd.fgCode = itemToAdd.productItemcode;

                itemToAdd.taxableAmount += reqToAdd.taxableAmount;

                if (reqToAdd.productState === INTERNAL_PROCESS) {
                    labour.saleRate += reqToAdd.taxableAmount;
                    labour.taxableAmount += reqToAdd.taxableAmount;
                }
                else {
                    itemToAdd.units += reqToAdd.units;
                    retVal.items.push(reqToAdd);
                }
            })

            if (labour.taxableAmount > 0) {
                itemToAdd.units += labour.units;
                retVal.items.push(labour);
            }

            itemToAdd = calculateTaxData(itemToAdd)
        }
        else {
            retVal.items.push(calculateTaxData(itemToAdd));
        }

        retVal.totalQuantity += itemToAdd.units;
        retVal.totalTaxableAmount += itemToAdd.taxableAmount;
        retVal.totalTax += itemToAdd.tax;
        retVal.totalRCMTax += (itemToAdd.rcmTax || 0);
        retVal.totalRCMTaxable += (itemToAdd.rcmTaxable || 0);
        retVal.totalAmountAfterTax += itemToAdd.totalAmount;
    })

    // For IGST -- Total Taxable is all IGST
    if (igstApp) {
        retVal.taxTypeWiseList.push({
            type: IGST_BATCH_ID,
            taxable: retVal.totalTaxableAmount,
            tax: retVal.totalTax
        })
    }
    else {
        // For CGST and SGST the total tax is half of total
        // and taxable is same as total
        retVal.taxTypeWiseList.push({
            type: CGST_BATCH_ID,
            taxable: retVal.totalTaxableAmount,
            tax: retVal.totalTax / 2
        })

        retVal.taxTypeWiseList.push({
            type: SGST_BATCH_ID,
            taxable: retVal.totalTaxableAmount,
            tax: retVal.totalTax / 2
        })
    }

    // In case of RCM, the tax is paid by the buyer
    // so in the sellers account, the tax is not added
    const finalTotal = retVal.totalAmountAfterTax - retVal.totalRCMTax;

    retVal.roundedTotal = Math.round(finalTotal)
    retVal.roundOff = getRoundUptoTwoPlaces(retVal.roundedTotal - finalTotal)

    if (docFormat?.maxInPage) {
        let totalItems = retVal.items.length;
        const maxInPage = docFormat.maxInPage;
        const pageSize = docFormat.pageSize;

        if (totalItems > maxInPage) {
            const rem = totalItems - maxInPage;
            retVal.totalPages += Math.ceil(rem / pageSize)
        }
    }

    return retVal;
}

export const getSaleINRTransactions = (inquiry, cData, collectionID = TOTAL_SALE_ID) => {
    const retVal = [];

    // Store transaction
    const storeTxn = {
        units: 0,
        batches: [],
        accountID: INQUIRY_STORE_ACCOUNT_ID,
        resourceID: INR_RESOURCE_ID,
    }

    cData.items.forEach((product) => {
        const suffix = SALE_BATCH_STORE_SUFFIX;

        storeTxn.units -= product.taxableAmount;
        storeTxn.batches.push({
            id: suffix + product.id,
            units: -product.taxableAmount
        })
    })

    // Tax transaction
    const taxTxn = {
        units: -cData.totalTax,
        batches: [],
        accountID: OUT_GST_ACCOUNT_ID,
        hsnWiseList: cData.hsnWiseList,
        rateWiseList: cData.rateWiseList,
        taxable: cData.totalTaxableAmount,
        resourceID: INR_RESOURCE_ID,
        gstin: inquiry.gstin || NON_GST_B2C_PARTY,
        partyName: checkValue(inquiry.customerName),
        invoiceTotal: cData.roundedTotal
    }

    cData.taxTypeWiseList.forEach((tax) => {
        taxTxn.batches.push({
            id: tax.type,
            units: -tax.tax
        })
    })

    // Customer transaction
    const customerTxn = {
        accountID: inquiry.customerId,
        resourceID: INR_RESOURCE_ID,
        batches: [{ index: 0, units: cData.roundedTotal }],
        units: cData.roundedTotal
    }

    // Demi INR collection Accounts
    const receivable = {
        accountID: ACCOUNT_RECEIVABLE,
        resourceID: DEMI_INR_RESOURCE_ID,
        batches: [{ id: inquiry.customerId, units: -cData.roundedTotal }],
        units: -cData.roundedTotal
    }

    const receivable_against = {
        accountID: collectionID,
        resourceID: DEMI_INR_RESOURCE_ID,
        batches: [{ id: COMMON_BATCH, units: cData.totalTaxableAmount }],
        units: cData.totalTaxableAmount
    }

    const diff = cData.roundedTotal - cData.totalTaxableAmount

    const receivable_diff = {
        accountID: TOTAL_OTHER_RECEIVABLE_ID,
        resourceID: DEMI_INR_RESOURCE_ID,
        batches: [{ id: COMMON_BATCH, units: diff }],
        units: diff
    }

    retVal.push(storeTxn, taxTxn, customerTxn, receivable, receivable_against, receivable_diff);

    // Round-Off

    // As the RoundOff value represents the differance to be added to
    // the products and tax so to make the total rounded to zero
    // we need to treat the round-off value same as other 2 values
    // as we are negating the other two values, we need to negate this
    // as well.
    if (cData.roundOff !== 0) {
        const roundTxn = {
            accountID: ROUNDOFF_ID,
            resourceID: INR_RESOURCE_ID,
            units: -cData.roundOff,
            batches: [{ id: COMMON_BATCH, units: -cData.roundOff }]
        }

        retVal.push(roundTxn);
    }

    return retVal;
}

export const getPurchaseINRTransaction = (
    vendor,
    cData,
    batchIndex,
    collectionID,
    extraProps = {}) => {
    const retVal = [];

    // Customer transaction
    const customerTxn = {
        accountID: vendor.id,
        resourceID: INR_RESOURCE_ID,
        batches: [{ index: batchIndex, units: -cData.roundedTotal }],
        units: -cData.roundedTotal,
        ...extraProps
    }

    // Demi INR collection Accounts
    const payable = {
        accountID: ACCOUNT_PAYABLE,
        resourceID: DEMI_INR_RESOURCE_ID,
        batches: [{ id: vendor.id, units: cData.roundedTotal }],
        units: cData.roundedTotal
    }

    const payable_against = {
        accountID: collectionID,
        resourceID: DEMI_INR_RESOURCE_ID,
        batches: [{ id: COMMON_BATCH, units: -cData.totalTaxableAmount }],
        units: -cData.totalTaxableAmount,
        ...extraProps
    }

    retVal.push(customerTxn, payable, payable_against);

    const diff = cData.roundedTotal - cData.totalTaxableAmount;

    if (diff > 0) {
        const payable_diff = {
            accountID: TOTAL_OTHER_PAYABLE_ID,
            resourceID: DEMI_INR_RESOURCE_ID,
            batches: [{ id: COMMON_BATCH, units: -diff }],
            units: -diff,
            ...extraProps
        }

        retVal.push(payable_diff);
    }

    if (cData.totalTax !== 0) {
        // Tax transaction
        const taxTxn = {
            units: cData.totalTax,
            batches: [],
            accountID: INPUT_GST_ACCOUNT_ID,
            hsnWiseList: cData.hsnWiseList,
            taxable: cData.totalTaxableAmount,
            resourceID: INR_RESOURCE_ID,
            gstin: vendor.gstin || NON_GST_B2C_PARTY,
            partyName: vendor.name,
            itc: true,
            gstrTwoAmt: 0,
            itcMatchDate: null,
            ...extraProps
        }

        cData.taxTypeWiseList.forEach((tax) => {
            taxTxn.batches.push({
                id: tax.type,
                units: tax.tax
            })
        })

        retVal.push(taxTxn);
    }

    // Round-Off
    if (cData.roundOff !== 0) {
        const roundTxn = {
            accountID: ROUNDOFF_ID,
            resourceID: INR_RESOURCE_ID,
            units: cData.roundOff,
            batches: [{ id: COMMON_BATCH, units: cData.roundOff }],
            ...extraProps
        }

        retVal.push(roundTxn);
    }

    // RCM if Any
    if (cData.totalRCMTax > 0) {
        const docDate = new Date(cData.documentDate);
        const batchID = docDate.getMonth() + 1 + "-" + docDate.getFullYear();

        const rcmTaxTxn = {
            units: -cData.totalRCMTax,
            batches: [{ id: batchID, units: -cData.totalRCMTax }],
            accountID: RCM_GST_ACCOUNT_ID,
            taxable: cData.totalRCMTaxable,
            resourceID: INR_RESOURCE_ID,
            partyName: vendor.name,
        }

        retVal.push(rcmTaxTxn);
    }

    return retVal;
}

const getCrDr = (number) => number > 0 ? " CR" : " DR";

const getColor = (number) => {
    if (number > 0) return COLOR_RED;
    if (number < 0) return GREEN_BUTTON;
    return PRIMARY_COLOR
}

export const getStatementData = ({ transactions }) => {
    var runningTotal = 0;
    const statements = []

    const batch_transfer_vid = {};

    transactions.forEach((txnObject) => {
        let txn = txnObject;

        if (txn.type === BATCH_BALANCE_UPDATE_TYPE) {
            // This is a batch transfer
            // There are 2 of these transactions
            // We combine and send it to table.

            batch_transfer_vid[txn.vid] = (batch_transfer_vid[txn.vid] || 0) + txn.units;

            if (batch_transfer_vid[txn.vid] === 0) {
                txn = {
                    ...txn,
                    units: 0,
                    type: PAYMENT_ADJUSTMENT,
                    refranceId: NOT_APPLICABLE
                }
            }
            else
                return
        }

        const txnRunning = runningTotal + txn.units;
        const crDr = getCrDr(txnRunning)

        const statement = {
            ...txn,
            running: txnRunning,
            runningText: ShowNumber(Math.abs(txnRunning), 2, true) + crDr,
            textColor: getColor(txnRunning)
        }

        statement.units = parseFloat(txn.units);

        if (txn.opening) {
            statement.vid = "--";
            statement.type = "Opening Balance";
            statement.refranceId = "";
            statement.notes = ""
        }

        runningTotal += txn.units;
        statements.push(statement)
    })

    const crDr = getCrDr(runningTotal)
    const runningText = ShowNumber(Math.abs(runningTotal), 2, true) + crDr;
    const textColor = getColor(runningTotal);

    return { statements, runningTotal, runningText, textColor }
}