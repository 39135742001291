import { useContext } from "react";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import { useLocation } from "react-router-dom";
import { TAX_INVOICE, UPDATE_ON_INR_ACCOUNT, UPDATE_ON_INQUIRY, BALANCE, INQUIRY_STORE_ACCOUNT_ID } from "../../Helpers/ConstantProperties";
import NewPurchaseForm from "../Purchase/NewPurchseForm";
import { propertyList } from "../../Helpers/ExtraProperties";
import { DateContext } from "../Contexts/DateContext";
import { createTaxInvoiceVoucher } from "./VoucherHelper";

const NewInvoice = () => {
    const { token } = useContext(AuthContext);
    const { khID, currentFirm } = useContext(FirmContext);
    const { currentDate } = useContext(DateContext);

    const location = useLocation();
    const inquiry = location.state?.inquiry;

    const queryFn = async ({
        data,
        party,
        cData
    }) => {
        const { id } = await createTaxInvoiceVoucher({
            token: token,
            khID: khID,
            prefix: currentFirm.prefixes.invoice,
            data: data,
            party: party,
            cData: cData,
            currentDate: currentDate
        });

        return id
    }

    const qkeys = [TAX_INVOICE, UPDATE_ON_INR_ACCOUNT, BALANCE + INQUIRY_STORE_ACCOUNT_ID]

    if (inquiry) {
        qkeys.push(UPDATE_ON_INQUIRY)
    }

    const predicate = (query) =>
        qkeys.some((queryKey) => query.queryKey.includes(queryKey))

    return <NewPurchaseForm
        refranceId={"AUTO"}
        title={TAX_INVOICE}
        itemList={propertyList.ProductDropDown}
        queryFn={queryFn}
        predicate={predicate} />
};

export default NewInvoice;