import React, { useContext } from 'react';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { UPDATE_ON_INR_ACCOUNT, UPDATE_ON_PAYMENT_ACCOUNT } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import GenericDropDown from '../GenericComponents/DropDown/GenericDropDown';

const PayAccountDropDown = ({ getSelected, attributes, currentValue, props }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const queryKey = [
        token,
        khID,
        UPDATE_ON_INR_ACCOUNT,
        UPDATE_ON_PAYMENT_ACCOUNT
    ];

    const queryFunction = async (pageParam) => {
        return await serviceHelpers.getPaymentAccounts(
            token, khID, null, null, pageParam);
    }

    const DisplayComponent = ({ props, option }) => <li {...props} key={option.id}>
        {option.name}
    </li>

    const filterFunction = (current) => current.name

    const propertyList = {
        currentValue: currentValue,
        attributes: attributes,
        queryKey: queryKey,
        queryFunction: queryFunction,
        inputLabel: props.data.item.displayName,
        getSelected: getSelected,
        searchFilter: filterFunction,
        getOptionLabel: (option) => `${option.name}`,
        DisplayComponent: DisplayComponent,
        props: props,
    }

    return (
        <GenericDropDown
            {...propertyList}
        />
    );
};

export default PayAccountDropDown;