import Grid2 from '@mui/material/Unstable_Grid2';
import { ShowNumber } from '../../Helpers/helpers';
import { Alert, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { BALANCE, COMMON_BATCH, INR_RESOURCE_ID, UPDATE_ON_INR_ACCOUNT } from '../../Helpers/ConstantProperties';
import { DateContext } from '../Contexts/DateContext';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { useQuery } from '@tanstack/react-query';
import { GenericErrorComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { ADVANCE_PAYMENT, PARTY_TAG, SchemaTypes } from '../../Helpers/ExtraProperties';
import AddProperty from '../AddProperties/AddProperty';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import StatementAmount from '../Statements/StatementAmount';

const PartyInvoiceDetails = ({ addReceipentToArray, DefaultbatchMulti = 0, useBatch = null }) => {
    const batches = [];

    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const { currentDate } = useContext(DateContext);

    const [recipient, setRecipient] = useState(null);
    const [amount, setAmount] = useState(0);
    const [batchMulti, setBatchMulti] = useState(DefaultbatchMulti);

    let sorterBatches = [];

    const queryFn = async () => {
        if (!recipient) return [];
        if (useBatch) return [];

        const balanceOption = {
            accountID: recipient.id,
            resourceID: INR_RESOURCE_ID,
            date: currentDate,
            withBatches: true,
        }

        return await serviceHelpers.getResourceBalance(token, khID, balanceOption)
    }

    const queryKey = [
        token,
        khID,
        recipient?.id,
        useBatch,
        UPDATE_ON_INR_ACCOUNT,
        BALANCE + INR_RESOURCE_ID
    ]

    const { data, error } = useQuery({
        queryKey: queryKey,
        queryFn: queryFn,
        enabled: (recipient !== null)
    });

    if (error) return <GenericErrorComponent error={error} />

    if (data) {
        let required = parseFloat(amount * batchMulti);

        const balanceBatch = data[0] ? data[0].batches : {}

        sorterBatches =
            Object.keys(balanceBatch)
                .sort()
                .reduce((acc, key) => {
                    acc[key] = balanceBatch[key];
                    return acc;
                }, {});

        const batchKeys = Object.keys(sorterBatches);

        for (const element of batchKeys) {
            const batchValue = sorterBatches[element];

            const batchUnits = parseFloat(batchValue.units);
            const invoiceID = (batchValue.BatchObject?.invoiceID || element)

            const canAllot = 0 > (required ^ batchUnits);
            const alloted = canAllot ? Math.min(Math.abs(required), Math.abs(batchUnits)) : 0;

            batches.push({
                id: element,
                balance: batchUnits,
                units: alloted * batchMulti,
                invoiceID: invoiceID
            });

            required -= (alloted * batchMulti)
        }

        const remainingBatch = recipient?.tag === PARTY_TAG ? ADVANCE_PAYMENT : COMMON_BATCH

        if (Math.abs(required) > 0) {
            batches.push({
                id: useBatch ? useBatch : remainingBatch,
                balance: 0,
                invoiceID: useBatch ? useBatch : remainingBatch,
                units: required,
            })
        }
    }

    const canAdd = amount !== 0;

    const addEntry = () => {
        addReceipentToArray({
            account: { name: recipient.name, tag: recipient.tag, id: recipient.id },
            effect: amount * batchMulti,
            batches: [...batches.filter((batch) => batch.units !== 0)]
        })

        setRecipient(null);
        setAmount(0);
    }

    return (
        <Grid2 container p={1} rowGap={2} alignItems={"center"}>
            <Grid2 xs={12} md={7}>
                <AddProperty
                    data={{
                        item: {
                            displayName: 'Recipient',
                            name: 'documentParty',
                            required: true,
                            type: SchemaTypes.ACCOUNTDROPDOWN,
                            helperText: 'Please Select the Party',
                        }
                    }}
                    deleteField={(element) => { setRecipient(null) }}
                    currentValue={recipient}
                    onChange={(e) => setRecipient(e.value)}
                />
            </Grid2>

            <Grid2 xs={11} md={4}>

                <AddProperty
                    data={{
                        item: {
                            displayName: "Amount",
                            name: "amount",
                            type: SchemaTypes.INRAMOUNT,
                            required: true,
                            helperText: 'Please enter the Amount',
                        },
                    }}
                    deleteField={(element) => { setAmount(0) }}
                    currentValue={amount}
                    onChange={(e) => setAmount(e.value)}
                />

            </Grid2>

            <Grid2 xs={1}>
                <ToggleButtonGroup
                    disabled={DefaultbatchMulti !== 0}
                    color="primary"
                    value={batchMulti}
                    exclusive={true}
                    orientation="vertical"
                    variant="outlined"
                    aria-label="Basic button group">

                    <ToggleButton value={1} onClick={() => setBatchMulti(1)}>
                        <CallMadeIcon fontSize="small" color='error' />
                    </ToggleButton>
                    <ToggleButton value={-1} onClick={() => setBatchMulti(-1)}>
                        <CallReceivedIcon fontSize="small" color='success' />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid2>

            <Grid2 xs={12} paddingX={1}>
                <Grid2 container>
                    {
                        (batches.length > 0) &&
                        batches.map((batch) => {

                            return (
                                <React.Fragment key={batch.id}>
                                    <Grid2 xs={4} textAlign={"left"}>
                                        {batch.invoiceID}
                                    </Grid2>
                                    <Grid2 xs={3} textAlign={"right"}>
                                        {ShowNumber(Math.abs(batch.balance), 2, true)}
                                    </Grid2>
                                    <Grid2 xs={1} textAlign={"right"}>
                                        <StatementAmount txn={{ units: batch.balance }} />
                                    </Grid2>
                                    <Grid2 xs={3} textAlign={"right"}>
                                        {ShowNumber(Math.abs(batch.units || 0), 2, true)}
                                    </Grid2>
                                    <Grid2 xs={1} textAlign={"right"}>
                                        <StatementAmount txn={{ units: (batch.units || 0) }} />
                                    </Grid2>
                                </React.Fragment>
                            )
                        })
                    }

                    {
                        batches.length === 0 &&
                        <Grid2 xs={12} width={"100%"}>
                            <Alert severity='warning'>No Data</Alert>
                        </Grid2>
                    }
                </Grid2>
            </Grid2>

            <Grid2 xs={12} textAlign={"center"}>
                <Button
                    onClick={addEntry}
                    disabled={!canAdd}
                    variant="contained"
                    color="success">
                    {"Add"}
                </Button>
            </Grid2>

        </Grid2>
    )
};

export default PartyInvoiceDetails;