import { useContext, useState } from "react";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { useQuery } from "@tanstack/react-query";
import { CASH_ACCOUNT, RCM_GST_ACCOUNT, ROUND_ACCOUNT, TRANSPORT_ACCOUNT } from "../../Helpers/ConstantProperties";
// import transactions from "../../Data/transactions";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Button } from "@mui/material";
import ShowExcelVouchers from "./ShowExcelVouchers";

const transactions = []

const knownAccount = [
    {
        excel: "Transport Charges",
        system: TRANSPORT_ACCOUNT
    },
    {
        excel: "Transport For Fixed Asset",
        system: TRANSPORT_ACCOUNT
    },
    {
        excel: "Transport For raw material",
        system: TRANSPORT_ACCOUNT
    },
    {
        excel: "RCM Payble",
        system: RCM_GST_ACCOUNT
    },
    {
        excel: "Round-Off",
        system: ROUND_ACCOUNT
    },
    {
        excel: "Cash In Hand",
        system: CASH_ACCOUNT
    }
]

export function ExcelDateToJSDate(date) {
    var retDate = new Date(Math.round((date - 25569) * 86400 * 1000));
    return retDate;
}

const AddClosingBalanceFromExcel = () => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const [current, setCurrent] = useState(0);

    const next = () => setCurrent(current + 1);
    const last = () => setCurrent(current - 1);

    const month = 3;

    const qKey = ["EXCEL"];

    const qFn = async () => await serviceHelpers.getAllLeads(
        token,
        khID,
        null,
        null
    )

    const { data } = useQuery({
        queryKey: qKey,
        queryFn: qFn
    })

    if (data) {
        const saleVoucher = transactions.filter(
            (t) => new Date(ExcelDateToJSDate(t.Date)).getMonth() === month);

        const indiVoucher = Object.groupBy(saleVoucher, (vch) => vch.Date + "-" + vch.VID);

        const vouchers = Object.values(indiVoucher);
        const currentVoucher = vouchers[current];

        if (currentVoucher === undefined) {
            return (
                <Grid2 container paddingTop={3}>
                    <Grid2 xs={6}>
                        <Button onClick={last}>Previous</Button>
                    </Grid2>

                    <Grid2 xs={6}>
                        <Button disabled={true} onClick={next}>Next</Button>
                    </Grid2>
                </Grid2>
            )
        }

        const txnList = currentVoucher.map((txn) => {

            const known = knownAccount.find((knAcc) => knAcc.excel === txn.Account);

            if (known) {
                return {
                    account: known.system,
                    effect: -txn.Effect
                }
            }

            const account = data.find((acc) => acc.name === txn.Account)

            if (account) {
                return {
                    account: account,
                    effect: -txn.Effect
                }
            }

            return {}
        })

        return <ShowExcelVouchers
            currentVoucher={currentVoucher}
            txnList={txnList}
            current={current}
            next={next}
            last={last}
            length={vouchers.length} />
    }

    return <></>

};

export default AddClosingBalanceFromExcel;