import React, { useContext } from 'react';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { CREATE_INV_FRCH, NEWINVOICE, PROFORMA_INVOICE_ID, TAX_INVOICE, UPDATE_ON_INQUIRY, UPDATE_ON_VOUCHER } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import DataView from '../GenericComponents/DataView/DataView';
import InvoiceCard from './invoiceCard';
import DataviewList from '../GenericComponents/DataView/DataviewList';
import { useNavigate } from 'react-router-dom';

const Invoices = ({ inquiry = null }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const navigate = useNavigate();

    const queryKey = [
        token,
        khID,
        UPDATE_ON_VOUCHER,
        UPDATE_ON_INQUIRY,
        TAX_INVOICE
    ];

    if (inquiry) queryKey.push(inquiry.id)

    const queryFunction = async (pageParam) => {
        const results = await serviceHelpers.getInvoices(token, khID, pageParam, inquiry?.id);

        // This will make sure to skip get Inquiry object while
        // showing the card.
        if (inquiry) {
            results.forEach((voucher) => voucher.inquiry = inquiry);

            results.push({
                id: PROFORMA_INVOICE_ID,
                refranceId: PROFORMA_INVOICE_ID,
                inquiry: inquiry
            })
        }

        return results;
    }

    const getSearchableValue = (current) => {
        return (
            current.id + " "
            + current.customerName
        )
    }

    const routeDetails = { heading: `Tax Invoices` }

    const actionList = [
        {
            onButtonClick: () => navigate(NEWINVOICE, { state: routeDetails }),
            buttonText: "New Invoice",
            xs: 1.5
        },
        {
            onButtonClick: () => navigate(CREATE_INV_FRCH, { state: routeDetails }),
            buttonText: "Invoice from Challan",
            xs: 2
        }
    ]

    return (
        <DataView
            routeDetails={routeDetails}
            getSearchableValue={getSearchableValue}
            actionList={actionList}
            queryKeyParameter={queryKey}
            queryFunctionParameter={queryFunction}
            ShowElement={{ Component: InvoiceCard }}
            DisplayComponent={DataviewList}
            searchingPlaceholder={"Search By Id and Customer Name"}
        />
    )
};

export default Invoices;