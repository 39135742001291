import { MenuItem } from '@mui/material';
import React from 'react';
import RCMRateForm from './RCMRateForm';

const AddRCMMenu = ({ item, showDialog }) => {
    const onMenuClick = () => {
        showDialog({
            contents: <RCMRateForm item={item} />,
            title: "Change RCM Rate"
        });
    }

    return (
        <>
            <MenuItem onClick={() => onMenuClick()}>
                {"Change RCM"}
            </MenuItem>
        </>
    );
};

export default AddRCMMenu;