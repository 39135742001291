import React, { useContext } from 'react';
import { FirmContext } from '../../Contexts/FirmContext';
import { AuthContext } from '../../Auth/Auth';
import { INR_RESOURCE_ID, TAX_INVOICE } from '../../../Helpers/ConstantProperties';
import { useQuery } from '@tanstack/react-query';
import { getDateDifferance, ShowNumber } from '../../../Helpers/helpers';
import ChartAndList from './ChartAndList';

const AccountChartAndList = ({ account, query, transform = false }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const listQuery = {
        queryKey: [
            token,
            khID,
            TAX_INVOICE,
            "List" + account
        ],

        queryFn: query
    }

    const result = useQuery(listQuery);

    if (result.data) {
        const chartData = [
            {
                name: "< 1 Week",
                value: 0,
                fill: '#0088FE',
                acc: []
            },
            {
                name: "1 to 2 Weeks",
                value: 0,
                fill: '#00C49F',
                acc: []
            },
            {
                name: "1 Month",
                value: 0,
                fill: '#FFBB28',
                acc: []
            },
            {
                name: "> Month",
                value: 0,
                fill: '#FF8042',
                acc: []
            }
        ]

        result.data.forEach((acc) => {
            const daysSince = getDateDifferance(acc.balanceDate, new Date());
            let index = 0

            if (daysSince < 7) {
                index = 0;
            }
            else if (daysSince < 15) {
                index = 1;
            }
            else if (daysSince < 30) {
                index = 2;
            }
            else {
                index = 3;
            }

            chartData[index].value += Math.abs(acc[INR_RESOURCE_ID]);
            chartData[index].acc.push({
                name: acc.name,
                value: ShowNumber(Math.abs(acc[INR_RESOURCE_ID]), 2, true)
            });
        })

        return <ChartAndList
            chartData={chartData}
            transform={transform}
            formatText={(text) => ShowNumber(text, 2, true)}
        />
    }

    return <></>
};

export default AccountChartAndList;