import { Alert } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import BOMItemTable from './BOMItemTable';
import { grayBorder, PRODUCT_TYPE_PROCESS, PRODUCT_TYPE_RAW, PRODUCT_TYPE_SEMIFINISHED } from '../../Helpers/ConstantProperties';
import { getTypeWiseBOM } from './ProductHelper';

const ProductBOMList = ({ item, removeEntry = null }) => {
    const bom = item.bom;

    if (!bom || bom.length === 0) {
        return <Alert color='warning'>No Data</Alert>
    }

    const types = getTypeWiseBOM(item);

    return (
        <Grid2 container rowGap={2}>
            <Grid2 xs={12} md={4} borderRight={grayBorder}>
                <BOMItemTable
                    removeEntry={removeEntry}
                    productType={PRODUCT_TYPE_SEMIFINISHED}
                    bomItems={types[PRODUCT_TYPE_SEMIFINISHED]} />
            </Grid2>

            <Grid2 xs={12} md={4} borderRight={grayBorder}>
                <BOMItemTable
                    removeEntry={removeEntry}
                    productType={PRODUCT_TYPE_RAW}
                    bomItems={types[PRODUCT_TYPE_RAW]} />
            </Grid2>

            <Grid2 xs={12} md={4}>
                <BOMItemTable
                    removeEntry={removeEntry}
                    productType={PRODUCT_TYPE_PROCESS}
                    bomItems={types[PRODUCT_TYPE_PROCESS]} />
            </Grid2>

        </Grid2>
    )
};

export default ProductBOMList;