import { createContext, useContext } from "react";
import { FirmContext } from "./FirmContext";
import { DEFAULT_FORMAT } from "../../Helpers/ConstantProperties";
import { newFormat } from "../Quotation/NewFormat/NewFormatData";
import { defaultFormat } from "../Quotation/DefaultFormat/FormatData";

export const DocumentFormatContext = createContext({});

export default function DocumentFormatContextProvider({ children }) {

    const { currentFirm } = useContext(FirmContext);
    const dropDownValue = currentFirm.useFormat;

    const getSelectedFormat = () => {
        switch (dropDownValue) {
            case DEFAULT_FORMAT:
                return defaultFormat;
            default:
                return newFormat;
        }
    }

    return (
        <DocumentFormatContext.Provider value={{ selectedFormat: getSelectedFormat() }}>
            {children}
        </DocumentFormatContext.Provider>
    );
}