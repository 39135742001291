import React, { useContext } from 'react';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { NEWPURCHASE, PURCHASE_VOUCHER, UPDATE_ON_VOUCHER } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import DataviewList from '../GenericComponents/DataView/DataviewList';
import DataView from '../GenericComponents/DataView/DataView';
import PurchaseCard from './PurchaseCard';

const Purchases = () => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const queryKey = [
        token,
        khID,
        UPDATE_ON_VOUCHER,
        PURCHASE_VOUCHER
    ];

    const queryFunction = async (pageParam) => {
        const results = await serviceHelpers.getPurchases(token, khID, pageParam);
        return results;
    }

    const getSearchableValue = (current) => {
        return (
            current.id + " "
            + current.vendorName
        )
    }

    return (
        <DataView
            routeDetails={{ heading: `Purchases` }}
            getSearchableValue={getSearchableValue}
            buttonDetails={{ text: "New Purchase", navigateTo: NEWPURCHASE }}
            queryKeyParameter={queryKey}
            queryFunctionParameter={queryFunction}
            ShowElement={{ Component: PurchaseCard }}
            DisplayComponent={DataviewList}
            searchingPlaceholder={"Search By Id and Vendor Name"}
        />
    )
};

export default Purchases;