import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import GenericDialogWithButton from "../GenericComponents/Dialog/GenericDialogWithButton";
import InquiryStockDetails from "../Stock/InquiryStockDetails";
import AssignGoods from "../AssignFinishGood/AssignGoods";
import InquiryStockProduction from "../Stock/InquiryStockProduction";

export default function InquiryStockCard({ inquiry, balanceDetails }) {
    const canAllotBalance =
        balanceDetails.some((product) => product.allotable > 0);

    const canAllotAll =
        balanceDetails.every((product) => product.allotable === product.remaining);

    const canAllotRM =
        balanceDetails.some((product) => product.prodPossible > 0);

    return (
        <>
            <Grid2 xs={12} md={2}>
                <Box width={"90%"}>
                    <GenericDialogWithButton
                        buttonProps={{ variant: "outlined", fullWidth: true }}
                        buttonText={"Balance Details"}
                        dialogTitle={"Balance Details"}
                        dialogContents={
                            <InquiryStockDetails balanceDetails={balanceDetails} />}
                    />
                </Box>
            </Grid2>
            <Grid2 xs={12} md={2}>
                <Box width={"90%"}>
                    <GenericDialogWithButton
                        buttonProps={{
                            disabled: !canAllotBalance,
                            variant: "outlined",
                            fullWidth: true
                        }}
                        buttonText={"Assign Goods"}
                        dialogTitle={"Assign Goods"}
                        dialogContents={
                            <AssignGoods
                                canAllotAll={canAllotAll}
                                inquiryID={inquiry.id}
                                balanceDetails={balanceDetails} />} />
                </Box>
            </Grid2>
            <Grid2 xs={12} md={2}>
                <Box width={"90%"}>
                    <GenericDialogWithButton
                        buttonProps={{
                            disabled: !canAllotRM,
                            variant: "outlined",
                            fullWidth: true
                        }}
                        buttonText={"Production Plan"}
                        dialogTitle={"Production Plan"}
                        dialogContents={
                            <InquiryStockProduction
                                balanceDetails={balanceDetails} inquiryID={inquiry.id} />} />
                </Box>
            </Grid2>
        </>
    )
}