import { Box, Typography } from "@mui/material";
import { getLocalDateString, getPlaceOfSupply, getRoundUptoTwoPlaces, ShowNumber } from "../../Helpers/helpers";
import { getRateWiseObject } from "./gstHelper";
import { commonFontWeight, largeFontSize, PRIMARY_COLOR } from "../../Helpers/ConstantProperties";
import Grid2 from "@mui/material/Unstable_Grid2";

function formatDateToGSTR1(dateNumber) {
    var date = new Date(dateNumber);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

export default function GstrOneBToB({ data, gstrJson }) {
    let totalTaxable = 0;

    return (
        <Grid2 container padding={1} rowGap={0.5}>
            <Grid2 xs={12} textAlign={"left"} paddingLeft={2}>
                <Typography fontSize={largeFontSize} color={PRIMARY_COLOR}>
                    B2B Invoices
                </Typography>
            </Grid2>

            <Grid2 xs={12}
                border={"1px solid lightgray"}
                fontWeight={commonFontWeight}>
                <Box>
                    <Grid2 container paddingY={0.5} className="bg-primary-subtle">
                        <Grid2 xs={12} md={2}>GSTIN</Grid2>
                        <Grid2 xs={12} md={3}>Name</Grid2>
                        <Grid2 xs={12} md={1}>ID</Grid2>
                        <Grid2 xs={12} md={1}>Date</Grid2>
                        <Grid2 xs={12} md={2}>Total</Grid2>
                        <Grid2 xs={12} md={0.5}>Place</Grid2>
                        <Grid2 xs={12} md={0.5}>Rate</Grid2>
                        <Grid2 xs={12} md={2}>Taxable</Grid2>
                    </Grid2>
                </Box>

            </Grid2>

            {
                data.map((txn, index) => {
                    totalTaxable += txn.taxable;

                    const invoiceValue = txn.invoiceTotal;

                    const rateWiseList = txn.rateWiseList
                        ? txn.rateWiseList
                        : getRateWiseObject(txn.hsnWiseList)

                    const inv =
                    {
                        "inum": txn.refranceId,
                        "idt": formatDateToGSTR1(txn.date),
                        "val": invoiceValue,
                        "pos": getPlaceOfSupply(txn.gstin),
                        "rchrg": "N",
                        "inv_typ": "R",
                        "itms": []
                    }

                    const invIndex = gstrJson.b2b.findIndex((entry) => entry.ctin === txn.gstin);

                    if (-1 === invIndex) {
                        gstrJson.b2b.push({
                            ctin: txn.gstin,
                            inv: [inv]
                        })
                    }
                    else {
                        gstrJson.b2b[invIndex].inv.push(inv);
                    }

                    return Object.values(rateWiseList).map((rateObject) => {

                        const itmObject = {
                            "num": parseInt(rateObject.GSTRate.toString() + "01"),
                            "itm_det": {
                                "txval": getRoundUptoTwoPlaces(rateObject.taxable),
                                "rt": rateObject.GSTRate,
                            }
                        }

                        if (rateObject.IGST > 0) {
                            itmObject.itm_det.iamt = rateObject.IGST;
                        }

                        if (rateObject.CGST > 0) {
                            itmObject.itm_det.camt = rateObject.CGST;
                        }

                        if (rateObject.SGST > 0) {
                            itmObject.itm_det.samt = rateObject.SGST;
                        }

                        itmObject.itm_det.csamt = 0

                        inv.itms.push(itmObject);

                        const border = "1px solid lightgray"

                        return (
                            <Grid2 xs={12} key={index}>
                                <Box>
                                    <Grid2 container borderBottom={border}>
                                        <Grid2
                                            xs={12} md={2}
                                            borderRight={border}
                                            borderLeft={border}>
                                            {txn.gstin}
                                        </Grid2>

                                        <Grid2 xs={12} md={3} borderRight={border}>
                                            {txn.partyName}
                                        </Grid2>

                                        <Grid2 xs={12} md={1} borderRight={border}>
                                            {txn.refranceId}
                                        </Grid2>

                                        <Grid2 xs={12} md={1} borderRight={border}>
                                            {getLocalDateString(txn.date)}
                                        </Grid2>

                                        <Grid2
                                            xs={12} md={2}
                                            borderRight={border}
                                            textAlign={"right"}
                                            paddingRight={1}>

                                            {ShowNumber(invoiceValue, 2, true)}
                                        </Grid2>

                                        <Grid2 xs={12} md={0.5} borderRight={border}>
                                            {getPlaceOfSupply(txn.gstin)}
                                        </Grid2>

                                        <Grid2 xs={12} md={0.5} borderRight={border}>
                                            {rateObject.GSTRate}
                                        </Grid2>

                                        <Grid2
                                            xs={12} md={2}
                                            borderRight={border}
                                            textAlign={"right"}
                                            paddingRight={1}>

                                            {ShowNumber(txn.taxable, 2, true)}
                                        </Grid2>
                                    </Grid2>
                                </Box>
                            </Grid2>
                        )
                    })
                })
            }

            <Grid2 xs={12}
                border={"1px solid lightgray"}
                fontWeight={commonFontWeight}>
                <Box>
                    <Grid2 container paddingY={0.5} className="bg-secondary-subtle">
                        <Grid2 xs={12} md={10}>Total</Grid2>
                        <Grid2
                            xs={12} md={2}
                            textAlign={"right"}
                            paddingRight={1}>

                            {ShowNumber(totalTaxable, 2, true)}
                        </Grid2>
                    </Grid2>
                </Box>

            </Grid2>

        </Grid2>
    )
}