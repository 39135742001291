import Grid2 from '@mui/material/Unstable_Grid2';
import { DELETE_FIELD, TDS_SECTIONS, UPDATE_ON_PARTY, UPDATE_ON_PRODUCT } from '../../Helpers/ConstantProperties';
import GenericMutateButton from '../GenericComponents/Buttons/GenericMutateButton';
import StaticDropDown from '../GenericComponents/DropDown/StaticDropDown';
import { useContext, useState } from 'react';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { FirmContext } from '../Contexts/FirmContext';
import { AuthContext } from '../Auth/Auth';
import { useQueryClient } from '@tanstack/react-query';
import { PARTY_TAG, PRODUCT_TAG } from '../../Helpers/ExtraProperties';

const TDSForm = ({ item }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const [tdsInfo, setTdsInfo] = useState(item.tdsSection);

    const queryFn = async () => {
        item.tdsSection = tdsInfo ? tdsInfo : DELETE_FIELD;

        if (item.tag === PRODUCT_TAG)
            return await serviceHelpers.updateProduct(token, khID, item, item.id);

        if (item.tag === PARTY_TAG)
            return await serviceHelpers.updateParty(token, khID, item, item.id);

        return true;
    }

    const queryClient = useQueryClient();

    const onSuccess = () => {
        const pathToUpdate = item.tag === PRODUCT_TAG ? UPDATE_ON_PRODUCT : UPDATE_ON_PARTY;

        queryClient.invalidateQueries({
            predicate: (query) => query.queryKey.includes(pathToUpdate),
        })

        return true;
    }

    return (
        <Grid2 container paddingTop={2} rowGap={2} >

            <Grid2 xs={12}>
                <StaticDropDown
                    getOptionLabel={(option) => (option.section + " - " + option.rate + "%")}
                    currentValue={tdsInfo}
                    props={{
                        data: {
                            item: {
                                displayName: "TDS Section",
                                name: "tdsSection",
                                required: true,
                                dropDownList: TDS_SECTIONS
                            }
                        },
                        onChange: (value) => setTdsInfo(value.value),
                    }}
                    isOptionEqualToValue={(option, value) => option.section === value.section}
                />
            </Grid2>

            <Grid2 xs={12} textAlign={"right"}>
                <GenericMutateButton
                    buttonText={"Save"}
                    disable={false}
                    onSuccess={onSuccess}
                    queryFn={queryFn}
                    successMessage={"TDS infomation changed."} />
            </Grid2>
        </Grid2>
    )
};

export default TDSForm;