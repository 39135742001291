import { useContext } from 'react';
import { AuthContext } from '../../Auth/Auth';
import { FirmContext } from '../../Contexts/FirmContext';
import { BALANCE, DEMI_INR_RESOURCE_ID, TOTAL_EXPENSES_ID, TOTAL_PURCHASE_ID, TOTAL_SALE_ID, UPDATE_ON_VOUCHER } from '../../../Helpers/ConstantProperties';
import { useQueries } from '@tanstack/react-query';
import { serviceHelpers } from '../../../Helpers/ServiceHelpers';
import { ShowNumber } from '../../../Helpers/helpers';
import ChartAndList from './ChartAndList';

const WorkingList = ({ transform }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const getQueryObject = (account) => {
        return {
            queryKey: [
                token,
                khID,
                UPDATE_ON_VOUCHER,
                account + BALANCE
            ],

            queryFn: async () => {
                const balanceOption = {
                    accountID: account,
                    resourceID: DEMI_INR_RESOURCE_ID,
                    date: Date.now(),
                }

                return await serviceHelpers.getResourceBalance(token, khID, balanceOption)
            }
        }
    }

    const saleQuery = getQueryObject(TOTAL_SALE_ID);
    const purchaseQuery = getQueryObject(TOTAL_PURCHASE_ID);
    const expenseQuery = getQueryObject(TOTAL_EXPENSES_ID);

    const results = useQueries({ queries: [saleQuery, purchaseQuery, expenseQuery] });

    if (results.every((res) => res.isSuccess)) {

        const accList = [
            {
                name: "Total Sale",
                value: ShowNumber((Math.abs(results[0].data[0]?.units) || 0), 2, true)
            },
            {
                name: "Total Purchase",
                value: ShowNumber((Math.abs(results[1].data[0]?.units) || 0), 2, true)
            },
            {
                name: "Total Expenses",
                value: ShowNumber((Math.abs(results[2].data[0]?.units) || 0), 2, true)
            }
        ]

        const chartData = [
            {
                name: "Sale",
                value: Math.abs(results[0].data[0]?.units) || 0,
                fill: '#198754',
                acc: accList
            },

            {
                name: "Purchase",
                value: Math.abs(results[1].data[0]?.units) || 0,
                fill: '#dc3545',
                acc: accList
            },

            {
                name: "Expenses",
                value: Math.abs(results[2].data[0]?.units) || 0,
                fill: '#0dcaf0',
                acc: accList
            }
        ]

        return <ChartAndList
            chartData={chartData}
            transform={transform}
            formatText={(text) => ShowNumber(text, 2, true)}
        />
    }

    return <></>
};

export default WorkingList;