export const getRateWiseObject = (hsnWiseList, isIGST) => {
    const rateWiseList = {};

    Object.values(hsnWiseList).forEach((hsnEntry) => {
        if (rateWiseList[hsnEntry.GSTRate]) {

            rateWiseList[hsnEntry.GSTRate].taxable += hsnEntry.taxable;
            if (isIGST) {
                rateWiseList[hsnEntry.GSTRate].IGST += hsnEntry.tax;
            }
            else {
                const halfTax = hsnEntry.tax / 2;
                rateWiseList[hsnEntry.GSTRate].CGST += halfTax;
                rateWiseList[hsnEntry.GSTRate].SGST += halfTax;
            }
        }
        else {
            rateWiseList[hsnEntry.GSTRate] = {
                GSTRate: hsnEntry.GSTRate,
                taxable: hsnEntry.taxable,
                IGST: isIGST ? hsnEntry.tax : 0,
                CGST: isIGST ? 0 : (hsnEntry.tax / 2),
                SGST: isIGST ? 0 : (hsnEntry.tax / 2)
            }
        }
    })

    return rateWiseList;
}