import React, { useContext } from 'react';
import { FirmContext } from '../Contexts/FirmContext';
import { BALANCE, INQUIRY_STORE_ACCOUNT_ID, OUTWORD_CHALLAN } from '../../Helpers/ConstantProperties';
import NewPurchaseForm from '../Purchase/NewPurchseForm';
import { propertyList } from '../../Helpers/ExtraProperties';
import { AuthContext } from '../Auth/Auth';
import { createTaxInvoiceVoucher } from '../TaxInvoice/VoucherHelper';

const NewOutChallan = () => {
    const { token } = useContext(AuthContext);
    const { khID, currentFirm } = useContext(FirmContext);

    const queryFn = async ({
        data,
        party,
        cData,
        selectedDate
    }) => {
        const { id } = await createTaxInvoiceVoucher({
            token: token,
            khID: khID,
            prefix: currentFirm.prefixes.challan,
            data: data,
            party: party,
            cData: cData,
            currentDate: selectedDate,
            vType: OUTWORD_CHALLAN
        });

        return id
    }

    const qkeys = [OUTWORD_CHALLAN, (BALANCE + INQUIRY_STORE_ACCOUNT_ID)]

    const predicate = (query) =>
        qkeys.some((queryKey) => query.queryKey.includes(queryKey))

    return <NewPurchaseForm
        refranceId={"AUTO"}
        title={OUTWORD_CHALLAN}
        itemList={propertyList.purchaseProductList}
        queryFn={queryFn}
        predicate={predicate} />
};

export default NewOutChallan;