import React, { useContext } from 'react';
import GenericDropDown from '../GenericComponents/DropDown/GenericDropDown';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { UPDATE_ON_INR_ACCOUNT } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';

const AccountDropDown = ({ getSelected, attributes, currentValue, props }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const queryKey = [
        token,
        khID,
        UPDATE_ON_INR_ACCOUNT,
    ];

    const queryFunction =
        async (pageParam) => serviceHelpers.getAllAccounts(token, khID, pageParam);

    const DisplayComponent = ({ props, option }) => <li {...props} key={option.id}>
        {option.name}
    </li>

    const filterFunction = (current) => current.name

    const propertyList = {
        currentValue: currentValue,
        attributes: attributes,
        queryKey: queryKey,
        queryFunction: queryFunction,
        inputLabel: props.data.item.displayName,
        getSelected: getSelected,
        searchFilter: filterFunction,
        getOptionLabel: (option) => `${option.name}`,
        DisplayComponent: DisplayComponent,
        props: props,
    }

    if (props.data.item.freeSolo) {
        propertyList.freeSolo = true;
        propertyList.setInputValue = getSelected
    }

    return (
        <GenericDropDown
            {...propertyList}
        />
    );
};

export default AccountDropDown;