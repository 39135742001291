import { Box, IconButton } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { ShowNumber } from '../../Helpers/helpers';
import DeleteIcon from '@mui/icons-material/Delete';
import { commonFontSize } from '../../Helpers/ConstantProperties';

const TDSEntryTable = ({ taxData, removeEntry = null }) => {
    if (taxData.length === 0) return <></>

    return (
        <Grid2
            margin={2}
            container
            rowGap={2}>
            {
                taxData.map((tds, index) => {
                    const tax = tds.taxable * tds.rate / 100;

                    return (
                        <Grid2 key={index} xs={12} fontSize={commonFontSize}>
                            <Box>
                                <Grid2 container border={"1px, solid, lightgray"} padding={1}>
                                    <Grid2 xs={2}>
                                        Section: {tds.section.section}
                                    </Grid2>

                                    <Grid2 xs={4.5} textAlign={"center"}>
                                        {
                                            tds.products.map((product, index) => (
                                                <Box key={index}>
                                                    {product}
                                                </Box>
                                            ))
                                        }
                                    </Grid2>

                                    <Grid2 xs={2}>
                                        Taxable: {ShowNumber(tds.taxable, 2, true)}
                                    </Grid2>

                                    <Grid2 xs={1}>
                                        Rate: {tds.rate}%
                                    </Grid2>

                                    <Grid2 xs={2}>
                                        Tax: {ShowNumber(tax, 2, true)}
                                    </Grid2>

                                    {
                                        removeEntry &&
                                        <Grid2 md={0.5}>

                                            <IconButton
                                                onClick={() => removeEntry((entry) =>
                                                    entry.section.section === tds.section.section)}>

                                                <DeleteIcon color='error' />
                                            </IconButton>
                                        </Grid2>
                                    }
                                </Grid2>
                            </Box>
                        </Grid2>
                    )
                })
            }
        </Grid2>
    )
};

export default TDSEntryTable;