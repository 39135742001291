import React from 'react';
import { useLocation } from 'react-router-dom';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { GenericErrorComponent, GenericSuccessComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { CHARTOF_ACC, UPDATE_ON_INR_ACCOUNT } from '../../Helpers/ConstantProperties';
import { InrAccountList } from '../../Helpers/ExtraProperties';
import { GenericEditForm } from '../GenericComponents/FormComponent/GenericEditForm';
import { getAccountGroupDD } from './ExpenseAccCard';
import { handleAccountPostDispatch } from '../../Helpers/helpers';

const EditExpenseAccount = ({ account, onSave }) => {
    const location = useLocation();
    const successMessage = "Account SuccessFully Edited "

    const editUserRoleQueryFunction = async (state, token, khid) => {
        state.tag = state.accountGroup.value;

        delete state.accountGroup;
        delete state.account;

        return await serviceHelpers.editINRAccount(token, khid, state, location.state.id)
    }

    if (location.state) {
        location.state.accountGroup = getAccountGroupDD(location.state.tag);
        location.state.account = { name: location.state.name, value: null }
    }

    const EditProps = {
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        navigateTo: CHARTOF_ACC,
        queryKeyValue: UPDATE_ON_INR_ACCOUNT,
        formTitle: "Edit Account",
        propertyList: InrAccountList,
        currentData: location.state,
        queryFunction: editUserRoleQueryFunction,
        afterDispatch: handleAccountPostDispatch
    }

    return <GenericEditForm
        {...EditProps}
    />
};

export default EditExpenseAccount;