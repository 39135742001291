import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

const GroupedMenu = ({ levelID, levelMenu, theme, currentPath, defaultOpen = false }) => {

    const [open, setOpen] = React.useState(defaultOpen);

    const handleClickO = () => {
        setOpen(!open);
    };

    const handleClick = (e, disabledPath) => {
        if (disabledPath) e.preventDefault()
    }

    return (
        <>
            <ListItemButton sx={{ color: theme.palette.text.navLink }} onClick={handleClickO}>

                <ListItemText primary={levelID} />
                {open ? <ExpandLess /> : <ExpandMore />}

            </ListItemButton>

            <Collapse in={open} timeout="auto" unmountOnExit>

                {levelMenu.map((item, index) => {
                    const isMenuSelected = item.path === currentPath;
                    const disabledPath = !item.path

                    const backgroundColor = isMenuSelected
                        ? theme.palette.background.selectedListItem
                        : theme.palette.background.listItem;

                    const textColor = isMenuSelected
                        ? theme.palette.text.selectedNavLink
                        : theme.palette.text.navLink;

                    return (
                        <ListItem
                            key={index}
                            disablePadding
                            className={`p-0 mt-1 mb-1 rounded-start ${isMenuSelected ? 'bg-white text-primary' : ' '}`}
                            sx={{
                                background: backgroundColor,
                                opacity: disabledPath ? 0.5 : 1,
                                pointerEvents: disabledPath ? 'none' : 'auto',
                            }}
                        >
                            <NavLink
                                onClick={(e) => handleClick(e, disabledPath)}
                                to={item.path}
                                className="text-decoration-none text-center w-100 p-2 d-block"
                                style={{
                                    color: textColor,
                                    textDecoration: 'none',
                                }}
                            >
                                <ListItemText primary={item.text} />
                            </NavLink>
                        </ListItem>
                    );
                })}
            </Collapse>
        </>
    );
};

export default GroupedMenu;