import React, { useContext, useState } from 'react';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { BALANCE, CONVERTED, DELETE_FIELD, INQUIRY_STORE_ACCOUNT_ID, NOT_AVAILABLE, OUTWORD_CHALLAN, TAX_INVOICE, UPDATE_ON_INQUIRY } from '../../Helpers/ConstantProperties';
import { INQUIRY_TAG, SchemaTypes } from '../../Helpers/ExtraProperties';
import { addDaysToToday } from '../../Helpers/helpers';
import { useQueryClient } from '@tanstack/react-query';
import Grid2 from '@mui/material/Unstable_Grid2';
import AddProperty from '../AddProperties/AddProperty';
import GenericMutateButton from '../GenericComponents/Buttons/GenericMutateButton';

const DeleteInvoice = ({ item }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const queryClient = useQueryClient();

    const [reason, setReason] = useState("");

    const canSubmit = reason !== "";

    const queryFunction = async () => {


        if (item.inquiryId) {
            const update = {
                followUpDate: addDaysToToday(2),
                status: CONVERTED,
                tag: INQUIRY_TAG,
                readyToDispatch: true,
            }

            const note = "Invoice " + item.refranceId + " deleted, items returned to store."

            await serviceHelpers.updateLeadStatus(token, khID, update, item.inquiryId, note);
        }

        // Find all the challans with the invoiceID and patch them
        const options = {
            type: OUTWORD_CHALLAN,
            invoiceID: item.refranceId
        }

        const challans = await serviceHelpers.getCustomChallans(token, khID, options);

        if (challans) {
            const voucherUpdate = {
                invoiceID: NOT_AVAILABLE,
                invoiceDate: DELETE_FIELD,
            }

            await Promise.all(
                challans.map(async (challan) => {
                    await serviceHelpers.patchVoucher(token, khID, voucherUpdate, challan.id)
                })
            )
        }

        return await serviceHelpers.deleteInvoices(token, khID, item.id, reason);
    }

    const qkeys = [TAX_INVOICE, (BALANCE + INQUIRY_STORE_ACCOUNT_ID), OUTWORD_CHALLAN]

    if (item.inquiryId) {
        qkeys.push(UPDATE_ON_INQUIRY);
    }

    const predicate = (query) =>
        qkeys.some((queryKey) => query.queryKey.includes(queryKey))

    const onSuccess = async () => {
        await queryClient.invalidateQueries({
            predicate: predicate
        })
    }

    return (
        <Grid2 container>
            <Grid2 xs={12}>
                <AddProperty
                    data={{
                        item: {
                            displayName: 'Reason For Deletion',
                            name: 'reason',
                            type: SchemaTypes.String,
                            required: true,
                            helperText: 'Please enter the reason',
                        },
                    }}
                    deleteField={(element) => { setReason("") }}
                    currentValue={reason}
                    onChange={(e) => setReason(e.value)}
                />
            </Grid2>

            <Grid2 xs={12} textAlign={"center"} paddingY={2}>
                <GenericMutateButton
                    queryFn={queryFunction}
                    disable={!canSubmit}
                    onSuccess={onSuccess}
                    successMessage="Invoice Deleted" />
            </Grid2>
        </Grid2>
    )
};

export default DeleteInvoice;