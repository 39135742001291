import { Box, Paper } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import RejectedInquiryPieChart from "./ReasonWise/RejectedInquiryPieChart";
import SourcewisePieChart from "./Sourcewise/SourcewisePieChart";
import TotalReceivable from "./INR/TotalReceivable";
import TotalPayable from "./INR/TotalPayable";
import TotalWorking from "./INR/TotalWorking";

function Reports() {
    return (
        <Box padding={2}>
            <Grid2 container columnSpacing={2} rowSpacing={2}>
                <Grid2 xs={12} md={6}>
                    <Paper elevation={2}>
                        <TotalReceivable transform={true} />
                    </Paper>
                </Grid2>

                <Grid2 xs={12} md={6}>
                    <Paper elevation={2}>
                        <TotalPayable transform={false} />
                    </Paper>
                </Grid2>

                <Grid2 xs={12} md={6} flexGrow={1}>
                    <Paper elevation={2}>
                        <SourcewisePieChart transform={true} />
                    </Paper>
                </Grid2>

                <Grid2 xs={12} md={6}>
                    <Paper elevation={2}>
                        <RejectedInquiryPieChart transform={false} />
                    </Paper>
                </Grid2>

                <Grid2 xs={12} md={6}>
                    <Paper elevation={2}>
                        <TotalWorking transform={true} />
                    </Paper>
                </Grid2>

                {/*
                <Grid2 xs={12} md={5} flexGrow={1}>
                    {<InquiryWiseReport />}
                </Grid2>

                <Grid2 xs={12} flexGrow={1}>
                    <DetailedReport />
                </Grid2> */}
            </Grid2>
        </Box>
    );
}

export default Reports;