import React, { useContext } from 'react';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { NEWEXPPUR, UPDATE_ON_JV_PURCHASE, UPDATE_ON_VOUCHER } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import DataviewList from '../GenericComponents/DataView/DataviewList';
import DataView from '../GenericComponents/DataView/DataView';
import ExpenseCard from '../Purchase/ExpenseCard';

const Expenses = () => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const queryKey = [
        token,
        khID,
        UPDATE_ON_VOUCHER,
        UPDATE_ON_JV_PURCHASE
    ];

    const queryFunction = async (pageParam) => {
        const results = await serviceHelpers.getJVPurchses(token, khID, pageParam);
        return results;
    }

    const getSearchableValue = (current) => {
        return (
            current.id + " "
            + current.vendorName
        )
    }

    return (
        <DataView
            routeDetails={{ heading: `Expenses` }}
            getSearchableValue={getSearchableValue}
            buttonDetails={{ text: "Post Expense", navigateTo: NEWEXPPUR }}
            queryKeyParameter={queryKey}
            queryFunctionParameter={queryFunction}
            ShowElement={{ Component: ExpenseCard }}
            DisplayComponent={DataviewList}
            searchingPlaceholder={"Search By Id and Vendor Name"}
        />
    )
};

export default Expenses;