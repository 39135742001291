import React from 'react';
import ProductDropDown from './ProductDropDown';

const SFGDropDown = ({ props }) => {
    return <ProductDropDown
        props={props}
        currentValue={props.currentValue}
        attributes={props.data.attributes}
        getSelected={(selectedItem) => {
            props.onChange({
                name: props.data.item.name,
                value: selectedItem,
            })
        }}
    />
};

export default SFGDropDown;