import React from 'react';
import { MenuItem } from '@mui/material';
import NewInvoiceReceipt from './NewInvoiceReceipt';
import { NEWRECEIPTS } from '../../Helpers/ConstantProperties';

const PostReceipt = ({ item, showDialog }) => {
    const onMenuClick = () => {
        showDialog({
            contents: <NewInvoiceReceipt
                document={item}
                action={"Receipt"}
                formLocation={NEWRECEIPTS}
                multiplier={-1} />,
            title: "Receipt for " + item.refranceId
        });
    }

    return (
        <>
            <MenuItem onClick={() => onMenuClick()}>
                {"Add Receipt"}
            </MenuItem>
        </>
    );
};

export default PostReceipt;