import { Box, Divider, IconButton } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { ShowNumber } from '../../Helpers/helpers';
import DeleteIcon from '@mui/icons-material/Delete';
import StatementAmount from '../Statements/StatementAmount';

const AccountAmt = ({ cData, removeEntry }) => {
    if (!cData.accList) return <></>
    if (cData.accList.length === 0) return <></>

    let total = 0;

    return (
        <Grid2 container paddingX={1}>

            {
                cData.accList?.map((txn) => {

                    total += parseFloat(txn.effect);

                    return (
                        <Grid2 key={txn.account.id} xs={12}>
                            <Box>
                                <Grid2 container alignItems={"center"}>

                                    {
                                        removeEntry &&
                                        <Grid2 md={2}>

                                            <IconButton
                                                onClick={() => removeEntry((entry) =>
                                                    entry.account.id === txn.account.id)}>

                                                <DeleteIcon color='error' />

                                            </IconButton>
                                        </Grid2>
                                    }
                                    <Grid2 xs={6}>
                                        {txn.account.name}
                                    </Grid2>

                                    <Grid2 xs={3} textAlign={"right"} paddingRight={1}>
                                        {ShowNumber(Math.abs(txn.effect), 2, true)}
                                    </Grid2>

                                    <Grid2 xs={1} textAlign={"right"} paddingRight={1}>
                                        <StatementAmount txn={{ units: txn.effect }} />
                                    </Grid2>
                                </Grid2>
                            </Box>

                        </Grid2>
                    )
                })
            }

            <Grid2 xs={12} paddingY={1}>
                <Divider sx={{ borderBottom: "3px solid lightgray" }} />
            </Grid2>

            <Grid2 xs={12}>
                <Box>
                    <Grid2 container>
                        <Grid2 xs={6} textAlign={"center"}>
                            {"Total"}
                        </Grid2>

                        <Grid2 xs={5} textAlign={"right"} paddingRight={1}>
                            {ShowNumber(Math.abs(total), 2, true)}
                        </Grid2>
                    </Grid2>
                </Box>

            </Grid2>
        </Grid2>
    )
};

export default AccountAmt;