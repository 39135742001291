import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useState } from 'react';
import AddProperty from '../AddProperties/AddProperty';
import { PARTY_TAG, SchemaTypes } from '../../Helpers/ExtraProperties';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NewInvoiceReceipt = ({ document, action, formLocation, multiplier }) => {
    const [amount, setAmount] = useState(document.cData?.roundedTotal || 0);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(
            formLocation,
            {
                state: {
                    notes: action + " for " + document.refranceId,
                    initState: [{
                        account: {
                            name: (document.customerName || document.vendorName),
                            tag: PARTY_TAG,
                            id: (document.customerId || document.vendorId)
                        },
                        effect: amount * multiplier,
                        batches: [{ id: document.inrBatch.toString(), units: amount * multiplier }]
                    }]
                }
            });
    }

    return (
        <Grid2 container>
            <Grid2 xs={12}>

                <AddProperty
                    data={{
                        item: {
                            displayName: "Amount",
                            name: "amount",
                            type: SchemaTypes.INRAMOUNT,
                            required: true,
                            helperText: 'Please enter the Amount',
                        },
                    }}
                    deleteField={(element) => { setAmount(0) }}
                    currentValue={amount}
                    onChange={(e) => setAmount(e.value)}
                />

            </Grid2>

            <Grid2 xs={12} textAlign={"center"} alignSelf={"center"}>
                <Button
                    disabled={amount === 0}
                    variant='outlined'
                    onClick={handleClick}>
                    Save
                </Button>
            </Grid2>
        </Grid2>
    );
};

export default NewInvoiceReceipt;