import React from 'react';
import { getLocalDateString, ShowNumber } from '../../Helpers/helpers';
import { Box, Typography } from '@mui/material';
import { commonFontSize, commonFontWeight, extraLargeFontSize, largeFontSize, PRIMARY_COLOR, PURCHASE_VOUCHER, regularFontSize } from '../../Helpers/ConstantProperties';
import GenericFormHeader from '../GenericComponents/FormComponent/GenericFormHeader';
import Grid2 from '@mui/material/Unstable_Grid2';
import StatementAmount from './StatementAmount';
import { PAYMENT_ADJUSTMENT } from '../../Helpers/ExtraProperties';

export const getSubText = (txn) => {
    if (txn.type === PURCHASE_VOUCHER || txn.jvPurchase) {
        return "VID: " + txn.vid + " " + (txn.notes || "")
    }

    if (txn.type === PAYMENT_ADJUSTMENT) {
        return txn.notes
    }

    return (txn.notes || "") + " " + (txn.refranceId || "")
}

const StatementTable = ({ result }) => {
    const rightBorder = "1px solid lightgray";
    const cData = result.statements;

    const header =
        "Statement for " +
        result.state.account.name +
        " (" +
        getLocalDateString(result.state.fromDate) +
        " - " +
        getLocalDateString(result.state.toDate) +
        ")"

    return (
        <Typography component={"span"} fontSize={regularFontSize}>

            <GenericFormHeader title={header} enableBack={true} />

            <Grid2
                container
                marginX={2}
                borderLeft={rightBorder}
                borderRight={rightBorder}>

                <Grid2 xs={12}>
                    <Box
                        className='bg-primary-subtle rounded d-none d-md-block'
                        paddingY={0.5}
                        textAlign={"center"}
                        fontWeight={commonFontWeight}>

                        <Grid2 container>

                            <Grid2 xs={5.5} borderRight={rightBorder}>
                                Details
                            </Grid2>

                            <Grid2 xs={1.5} borderRight={rightBorder}>
                                Date
                            </Grid2>

                            <Grid2 xs={2} borderRight={rightBorder}>
                                Document ID
                            </Grid2>

                            <Grid2 xs={3}>
                                Ammount
                            </Grid2>
                        </Grid2>

                    </Box>
                </Grid2>

                {
                    cData.map((txn) => (
                        <Grid2 xs={12} key={txn.id} borderBottom={rightBorder}>
                            <Box display={"block"} paddingY={1}>

                                <Grid2 container alignItems={"center"}>

                                    <Grid2
                                        xs={12}
                                        md={5.5}
                                        textAlign={"left"}
                                        paddingLeft={1}>

                                        <Box display={"block"}>
                                            <Grid2 container>
                                                <Grid2 xs={12}>
                                                    {txn.type}
                                                </Grid2>

                                                <Grid2
                                                    fontSize={commonFontSize}
                                                    xs={12}
                                                    color={PRIMARY_COLOR}>
                                                    {getSubText(txn)}
                                                </Grid2>
                                            </Grid2>
                                        </Box>

                                    </Grid2>

                                    <Grid2
                                        xs={3}
                                        md={1.5}
                                        paddingRight={{ xs: 1, sm: 0 }}
                                        textAlign={{ xs: "right", md: "center" }}>
                                        {getLocalDateString(txn.date)}
                                    </Grid2>

                                    <Grid2
                                        xs={2}
                                        md={2}
                                        textAlign={"center"}>
                                        {txn.refranceId}
                                    </Grid2>

                                    <Grid2 xs={6} md={2.5}>
                                        <Box display={"block"}>
                                            <Grid2 container>

                                                <Grid2
                                                    fontSize={largeFontSize}
                                                    xs={12}
                                                    textAlign={"right"}
                                                    paddingRight={1}>

                                                    {ShowNumber(Math.abs(txn.units), 2, true)}

                                                </Grid2>

                                                <Grid2
                                                    fontSize={commonFontSize}
                                                    textAlign={"right"}
                                                    paddingRight={1}
                                                    xs={12}
                                                    color={txn.textColor}>

                                                    Balance: {txn.runningText}

                                                </Grid2>
                                            </Grid2>
                                        </Box>
                                    </Grid2>

                                    <Grid2 xs={1} md={0.5} textAlign={"center"}>
                                        <StatementAmount txn={txn} />
                                    </Grid2>

                                </Grid2>
                            </Box>
                        </Grid2>
                    ))
                }
            </Grid2>

            <Grid2 container paddingTop={3} marginX={2}>
                <Grid2 className="d-none d-md-block" md={9}>
                </Grid2>

                <Grid2 xs={12} md={3}>
                    <Box>
                        <Grid2 container rowSpacing={1} border={rightBorder}>

                            <Grid2
                                xs={12}
                                className='bg-primary-subtle rounded'
                                paddingY={0.5}
                                textAlign={"center"}
                                fontWeight={commonFontWeight}>
                                Closing Balance
                            </Grid2>

                            <Grid2
                                xs={12}
                                textAlign={"right"}
                                paddingRight={1}
                                color={result.textColor}
                                fontSize={extraLargeFontSize}>
                                {result.runningText}
                            </Grid2>

                            <Grid2
                                xs={12}
                                fontSize={commonFontSize}
                                textAlign={"right"}
                                paddingRight={1}>

                                As on: {getLocalDateString(result.state.toDate)}

                            </Grid2>

                        </Grid2>

                    </Box>
                </Grid2>
            </Grid2>
        </Typography>
    )
};

export default StatementTable;