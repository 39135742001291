import { useState } from "react"

export default function useArray({ initState = [] }) {
    const [data, setData] = useState(initState);

    const addEntry = (entry) => {
        setData([...data, entry])
    }

    const addEntries = (entry) => {
        setData([...data, ...entry])
    }

    const removeEntry = (filterFn) => {
        setData(data.filter((item) => !filterFn(item)))
    }

    const updateEntry = (index, entry) => {
        data[index] = entry;
        setData([...data]);
    }

    const clearData = () => setData([]);

    return { data, addEntry, removeEntry, addEntries, clearData, updateEntry }
}