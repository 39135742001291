import React, { useContext, useState } from 'react';
import { INSPECTION_REPORT_GROUP_NAME, propertyList } from '../../Helpers/ExtraProperties';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Button } from '@mui/material';
import AddProperty from '../AddProperties/AddProperty';
import ProductInspectionTable from './ProductInspectionTable';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { useQueryClient } from '@tanstack/react-query';
import useArray from '../GenericComponents/Array/GenericArrayHandler';
import { UPDATE_ON_PRODUCT } from '../../Helpers/ConstantProperties';
import GenericMutateButton from '../GenericComponents/Buttons/GenericMutateButton';

const InspectionParams = ({ item }) => {
    const [name, setName] = useState("");
    const [dimentions, setDimention] = useState("");
    const [tolerance, setTolerance] = useState("");

    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const queryClient = useQueryClient();

    const canAdd = name !== "" && dimentions !== "" && tolerance !== ""

    const { data, addEntry, removeEntry } = useArray(
        { initState: item[INSPECTION_REPORT_GROUP_NAME] || [] })

    const addEntryToArray = () => {
        const entry = {
            inspectionParameter: name,
            inspectionDiamention: dimentions,
            inspectionTolerance: tolerance
        }

        addEntry(entry);

        setName("");
        setDimention("");
        setTolerance("");
    }

    const updateProduct = async () => {

        item[INSPECTION_REPORT_GROUP_NAME] = data;
        return await serviceHelpers.createProduct(token, khID, item)
    }

    const onSuccess = async () => {
        await queryClient.invalidateQueries({
            predicate: (query) => query.queryKey.includes(UPDATE_ON_PRODUCT),
        })
    }

    return (
        <Grid2 container paddingTop={2}>
            <Grid2 xs={12} md={3.5}>
                <AddProperty
                    data={{
                        item: propertyList.inspectionParameter,
                    }}
                    deleteField={(element) => { setName("") }}
                    currentValue={name}
                    onChange={(e) => setName(e.value)}
                />
            </Grid2>

            <Grid2 xs={12} md={3.5}>
                <AddProperty
                    data={{
                        item: propertyList.inspectionDiamention,
                    }}
                    deleteField={(element) => { setDimention("") }}
                    currentValue={dimentions}
                    onChange={(e) => setDimention(e.value)}
                />
            </Grid2>

            <Grid2 xs={12} md={3.5}>
                <AddProperty
                    data={{
                        item: propertyList.inspectionTolerance,
                    }}
                    deleteField={(element) => { setTolerance("") }}
                    currentValue={tolerance}
                    onChange={(e) => setTolerance(e.value)}
                />
            </Grid2>

            <Grid2 xs={12} md={1.5} textAlign={"center"} alignSelf={"center"}>
                <Button
                    onClick={addEntryToArray}
                    disabled={!canAdd}
                    variant="contained"
                    color="success">
                    {"Add To List"}
                </Button>
            </Grid2>

            <Grid2 xs={12}>
                <ProductInspectionTable
                    item={{ [INSPECTION_REPORT_GROUP_NAME]: data }}
                    removeEntry={removeEntry} />
            </Grid2>

            <Grid2 xs={12} textAlign={"center"} paddingY={2}>
                <GenericMutateButton
                    queryFn={updateProduct}
                    disable={data.length === 0}
                    onSuccess={onSuccess}
                    successMessage="Product Updated" />
            </Grid2>
        </Grid2>
    )
};

export default InspectionParams;