import React from 'react';
import { Box, Typography, TextField, Button, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { commonFontWeight } from '../../../Helpers/ConstantProperties';
import Grid2 from '@mui/material/Unstable_Grid2';

const GenericHeader = ({
    title,
    textFieldLabel,
    searchstring,
    setSearchString,
    actionsList
}) => {
    return (
        <Grid2 sx={{ p: 2, alignItems: 'start' }}>

            <Grid2 xs={12}>
                <Typography variant='h6' sx={{ fontWeight: commonFontWeight }}>
                    {title}
                </Typography>

            </Grid2>

            <Grid2 xs={12}>
                <Box>
                    <Grid2 container columnGap={2}>
                        <Grid2 xs>
                            <TextField
                                fullWidth
                                value={searchstring}
                                onChange={(e) => setSearchString(e.target.value)}
                                size='small'
                                variant="outlined"
                                placeholder={textFieldLabel}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid2>

                        {
                            actionsList.map((action, index) => (

                                <Grid2 xs={action.xs || 1.5} key={index} textAlign={"center"}>
                                    <Button
                                        variant='contained'
                                        className="fs-6 fw-small text-capitalize d-inline-flex align-items-center"
                                        onClick={action.onButtonClick}
                                        sx={{ whiteSpace: 'nowrap' }}
                                    >
                                        <span className="ms-2 d-flex">{action.buttonText}</span>
                                    </Button>
                                </Grid2>
                            ))
                        }
                    </Grid2>
                </Box>
            </Grid2>
        </Grid2>
    );
};

export default GenericHeader;