import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { NEWEXPPUR, TRANSPORT_STORE } from '../../../Helpers/ConstantProperties';
import TransportForm from './TransportForm';

const AddTransport = ({ item, showDialog }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(
            NEWEXPPUR,
            {
                state: {
                    document: item,
                    expenses: TRANSPORT_STORE,
                    description: "Transport Cost for " + (item.refranceId || item.id)
                }
            });
    }

    const onMenuClick = () => {
        showDialog({
            contents: <TransportForm document={item} />,
            title: "Add Transport Cost"
        });
    }

    if (item.transportConst) {
        return (
            <>
                <MenuItem onClick={() => onMenuClick()}>
                    {"Delete Tranport"}
                </MenuItem>
            </>
        );
    }

    return (
        <>
            <MenuItem onClick={() => handleClick()}>
                {"Add Transport"}
            </MenuItem>
        </>
    );
};

export default AddTransport;