import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCOUNTSTATMENT, DEFAULT_STORE } from '../../Helpers/ConstantProperties';
import Grid2 from '@mui/material/Unstable_Grid2';
import AddProperty from '../AddProperties/AddProperty';
import { SchemaTypes } from '../../Helpers/ExtraProperties';
import { Button } from '@mui/material';

const GetStockStatement = ({ item }) => {
    const lastTwoWeeks = dayjs().add(-15, 'day');

    const [fromDate, setFromDate] = useState(lastTwoWeeks);
    const [toDate, setToDate] = useState(dayjs());

    const navigateTo = useNavigate();

    const canSubmit = fromDate <= toDate;

    const onSubmit = () => {
        const state = {
            account: DEFAULT_STORE,
            resource: { id: item.id },
            fromDate: fromDate.toDate(),
            toDate: toDate.toDate(),
        }

        navigateTo(ACCOUNTSTATMENT, { state: state })
    }

    return (
        <Grid2 container padding={2}>
            <Grid2 xs={12} md={6}>
                <AddProperty
                    data={{
                        item: {
                            displayName: 'From Date',
                            name: 'fromDate',
                            required: true,
                            type: SchemaTypes.DATEPICKER,
                            helperText: 'Please Select the From Date',
                        },
                        extraProps: {
                            maxDate: toDate
                        }
                    }}
                    deleteField={(element) => { setFromDate(lastTwoWeeks) }}
                    currentValue={fromDate}
                    onChange={(e) => setFromDate(e.value)}
                />
            </Grid2>

            <Grid2 xs={12} md={6}>
                <AddProperty
                    data={{
                        item: {
                            displayName: 'To Date',
                            name: 'toDate',
                            required: true,
                            type: SchemaTypes.DATEPICKER,
                            helperText: 'Please Select the To Date',
                        },
                        extraProps: {
                            minDate: fromDate
                        }
                    }}
                    deleteField={(element) => { setToDate(dayjs()) }}
                    currentValue={toDate}
                    onChange={(e) => setToDate(e.value)}
                />
            </Grid2>

            <Grid2 xs={12} textAlign={"center"} paddingTop={2}>
                <Button disabled={!canSubmit} onClick={onSubmit}>Get Statement</Button>
            </Grid2>
        </Grid2>
    )
};

export default GetStockStatement;