import React from 'react';
import { ExcelDateToJSDate } from './AddClosingBalanceFromExcel';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Box, Button } from '@mui/material';
import { ShowNumber } from '../../Helpers/helpers';

const ShowExcelVouchers = ({ currentVoucher, txnList, current, next, last, length }) => {

    const date = ExcelDateToJSDate(currentVoucher[0].Date).toISOString()

    return (
        <Box paddingY={4} textAlign={"center"} display={"flex"} flexDirection={"column"} height={"100%"}>
            <Grid2 container>
                <Grid2 xs={4}>
                    {currentVoucher[0].Type}
                </Grid2>

                <Grid2 xs={4}>
                    {new Date(date).toLocaleDateString()}
                </Grid2>
            </Grid2>

            <Grid2 flexGrow={1} paddingTop={5} container gap={3} alignContent={"start"} overflow={"auto"}>
                {
                    currentVoucher.map((txn, index) => {


                        return (
                            <Grid2 xs={12} key={index}>
                                <Box>
                                    <Grid2 container>

                                        <Grid2 xs={4}>
                                            {txn.Account}
                                        </Grid2>

                                        <Grid2 xs={2}>
                                            {ShowNumber(txn.Effect, 2, true)}
                                        </Grid2>

                                        <Grid2 xs={2}>
                                            {txn.DocumentRef || "NA"}
                                        </Grid2>

                                        <Grid2 xs={4}>
                                            {txn.Notes || "NA"}
                                        </Grid2>
                                    </Grid2>
                                </Box>
                            </Grid2>
                        )
                    })
                }

            </Grid2>

            <Grid2 container paddingTop={3}>
                <Grid2 xs={4}>
                    <Button disabled={current <= 0} onClick={last}>Previous</Button>
                </Grid2>

                <Grid2 xs={4}>
                    {current + 1} of {length}
                </Grid2>

                <Grid2 xs={4}>
                    <Button disabled={(current + 1) === length} onClick={next}>
                        Next
                    </Button>
                </Grid2>
            </Grid2>

        </Box>
    )
};

export default ShowExcelVouchers;