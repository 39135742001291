import { Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import GenericDialogWithButton from '../GenericComponents/Dialog/GenericDialogWithButton';
import InquiryReadyStockDetails from '../Stock/inquiryReadyStockDetails';
import GenerateOutInvoice from './DashboardMenuOptions/GenerateOutInvoice';

const InquiryDispatchCard = ({ balanceDetails, inquiry }) => {
    return (
        <>
            <Grid2 xs={12} md={2}>
                <Box width={"90%"}>
                    <GenericDialogWithButton
                        buttonProps={{ variant: "outlined", fullWidth: true }}
                        buttonText={"Balance Details"}
                        dialogTitle={"Balance Details"}
                        dialogContents={
                            <InquiryReadyStockDetails balanceDetails={balanceDetails} />}
                    />
                </Box>
            </Grid2>

            <Grid2 xs={12} md={2}>
                <Box width={"90%"}>
                    <GenerateOutInvoice
                        generateChallan={true}
                        item={inquiry}
                        balanceDetails={balanceDetails} />
                </Box>
            </Grid2>

            <Grid2 xs={12} md={2}>
                <Box width={"90%"}>
                    <GenerateOutInvoice item={inquiry} balanceDetails={balanceDetails} />
                </Box>
            </Grid2>
        </>
    );
};

export default InquiryDispatchCard;