import { useContext, useState } from "react";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import { PRODUCT_TYPE_FINISHED, UPDATE_ON_PRODUCT } from "../../Helpers/ConstantProperties";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GenericErrorComponent } from "../GenericComponents/FormComponent/GenericAlertComponent";
import GenericSpinner from "../GenericComponents/FormComponent/GenericSpinner";
import GenericHeader from "../GenericComponents/Header/GenericHeader";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, Button, IconButton, Paper } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddProperty from "../AddProperties/AddProperty";
import { propertyList } from "../../Helpers/ExtraProperties";
import { useSnackbar } from "../Contexts/SnackbarProvider";

const MarkAsJobWork = ({ item, showDialog }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const [searchString, setSearchString] = useState("");
    const [product, setProduct] = useState(null);
    const queryClient = useQueryClient();
    const { showSnackbar } = useSnackbar();

    const canAdd = product !== null;

    const queryKey = [
        token,
        khID,
        UPDATE_ON_PRODUCT,
        "JOB WORK"
    ];

    const queryFunction = async () => {

        const options = {
            type: PRODUCT_TYPE_FINISHED,
            isJob: true
        }

        return await serviceHelpers.getProducts(token, khID, null, options);
    }

    var qResult = useQuery({
        queryKey: queryKey,
        queryFn: queryFunction
    })

    const changeJobWork = async (newProduct) => {
        return await serviceHelpers.updateProduct(token, khID, newProduct, newProduct.id);
    }

    const mutateResult = useMutation({
        mutationFn: (props) => changeJobWork(props),

        onSuccess: async (data) => {
            await queryClient.invalidateQueries({
                queryKey: queryKey
            })

            showSnackbar("State Changed", 'success')
        },
    })

    const addToList = async () => {
        product.isJob = true;
        await mutateResult.mutate(product);
        setProduct(null);
    }

    if (qResult.error) return <GenericErrorComponent error={qResult.error} />

    if (qResult.isLoading) return <GenericSpinner />

    if (qResult.data) {
        var data = [...qResult.data];

        if (searchString !== "") {
            data = data.filter((current) => {
                var valueToSearchIn = current.name.toLowerCase();
                var valueToSearch = searchString.toLowerCase();

                return valueToSearchIn.includes(valueToSearch);
            })
        }

        return (
            <>
                <GenericHeader
                    title={"Job Work Products"}
                    textFieldLabel={"Search By Name"}
                    setSearchString={setSearchString}
                    actionsList={[]}
                />

                <Grid2 container columnSpacing={1} rowSpacing={1} padding={1}>

                    <Grid2 xs={12} justifyItems={"center"}>
                        <Box width={{ xs: "90%", md: "60%" }}>
                            <Grid2 container alignItems={"center"}>
                                <Grid2 xs={10}>
                                    <AddProperty
                                        data={{
                                            item: propertyList.ProductDropDown,
                                        }}
                                        deleteField={(element) => { setProduct(null) }}
                                        currentValue={product}
                                        onChange={(e) => setProduct(e.value)}
                                    />
                                </Grid2>

                                <Grid2 xs={2}>
                                    <Button
                                        onClick={addToList}
                                        disabled={!canAdd}
                                        variant="contained"
                                        color="success">
                                        {"Mark As Job Work"}
                                    </Button>
                                </Grid2>
                            </Grid2>
                        </Box>
                    </Grid2>

                    {
                        data.map((product) => {
                            return (
                                <Grid2 xs={12} md={4} key={product.id}>
                                    <Paper elevation={1}>
                                        <Grid2 container paddingY={1}>
                                            <Grid2 xs={1}>

                                                <IconButton
                                                    size="small"
                                                    onClick={async () => {
                                                        product.isJob = false;
                                                        await mutateResult.mutate(product)
                                                    }}>

                                                    <DeleteIcon color='error' />

                                                </IconButton>
                                            </Grid2>

                                            <Grid2 xs={11}>
                                                {product.name}
                                            </Grid2>
                                        </Grid2>
                                    </Paper>
                                </Grid2>
                            )
                        })
                    }
                </Grid2>
            </>
        )
    }

    return <></>
};

export default MarkAsJobWork;