import Grid2 from '@mui/material/Unstable_Grid2';
import { ACCOUNT_PAYABLE } from '../../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../../Helpers/ServiceHelpers';
import BalanceTotal from './BalanceTotal';
import { useContext } from 'react';
import { AuthContext } from '../../Auth/Auth';
import { FirmContext } from '../../Contexts/FirmContext';
import AccountChartAndList from './AccountChartAndList';


const TotalPayable = ({ transform = false }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    return (
        <Grid2 container rowGap={2}>

            <Grid2 xs={12} className="text-success">
                <BalanceTotal
                    transform={transform}
                    header={"Payable"}
                    account={ACCOUNT_PAYABLE} />
            </Grid2>

            <Grid2 xs={12} >
                <AccountChartAndList
                    transform={transform}
                    account={ACCOUNT_PAYABLE}
                    query={
                        async () =>
                            await serviceHelpers.getPayableAccounts(token, khID)
                    }
                />
            </Grid2>

        </Grid2>
    );
};

export default TotalPayable;