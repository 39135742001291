import React, { useContext } from 'react';
import { List, Toolbar, Drawer, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { MenuContext } from '../Contexts/MenuContext';
import { FirmContext } from '../Contexts/FirmContext';
import { menuItems } from '../../Helpers/ExtraProperties';
import { DARK_GREY, DEFAULT_TEXT_COLOR, LIGHT_GREY, MEDIUM_GREY, MENU_ACCOUNTS, MENU_DESIGN, MENU_INQUIRY, MENU_MASTER, MENU_PRODUCTION, MENU_REPORTS, MENU_STORE, PRIMARY_COLOR, SECONDARY_COLOR, gradientMenuColors } from '../../Helpers/ConstantProperties';
import { checkAccessLevel, checkAdmin } from '../../Helpers/helpers';
import { OriginContext } from '../Contexts/OriginContext';
import GenericFooter from '../GenericComponents/Footer/Footer';
import GroupedMenu from './GroupedMenu';

export default function DrawerMenu() {
    const { mobileOpen, handleDrawerClose, handleDrawerTransitionEnd } = useContext(MenuContext);
    const { currentFirm } = useContext(FirmContext);
    const { limitFunctionality } = useContext(OriginContext);
    const location = useLocation();
    const currentPath = location.pathname;
    const firmColorIndex = currentFirm ? currentFirm.colorIndex : null;
    const firmGradientColor = firmColorIndex !== null
        ? gradientMenuColors[firmColorIndex % gradientMenuColors.length]
        : SECONDARY_COLOR;

    const theme1 = createTheme({
        palette: {
            main: SECONDARY_COLOR,
            background: {
                default: LIGHT_GREY,
                drawer: PRIMARY_COLOR,
                listItem: PRIMARY_COLOR,
                selectedListItem: PRIMARY_COLOR,
            },
            text: {
                primary: DEFAULT_TEXT_COLOR,
                navLink: SECONDARY_COLOR,
                selectedNavLink: PRIMARY_COLOR,
            },
        },
    });

    const theme2 = createTheme({
        palette: {
            main: SECONDARY_COLOR,
            background: {
                default: LIGHT_GREY,
                drawer: DARK_GREY,
                listItem: MEDIUM_GREY,
                selectedListItem: firmGradientColor,
            },
            text: {
                primary: SECONDARY_COLOR,
                navLink: DEFAULT_TEXT_COLOR,
                selectedNavLink: SECONDARY_COLOR,
            },
        },
    });

    const theme = currentFirm && currentFirm.name && currentFirm.khID ? theme2 : theme1;

    var menu = menuItems;

    if (limitFunctionality) {
        menu = menu.filter((menu) => menu.showInLimit);
    }

    menu = menu.filter(item => checkAdmin(currentFirm.currentAccess,) || checkAccessLevel(currentFirm.currentAccess, item.accessByLevel));

    const groupedMenu = Object.groupBy(menu, (item) => item.group);
    const menuOrder = [
        MENU_REPORTS,
        MENU_INQUIRY,
        MENU_STORE,
        MENU_PRODUCTION,
        MENU_DESIGN,
        MENU_ACCOUNTS,
        MENU_MASTER
    ]

    const collapsableDrawer = (
        <Box
            sx={{
                backgroundColor: theme.palette.background.drawer,
                height: '100%',
                overflowY: 'auto',
                display: "flex",
                flexDirection: "column",
            }}>
            <Toolbar />

            <List>
                {
                    menuOrder.filter((group) => groupedMenu[group]).map((menuGroup, index) => {
                        return (
                            <GroupedMenu
                                defaultOpen={index === 0}
                                currentPath={currentPath}
                                theme={theme}
                                key={index}
                                levelID={menuGroup}
                                levelMenu={groupedMenu[menuGroup]}
                            />
                        );
                    })
                }

            </List>
            <GenericFooter />
        </Box>
    )

    return (
        <ThemeProvider theme={theme}>
            <>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'block', md: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: theme.spacing(30) },
                    }}
                >
                    {collapsableDrawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: theme.spacing(30) },
                    }}
                    open
                >
                    {collapsableDrawer}
                </Drawer>
            </>
        </ThemeProvider>
    );
}
