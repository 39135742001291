import React, { useContext } from 'react';
import GenericFormHeader from '../GenericComponents/FormComponent/GenericFormHeader';
import Grid2 from '@mui/material/Unstable_Grid2';
import TDSEntryTable from './TDSEntryTable';
import GenericMutateButton from '../GenericComponents/Buttons/GenericMutateButton';
import { DELETE_FIELD, UPDATE_ON_VOUCHER } from '../../Helpers/ConstantProperties';
import { useQueryClient } from '@tanstack/react-query';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { Alert } from '@mui/material';

const ViewTDSForm = ({ document }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const queryClient = useQueryClient();

    if (!document.tdsVoucherID) {
        return (
            <>
                <GenericFormHeader
                    title={"TDS entry for " + document.refranceId}
                    enableBack={true} />

                <Alert color='warning'>
                    TDS information is not available.
                </Alert>
            </>
        )
    }

    const queryFn = async () => {
        const voucherUpdate = {
            tdsAdjusted: DELETE_FIELD,
            tdsVoucherID: DELETE_FIELD,
            tdsData: DELETE_FIELD
        }

        await serviceHelpers.patchVoucher(token, khID, voucherUpdate, document.id);
        return await serviceHelpers.deleteChallans(token, khID, document.tdsVoucherID);
    }

    const onSuccess = async () => {
        await queryClient.invalidateQueries({
            predicate: (query) =>
                query.queryKey.includes(UPDATE_ON_VOUCHER)
        });
    }
    return (
        <Grid2 container paddingTop={3}>

            <Grid2 xs={12}>
                <TDSEntryTable taxData={document.tdsData} />
            </Grid2>

            <Grid2 xs={12} textAlign={"center"} paddingTop={3}>
                <GenericMutateButton
                    buttonText={"Delete"}
                    disable={false}
                    onSuccess={onSuccess}
                    queryFn={queryFn}
                    successMessage={"TDS infomation changed."} />
            </Grid2>
        </Grid2>
    );
};

export default ViewTDSForm;