import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GenericErrorComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import GenericFormHeader from '../GenericComponents/FormComponent/GenericFormHeader';
import Grid2 from '@mui/material/Unstable_Grid2';
import BOMDDQty from './BOMDDQty';
import { propertyList } from '../../Helpers/ExtraProperties';
import useArray from '../GenericComponents/Array/GenericArrayHandler';
import ProductBOMList from './ProductBOMList';
import { Typography } from '@mui/material';
import { commonFontSize, UPDATE_ON_PRODUCT } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { useQueryClient } from '@tanstack/react-query';
import GenericMutateButton from '../GenericComponents/Buttons/GenericMutateButton';

const ProductBOM = () => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const location = useLocation();
    var { product } = location.state;

    const { data, addEntry, removeEntry, updateEntry } = useArray({ initState: product?.bom || [] })
    const [disable, setDisable] = useState(true);

    if (!product) return <GenericErrorComponent error={new Error("No Inquiry")} />

    const addEntryToArray = (product, qty) => {
        const entry = {
            product: {
                id: product.id,
                name: product.name,
                type: product.type,
                bom: product.bom,
            },
            fgRate: qty
        }

        const existing = data.findIndex((entry) => entry.product.id === product.id);

        if (existing !== -1) {
            updateEntry(existing, entry)
        }
        else {
            addEntry(entry)
        }

        setDisable(false);
    }

    const removeEntryFromArray = (filter) => {
        removeEntry(filter);
        setDisable(false)
    }

    const updateProduct = async () => {

        product.bom = data;
        return await serviceHelpers.createProduct(token, khID, product)
    }

    const onSuccess = async () => {
        await queryClient.invalidateQueries({
            predicate: (query) => query.queryKey.includes(UPDATE_ON_PRODUCT),
        })

        navigate(-1);
    }

    return (
        <>
            <GenericFormHeader enableBack={true} title={"BOM for " + product.name} />
            <Grid2 container>
                <Grid2 xs={12} md={4} padding={1}>
                    <BOMDDQty
                        itemList={propertyList.SFGDropdown}
                        addEntryToArray={addEntryToArray} />
                </Grid2>

                <Grid2 xs={12} md={4} padding={1}>
                    <BOMDDQty
                        itemList={propertyList.RawMaterialDropdown}
                        addEntryToArray={addEntryToArray} />
                </Grid2>

                <Grid2 xs={12} md={4} padding={1}>
                    <BOMDDQty
                        itemList={propertyList.ProcessDropdown}
                        addEntryToArray={addEntryToArray} />
                </Grid2>

                <Grid2 xs={12} paddingTop={3}>
                    <Typography component={"span"} fontSize={commonFontSize}>
                        <ProductBOMList item={{ bom: data }} removeEntry={removeEntryFromArray} />
                    </Typography>
                </Grid2>

                <Grid2 xs={12} textAlign={"center"} paddingY={2}>
                    <GenericMutateButton
                        queryFn={updateProduct}
                        disable={disable}
                        onSuccess={onSuccess}
                        successMessage="Product Updated" />
                </Grid2>
            </Grid2>
        </>
    )
};

export default ProductBOM;