import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { InquiryGridItem } from '../Inquiry/InquiryGridItem';
import { checkValue, GetInclusiveRate, getSaleRate, ShowNumber } from '../../Helpers/helpers';
import { HSN_CODE_PROP } from '../../Helpers/ExtraProperties';
import { PRODUCT_TYPE_PROCESS } from '../../Helpers/ConstantProperties';

const ProductDetails = ({ item }) => {
    let type = item.type;

    if (type === PRODUCT_TYPE_PROCESS) {
        type += " (" + item.processType + ")"
    }

    return (
        <Grid2 container paddingY={1} rowGap={2}>

            <InquiryGridItem
                sm={4}
                xs={12}
                title={'Name'}
                value={checkValue(item.name)} />

            <InquiryGridItem
                sm={4}
                xs={12}
                title={'Type'}
                value={type} />

            <InquiryGridItem
                sm={4}
                xs={12}
                title={'HSN'}
                value={checkValue(item[HSN_CODE_PROP])} />

            <InquiryGridItem
                sm={4}
                xs={12}
                title={'Taxable Rate'}
                value={ShowNumber(getSaleRate(item), 2, true)} />

            <InquiryGridItem
                sm={4}
                xs={12}
                title={'GST Rate'}
                value={(item.GSTRate || 0) + "%"} />

            <InquiryGridItem
                sm={4}
                xs={12}
                title={'Inclusive Rate'}
                value={ShowNumber(GetInclusiveRate(item), 2, true)} />

        </Grid2>
    );
};

export default ProductDetails;