import { useContext } from 'react';
import { FirmContext } from '../../Contexts/FirmContext';
import { AuthContext } from '../../Auth/Auth';
import { BALANCE, DEMI_INR_RESOURCE_ID, TAX_INVOICE } from '../../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../../Helpers/ServiceHelpers';
import { useQuery } from '@tanstack/react-query';
import { ShowNumber } from '../../../Helpers/helpers';
import ReportHeader from '../Formats/ReportHeader';

const BalanceTotal = ({ account, header, transform = false }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const totalQuery = {
        queryKey: [
            token,
            khID,
            TAX_INVOICE,
            BALANCE + account
        ],

        queryFn: async () => {
            const balanceOption = {
                accountID: account,
                resourceID: DEMI_INR_RESOURCE_ID,
                date: Date.now(),
            }

            return await serviceHelpers.getResourceBalance(token, khID, balanceOption)
        }
    }

    const result = useQuery(totalQuery);

    if (result.data) {
        const total = Math.abs(result.data[0]?.units) || 0;

        return <ReportHeader
            total={ShowNumber(total, 2, true)}
            header={header}
            transform={transform} />
    }

    return <></>
};

export default BalanceTotal;