
import { Paper, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CardActionButtons from "../GenericComponents/DataView/CardActionButtons";
import GenericSkeleton from "../GenericComponents/DataView/GenericSkeleton";
import { useLocation, useNavigate } from "react-router-dom";
import { GenericErrorComponent, GenericSuccessComponent } from "../GenericComponents/FormComponent/GenericAlertComponent";
import { COLOR_TEAL, commonFontSize, EDITPRODUCT, PRODUCT_TYPE_PROCESS, PRODUCT_TYPE_RAW, UPDATE_ON_PRODUCT } from "../../Helpers/ConstantProperties";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { GetInclusiveRate, getSaleRate, ShowNumber, SMALL_SCREEN, useScreenSize } from "../../Helpers/helpers";
import EditMenuItem from "../GenericComponents/DataView/EditMenuItem";
import DeleteMenuItem from "../GenericComponents/DataView/DeleteMenuItem";
import AddTDS from "../TaxInvoice/AddTDS";
import ProductName from "./ProductName";
import ChangeInspection from "./ChangeInspection";
import ChangeBOM from "./ChangeBOM";

function ProductCardComponent({ item }) {
    const navigate = useNavigate();
    const screenSize = useScreenSize();
    const location = useLocation();
    const currentPath = location.pathname;

    if (item.showSkeleton) return <GenericSkeleton />;

    let successMessage = "Product Deleted Successfully"

    const deleteProductQueryFunction = async (token, khid) => {
        return await serviceHelpers.deleteProducts(token, khid, item.id);
    }

    const onDelete = {
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        navigateTo: currentPath,
        queryFunction: deleteProductQueryFunction,
        queryKeyValue: UPDATE_ON_PRODUCT,
    }

    const onEdit = () => {
        navigate(EDITPRODUCT, { state: { ...item, navigateTo: currentPath } })
    }

    const showCard = screenSize === SMALL_SCREEN ? true : false;

    const menu = [
        <AddTDS item={item} />,
        <ChangeInspection item={item} />,
        <EditMenuItem onEdit={onEdit} />,
        <DeleteMenuItem onDelete={onDelete} />
    ]

    if (![PRODUCT_TYPE_RAW, PRODUCT_TYPE_PROCESS].includes(item.type)) {
        menu.push(<ChangeBOM item={item} />)
    }

    return (
        <Paper
            sx={{
                borderLeft: `15px solid ${COLOR_TEAL}`,
                padding: '0.3rem',
                minWidth: 0,
                px: 1
            }}
            elevation={2} >

            <Grid2 container alignItems="center" spacing={2}>
                <ProductName product={item} />

                <Grid2
                    textAlign={"left"}
                    display={showCard ? "block" : "none"}
                    xs={3}
                    alignSelf={"center"}>
                    <CardActionButtons menuList={[
                        <EditMenuItem onEdit={onEdit} />,
                        <DeleteMenuItem onDelete={onDelete} />
                    ]} />
                </Grid2>

                <Grid2 xs={3} md={2} alignSelf={"center"}>
                    <Typography noWrap textAlign={{ md: "center", xs: "left" }} fontSize={commonFontSize}>
                        {item.productItemcode}
                    </Typography>
                </Grid2>

                <Grid2 xs={3} md={1} alignSelf={"center"}>
                    <Typography textAlign={"right"} paddingRight={2} fontSize={commonFontSize}>
                        {ShowNumber(parseFloat(getSaleRate(item)), 2, true)}
                    </Typography>
                </Grid2>

                <Grid2 xs={3} md={1} alignSelf={"center"}>
                    <Typography textAlign={"center"} fontSize={commonFontSize}>
                        {`${item.GSTRate || 0} %`}
                    </Typography>
                </Grid2>

                <Grid2 xs={3} md={1} alignSelf={"center"}>
                    <Typography textAlign={"right"} paddingRight={2} fontSize={commonFontSize}>
                        {ShowNumber(parseFloat(GetInclusiveRate(item)), 2, true)}
                    </Typography>
                </Grid2>

                <Grid2 md></Grid2>

                <Grid2
                    display={showCard ? "none" : "block"}
                    xs={12}
                    alignSelf={"center"}
                    md={1}>
                    <CardActionButtons menuList={menu} />
                </Grid2>

            </Grid2>
        </Paper>
    )
}

export default ProductCardComponent;