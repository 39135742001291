import { CREATETRANSPORT, UPDATE_ON_TRANSPORT } from "../../Helpers/ConstantProperties";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import Party from "../Party/Party";
import PartyCardComponent from "../Party/PartyCardComponent";

export default function Transports() {
    return <Party
        qKey={UPDATE_ON_TRANSPORT}
        queryFn={serviceHelpers.getTransports}
        heading={'Transports'}
        btnText={"New Transport"}
        navigateTo={CREATETRANSPORT}
        ShowElement={{ Component: PartyCardComponent }}
    />
}