import React, { useContext } from 'react';
import { BALANCE, INQUIRY_STORE_ACCOUNT_ID, INWORD_CHALLAN } from '../../Helpers/ConstantProperties';
import { propertyList } from '../../Helpers/ExtraProperties';
import NewPurchaseForm from '../Purchase/NewPurchseForm';
import { inwordIdWithDate } from '../../Helpers/helpers';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';

const NewInChallan = () => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const queryFn = async ({
        data,
        selectedDate,
        party,
        refID,
        cData
    }) => {
        const batches = []
        const transaction = []

        data.forEach((entry, index) => {

            batches.push({
                id: inwordIdWithDate(index, new Date(selectedDate)),
                name: party.name,
                // rate: entry.saleRate,
                entityPublicAccess: true
            })

            transaction.push({
                accountID: party.id,
                resourceID: entry.product.id,
                units: entry.units * -1,
                batches: [{ id: refID, units: entry.units * -1 }]
            })

            transaction.push({
                accountID: INQUIRY_STORE_ACCOUNT_ID,
                resourceID: entry.product.id,
                units: entry.units,
                batches: [{ index: index, units: entry.units }]
            })
        })

        const voucher = {
            vendorName: party.name,
            vendorId: party.id,
            city: party.city,
            cData: cData,
            date: selectedDate,
            verified: true,
            refranceId: refID,
            type: INWORD_CHALLAN,
            batches: batches,
            transactions: transaction,
        }

        return await serviceHelpers.creteProductVoucher(
            token, khID, voucher, party.id);
    }

    const qkeys = [INWORD_CHALLAN, (BALANCE + INQUIRY_STORE_ACCOUNT_ID)]

    const predicate = (query) =>
        qkeys.some((queryKey) => query.queryKey.includes(queryKey))

    return <NewPurchaseForm
        title={INWORD_CHALLAN}
        itemList={propertyList.purchaseProductList}
        queryFn={queryFn}
        predicate={predicate} />
};

export default NewInChallan;