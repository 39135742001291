import { useContext } from "react";
import { ACCOUNT_RECEIVABLE, RECEIPT_VOUCHER, RECEIPTS, TOTAL_OTHER_RECEIVABLE_ID, UPDATE_ON_RECEIPTS } from "../../Helpers/ConstantProperties";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import NewPaymentForm from "./NewPaymentForm";
import { createVoucherObject } from "./VoucherHelper";

const NewReceipt = () => {
    const { token } = useContext(AuthContext);
    const { khID, currentFirm } = useContext(FirmContext);

    const queryFn = async ({ cData }) => {
        return createVoucherObject({
            cData: cData,
            title: "Received",
            type: RECEIPT_VOUCHER,
            collAccounts: [ACCOUNT_RECEIVABLE, TOTAL_OTHER_RECEIVABLE_ID],
            token: token,
            khID: khID,
            prefix: currentFirm.prefixes.receipt
        })
    }

    return (
        <NewPaymentForm
            queryFn={queryFn}
            title="Receipt"
            updateKey={UPDATE_ON_RECEIPTS}
            nevigateRoute={RECEIPTS}
            batchMulti={-1} />
    )
};

export default NewReceipt;