import { Alert, Box, IconButton, Paper } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { ShowNumber } from '../../Helpers/helpers';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { INR_RESOURCE_ID } from '../../Helpers/ConstantProperties';
import StatementAmount from '../Statements/StatementAmount';

const TransactionTable = ({ voucher, removeEntry }) => {
    const txnList = voucher.transactions;

    if (txnList.length === 0)
        return <Alert variant="outlined" color="warning">No Transactions </Alert>

    var total = 0;

    const filterFn = (row) => (item) => item.account.id === row.accountID

    return (
        <Grid2 container rowSpacing={1}>
            {

                txnList.filter((txn) => txn.resourceID === INR_RESOURCE_ID).map((txn, index) => {
                    total += txn.units;

                    return (
                        <Grid2 xs={12} key={index}>
                            <Paper elevation={1}>
                                <Grid2 container paddingY={1} alignItems={"center"}>
                                    <Grid2 xs={12} md={7} className="ps-2 text-truncate">
                                        {txn.accountName}
                                    </Grid2>
                                    <Grid2 xs={12} md={3} className="text-end pe-1">
                                        {ShowNumber(Math.abs(txn.units), 2, true)}
                                    </Grid2>
                                    <Grid2 xs={12} md={1}>
                                        <StatementAmount txn={txn} />
                                    </Grid2>
                                    <Grid2 xs={12} md={1} className="text-end pe-1">
                                        <IconButton
                                            disabled={txn.skipDelete}
                                            onClick={() => removeEntry(filterFn(txn))}>
                                            <DeleteRoundedIcon color='error' />
                                        </IconButton>
                                    </Grid2>
                                </Grid2>
                            </Paper>
                        </Grid2>
                    )
                })
            }

            <Grid2 xs={12} paddingTop={2}>
                <Box>
                    <Grid2 container>
                        <Grid2 xs={12} md={7} className={"ps-2"}>
                            Total
                        </Grid2>
                        <Grid2 xs={12} md={3} className="text-end pe-3">
                            {ShowNumber(total, 2, true)}
                        </Grid2>
                    </Grid2>
                </Box>
            </Grid2>

        </Grid2>
    )
};

export default TransactionTable;