import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { getPageCalculation, OutChallanTableValues, ShowNumber } from '../../../Helpers/helpers';
import { PRIMARY_COLOR } from '../../../Helpers/ConstantProperties';
import NoItemItemTable from './NoItemItemTable';

const styles = StyleSheet.create({
    table: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#e0e0e0",
        borderRadius: 4,
        fontFamily: 'Roboto',
    },
    tableRow: {
        display: "flex",
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "#e0e0e0",
        borderBottomStyle: "solid",
        alignItems: "center",
    },
    tableHeader: {
        fontSize: 8,
        backgroundColor: "#cfe2ff",
        fontWeight: "bold",
        textAlign: "center",
        paddingVertical: "1mm"
    },
    itemNameCell: {
        textAlign: "center",
        fontSize: 8,
        borderRight: 1,
        borderRightColor: "lightgray",
        paddingVertical: "1mm",
        flexBasis: OutChallanTableValues[0].sizes.basis / 12 * 100 + "%"
    },
    unitsCell: {
        textAlign: "center",
        fontSize: 8,
        paddingVertical: "1mm",
        flexBasis: OutChallanTableValues[1].sizes.basis / 12 * 100 + "%"
    },
    subText: {
        fontSize: 7,
        color: PRIMARY_COLOR,
        marginTop: 2,
    },

    totalRow: {
        fontSize: 8,
        fontWeight: "bold",
        backgroundColor: "#f8f9fa",
        paddingVertical: "1mm"
    },

    totalNameCell: {
        textAlign: "center",
        flexBasis: 9 / 12 * 100 + "%"
    },

    totalUnitsCell: {
        textAlign: "center",
        flexBasis: 3 / 12 * 100 + "%"
    },
});

const ChallanItemsFormat = ({ context, page }) => {
    const cData = context.cData;

    const { itemsToShow } = getPageCalculation(context.selectedFormat, cData.items, page);
    if (itemsToShow.length === 0) return <NoItemItemTable styles={styles} />;

    const products = itemsToShow;

    const ItemNameCell = ({ product }) => (
        <View style={styles.itemNameCell}>
            <Text>{product.name}</Text>
            <Text style={styles.subText}>{product.productdescription}</Text>
        </View>
    );

    const UnitsCell = ({ product }) => (
        <View style={styles.unitsCell}>
            <Text>{ShowNumber(product.units, 0) + " " + product.unit} </Text>
        </View>
    );

    return (
        <View style={styles.table}>

            {/* Table Header */}
            <View style={styles.tableRow}>
                {
                    OutChallanTableValues.map((header) => (
                        <View
                            key={header.key}
                            style={[
                                styles.tableHeader,
                                { flexBasis: header.sizes.basis / 12 * 100 + "%" },
                            ]}
                        >
                            <Text>{header.content}</Text>
                        </View>
                    ))
                }
            </View>

            {/* Table Rows */}
            {
                products.map((product, index) => (
                    <View key={index} style={styles.tableRow}>
                        <ItemNameCell product={product} />
                        <UnitsCell product={product} />
                    </View>
                ))
            }

            <View style={[styles.tableRow, { flex: 1 }]}>
            </View>

            {/* Total Row */}
            <View style={[styles.tableRow, styles.totalRow]}>
                <View style={styles.totalNameCell}>
                    <Text>Total</Text>
                </View>
                <View style={styles.totalUnitsCell}>
                    <Text>{ShowNumber(cData.totalQuantity, 0)}</Text>
                </View>
            </View>

        </View>
    );
};

export default ChallanItemsFormat;